import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
  Select,
  InputLabel, FormControl,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage} from 'helpers';
import {LoadingButton, PageLoading} from 'components'
import * as qs from 'query-string';

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  office: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100
    }
  },
  department: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100
    }
  },
  company: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/system/bg.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 'bold',
    fontSize: 'xxx-large',
    lineHeight : '85px'
  },
  name: {
    marginTop: theme.spacing(3),
    color: '#fff',
    fontSize : 'large'
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    overflowY: 'auto'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    marginTop : '10vh',
    textAlign : 'center'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  avatar: {
    width: 120,
    height: 120
  },
  formControl: {
    minWidth: 150,
  },
}));

const SignUp = props => {
  const { history } = props;

  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
 
  const [loading, setLoading] = useState(false);
  const [passConfirm, setPassConfirm] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));

    get_auth_status();
  }, [formState.values]);

  const get_auth_status = async () => {
    
    setPageLoad(true);
  }

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const  onSubmit = (event) => {
    if(formState.errors['email'] || formState.values.password ==  null || formState.values.confirm_password == null)
    {
      return;
    }
    if(formState.values.password != formState.values.confirm_password)
    {

      setPassConfirm(true);
      event.preventDefault();
      return;
    }
   
    setLoading(true);
    let body = { 
      name : formState.values.name, 
      email : formState.values.email,
      pass : formState.values.password,
    }
     
    _http.doPost('auth/register', 
      body, 
      (data) => {
        setLoading(false);
        document.location.href = '/auth_manage';
      },
      (err) => {
        setLoading(false);
        // alert('Error occured');
      }
    );
  }
  
  return (
    (
      pageLoaded == false ?
        <PageLoading />
        :
        <div className={classes.root}>
          <Grid
            className={classes.grid}
            container
            style = {{justifyContent : 'center'}}
          >
            <Grid
              className={classes.content}
              item
              lg={6}
              md = {6}
              sm = {6}
              xs={12}
            >
              <div className={classes.content}>
                {/* <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div> */}
                <div className={classes.contentBody}>
                  <form
                    className={classes.form}
                    // onSubmit={handleSignUp}
                  >
                    <Typography
                      className={classes.title}
                      variant="h2"
                    >
                  Add Member
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                    >
                  Add details to create new member
                    </Typography>
              
               
                    <TextField
                      className={classes.textField}
                      error={hasError('name')}
                      fullWidth
                      helperText={
                        hasError('name') ? formState.errors.name[0] : null
                      }
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      type="text"
                      value={formState.values.name || ''}
                      variant="outlined"
                    />
             
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      required
                      type="email"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password') ? formState.errors.password[0] : null
                      }
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      required
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={passConfirm}
                      fullWidth
                      helperText={
                        passConfirm ? 'Please confirm password' : null
                      }
                      label="Confirm password"
                      name="confirm_password"
                      onChange={handleChange}
                      required
                      type="password"
                      value={formState.values.confirm_password || ''}
                      variant="outlined"
                    />
             
                    <div style = {{marginTop : '10px'}} />
                    <LoadingButton
                      label = "Sign up"
                      label_loading = "Sign up"
                      loading = {loading}
                      onClick = {onSubmit}
                    />
                
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
    )
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
