import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GroupAdd, Add, Close, ArrowBack, CheckBoxOutlineBlank, CheckBox, ExpandMore, CloudUpload, PhotoCamera} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import SignatureCanvas from 'react-signature-canvas'
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  table_font : {
    fontSize : '14px !important',
    
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      fontSize : '10px !important'
    }
  },
  table1 : {
    fontSize: '60%',
    lineHeight: '10px',
  },
  license_img_wrapper_pc : {
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      display : 'none !important',
    }
  },
  license_img_wrapper_phone : {
    [theme.breakpoints != null && theme.breakpoints.up('md')]: {
      display : 'none',
    }
  }
}));

const RefuseForm = props => {
  const { history, prfData, fontSize } = props;
  const classes = useStyles();
  const [prf_Data, setPrfData] = useState({
    tangos : {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details : {},
    patient_history : {},
    chronic_conditions : {},
    haemorrhage : {},
    airway : {},
    breathing : {},
    circulation : {},
    immobilisation : {},
    mornitoring : {},
    iv_access1 : {},
    iv_access2 : {},
    medication_slots : {},
    vital_1 : {},
    vital_2 : {},
    vital_3 : {},
    vital_4 : {},
    vital_5 : {},
    vital_6 : {},
    patient_consent : {},
    signature : {
      patient : 'patient_signature',
      hospital : 'hospital_signature'
    },
    license_img : {
      driver : 'driver_photo',
      hospital : 'hospital_photo'
    },
    
  });

  useEffect(()=>{
    setPrfData(prfData);
  }, [prfData])

  let vehicle_info = prf_Data.call_information == null || prf_Data.call_information.vehicle == null ? '' : prf_Data.call_information.vehicle;
  let tmp_vehicle_infos = vehicle_info.split('_@@==@@_');

  return (
    <Grid
      className = {classes.table_font}
      container
      item
      lg={12}
      md = {12}
      sm = {12}
      style = {{width : '100%', padding : '10px', paddingTop : 20}}
    >
      <div
        id="print1"
        style = {{width : '100%'}}
      >
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                align = "left"
                width="33%"
              >
                <img
                  src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                  width="80px"
                />
              </td>
              <td
                style= {{textAlign: 'right', }}
                width="62%"
              >
                <strong>Legacy Emergency Specialists</strong>
                <br/>
                <strong>Route N4 Business Park, Corridor Crescent</strong>
                <br/>
                <strong>9 Bureau de Paul</strong>
                <br/>
                <strong>Ben Fleur</strong>
                <br/>
                <strong>Witbank</strong>
                <br/>
                <strong>www.legacyems.co.za</strong>
                <br/>
                <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
                <br/>
                <strong>Practice Number: 009 003 064 1332</strong>
                <br/>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td style={{textAlign : 'center'}}><strong>REFUSAL OF TREATMENT, TRANSPORT AND/OR EVALUATION</strong></td></tr></tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Patient Name</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Patient Surname</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>ID Number</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Mobile Number</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>E-mail Address</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Date</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Incident</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Time</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Incident Address</b></td>
              <td
                colSpan = {3}
                valign = {'top'}
              />
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr >
              <td
                style = {{paddingTop : 8, fontWeight : 'bold'}}
                valign = {'top'}
              >Indemnity Form and Legal Waiver</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >Please read completely before signing below</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >Because it is sometimes impossible to recognize actual or potential medical problems outside the hospital, Legacy Emergency Medical Services strongly encourage you to be evaluated, treated if necessary, and transported to a hospital by ambulance for a more complete examination by the Emergency Unit physician.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >You as a patient have the right to choose not to be evaluated, treated or transported if you wish; however, there is the possibility that you could suffer serious complications or even death from conditions that are not apparent at this time.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >By signing below, you are acknowledging that Legacy Emergency Medical Services personnel have advised you, and that you understand, the potential harm to you health that may result from your refusal of the recommended care; and, you release Legacy Emergency Medical Services (Pty) Ltd and supporting personnel from liability resulting from refusal of treatment, transport and/or Evaluation.</td>
            </tr>
            <tr  >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >If you change your mind and desire evaluation, treatment, and/or transport to a hospital, you may contact us again telephonically at any time.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >I acknowledge that Legacy Emergency Medical Services personnel explained my condition to me and advised me of some of the potential risk(s) and/or complication(s) which could or would arise from refusal of medical care. I have also been advised that other unknown risk(s) and/or complication(s) are possible up to and including the loss of life or limb. Being aware that there are known and unknown potential risk(s) and/or complication(s), it is still my desire to refuse the advised medical care.</td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Patient Signature</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Signature</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Legacy Signature</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Name</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Legacy Crew</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Withness ID</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>HPCSA Reg</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Relation</b></td>
              <td valign = {'top'} />
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "12"
            style={{textAlign : 'left'}}
          ><strong>Person Capacity</strong></td></tr></tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr >
              <td valign = {'top'}  >Orientated to person, time and place?</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Coherent Speech?</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Auditory and/or visual hallucinations</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Suicidal or Homicida ? SAPS notified</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr>
              <td valign = {'top'} >Under influence of alcohol/narcotics</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              >yes</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "12"
            style={{textAlign : 'left'}}
          ><strong>Vital Signs</strong></td></tr></tbody>
        </table>
        <table
          border = "1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
          width="99%"
        >
          <tbody>
            <tr >
              <td valign = {'top'}>BP</td>
              <td valign = {'top'} />
              <td valign = {'top'}>Pulse</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>Sats</td>
              <td valign = {'top'} />
              <td valign = {'top'}>R Rate</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>GCS</td>
              <td valign = {'top'} />
              <td valign = {'top'}>Pain</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>Pupils</td>
              <td valign = {'top'} />
              <td valign = {'top'}>HGT</td>
              <td valign = {'top'} />
            </tr>
          </tbody>
        </table>
      </div>
    </Grid>
  );
};


export default RefuseForm;
