import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import {LoadingButton, PageLoading} from 'components'
import { Sidebar, Topbar, Footer } from './components';
import {config, _http, _sessionStorage, _auth_check} from 'helpers';
import {Button, DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, TextField, } from '@material-ui/core';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 265
  },
  unshiftContent: {
    paddingLeft: 0
  },
  content: {
    height: '100%',
    width : '100%'
  },
  content_with_sidebar : {
    height: '100%',
    width : '100%',
    // width : 'calc(100% - 240px)'
  }
}));

const get_cur_page = () => {
  if(document.location.href.includes('home') == true){
    return 'LOG A PRF';
  }
  else if(document.location.href.includes('view_prf') == true){
    return 'PRF Document';
  }
  else if(document.location.href.includes('edit_prf') == true){
    return 'PRF Editor';
  }
  else if(document.location.href.includes('auth_manage') == true){
    return 'Crew Member Management';
  }
  else if(document.location.href.includes('fleet_manage') == true){
    return 'Fleet Management';
  }
  else if(document.location.href.includes('register') == true){
    return 'Crew Member Management';
  }
  else if(document.location.href.includes('cwr_form/patient/tests') == true){
    return 'CWR Form / Test Management';
  }
  else if(document.location.href.includes('cwr_form/patient') == true){
    return 'CWR Form / Patient Management';
  }
  else if(document.location.href.includes('cwr_form/report') == true){
    return 'CWR Form / Report';
  }
  else if(document.location.href.includes('cwr_form') == true){
    return 'CWR Form / Site Management';
  }
  else if(document.location.href.includes('epr_form/equip') == true){
    return 'EPR Form / Equipment Management';
  }
  else if(document.location.href.includes('epr_form/audit') == true){
    return 'EPR Form / Audit Management';
  }
  else if(document.location.href.includes('epr_form/report') == true){
    return 'EPR Form / Preview Report';
  }
  else if(document.location.href.includes('epr_form') == true){
    return 'EPR Form / Site Management';
  }
   
}


const Collab = props => {
  const { children , cur_module} = props;
  const classes = useStyles();
  const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  //   defaultMatches: true
  // });

  const [pageLoaded, setPageLoad] = useState(false);
  const [isDesktop, setDesktop] = useState(true);
  const [openSidebar, setOpenSidebar] = useState(isDesktop ? true : false);
  const [workspace_name, setWorkspaceName] = useState('');
  const [isWDialog, openWDialog] = useState(false);
  const [isConfirmDialog, openConfrimDialog] = useState(false);
  const [cur_workspace, setCurWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [projects, setProjects] = useState([]);
  const [cur_project, setCurProject] = useState(null);

  let registered_user = _sessionStorage.getItem('user');
  let cur_page = get_cur_page();

  useEffect(() => {
    get_auth_status();
  
    function handleResize() {
      if(window.innerWidth < 1024)
      {
        setDesktop(false);
        setOpenSidebar(false);
      }
      else
      {
        setDesktop(true);
        setOpenSidebar(true);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const get_auth_status =  () => {
    if(registered_user != null)
    {
      setPageLoad(true);
    }
    else {
      document.location.href = '/';
    }
  }
  

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };


  const handleClose = () => {
    openWDialog(false);
  }

  const handleConfirmClose = () => {
    openConfrimDialog(false);
  }

  


  return (
    (
      pageLoaded == false ?
        <PageLoading />
        :
        <div
          className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: openSidebar
          })}
        >
          <Sidebar
            cur_module = {cur_module}
            cur_workspace = {cur_workspace}
            onClose={handleSidebarClose}
            open={openSidebar}
            projects = {projects}
            variant={isDesktop ? 'persistent' : 'temporary'}
          />
          <main className={openSidebar ?  classes.content_with_sidebar : classes.content} >
            <Topbar 
              cur_page = {cur_page} 
              cur_project = {cur_project}
              cur_workspace = {cur_workspace} 
              onCreateNewWorkSpace = {() => {openWDialog(true);}} 
              onDeleteWorkSpace = {() => {openConfrimDialog(true);}}
              onSidebarOpen = {handleSidebarOpen}
              open={openSidebar}
              workspaces = {workspaces}
            />
            {children}
            {/* <Footer /> */}
          </main>
          <Dialog
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            onClose={handleClose}
            open={isWDialog}
          >
            <DialogTitle id="form-dialog-title">Add a new Workspace</DialogTitle>
            <DialogContent style = {{alignItems : 'flex-start', width : '400px'}} >
              {/* <DialogContentText>
              Please input category name and short description.
            </DialogContentText> */}
              <TextField
                autoFocus
                fullWidth
                id="workspace_name"
                label="Workspace Name"
                margin="dense"
                onChange = {event => setWorkspaceName(event.target.value)}
                type="text"
                value = {workspace_name}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleClose}
              >
              Close
              </Button>
              <Button  color="primary">
              Add
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            onClose={handleConfirmClose}
            open={isConfirmDialog}
          >
            <DialogTitle id="form-dialog-title">Remove Yourself from the Workspace?</DialogTitle>
            <DialogContent style = {{alignItems : 'flex-start', width : '400px'}} >
              <DialogContentText>
              If you remove yourself, you won't be able to access any of the projects or tasks in  
                <b> {
                  cur_workspace == null? '' : cur_workspace.name
                }</b>
              . 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleConfirmClose}
              >
              Cancel
              </Button>
              <Button color="primary">
              Remove
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    )
   
  );
};

Collab.propTypes = {
  children: PropTypes.node
};

export default Collab;
