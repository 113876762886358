import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout, Collab as CollabLayout } from './layouts';

import {
  AuthManageHome as AuthManageHomeView,
  SignUp as SignUpView,
  SignIn as SignInView,
} from './views/Auth';

import {
  SiteManage as CWR_SiteManageView,
  PatientManage as CWR_PatientManageView,
  TestManage as CWR_TestManageView,
  TestPreview as CWR_ReportPreview
} from './views/CWR_Form'

import {
  SiteManage as EPR_SiteManageView,
  EquipmentManage as EquipmentManageView,
  AuditManage as AuditManageView,
  ReportPreview as ReportPreview
} from './views/EPR_Form'


import {
  Home as LogCall_HomeView,
  PrfEdit as PrfEditView,
  PrfView as PrfDetailView,
  FleetManageHome as FleetManageHomeView,
  SendForm as SendFormView
} from './views/LogCall';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />
      <RouteWithLayout
        component={AuthManageHomeView}
        exact
        layout={CollabLayout}
        path="/auth_manage"
      />
      <RouteWithLayout
        component={SignUpView}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/register"
      />
      <RouteWithLayout
        component={SignInView}
        cur_module="Legacy EMS"
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={LogCall_HomeView}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/home"
      />
      <RouteWithLayout
        component={PrfDetailView}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/view_prf"
      />
      <RouteWithLayout
        component={PrfEditView}
        cur_module="Legacy EMS"
        layout={CollabLayout}
        path="/edit_prf"
      />
      <RouteWithLayout
        component={SendFormView}
        cur_module="Legacy EMS"
        layout={CollabLayout}
        path="/send_form"
      />
      <RouteWithLayout
        component={FleetManageHomeView}
        exact
        layout={CollabLayout}
        path="/fleet_manage"
      />
      <RouteWithLayout
        component={CWR_SiteManageView}
        exact
        layout={CollabLayout}
        path="/cwr_form"
      />
      <RouteWithLayout
        component={CWR_PatientManageView}
        exact
        layout={CollabLayout}
        path="/cwr_form/patient"
      />
      <RouteWithLayout
        component={CWR_TestManageView}
        exact
        layout={CollabLayout}
        path="/cwr_form/patient/tests"
      />
      <RouteWithLayout
        component={CWR_ReportPreview}
        exact
        layout={CollabLayout}
        path="/cwr_form/report"
      />
      <RouteWithLayout
        component={EPR_SiteManageView}
        exact
        layout={CollabLayout}
        path="/epr_form"
      />
      {/* EPR */}
      <RouteWithLayout
        component={EquipmentManageView}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/epr_form/equip"
      />
      <RouteWithLayout
        component={AuditManageView}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/epr_form/audit"
      />
      <RouteWithLayout
        component={ReportPreview}
        cur_module="Legacy EMS"
        exact
        layout={CollabLayout}
        path="/epr_form/report"
      />

      {/* <Route exact path="/">
        <LandingView />
      </Route> */}
    </Switch>
  );
};

export default Routes;
