import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import ReactDOMServer  from 'react-dom/server'
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
  Grid,
  Button,
  TextField,
  DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, 
  InputLabel, FormControl,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GroupAdd, Add, Close, ArrowBack, CheckBoxOutlineBlank, CheckBox, ExpandMore, CloudUpload, PhotoCamera} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import AcceptForm from './AcceptForm';
import RefuseForm from './RefuseForm';
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
 
}));

const SendForm = props => {
  const { history } = props;

  const printNetCareFormRef = useRef(null);
  const printGemsFormRef = useRef(null);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loading_mail_medcor, setLoading_MailMedcor] = useState(false);
  const [loading_mail_gems, setLoading_MailGems] = useState(false);
  const [loading_mail_raf, setLoading_MailRaf] = useState(false);
  const [loading_mail_comsol, setLoading_MailComsol] = useState(false);
  const [isDriverCamDialog, showDriverCamDialog] = useState(false);
  const [isHospitalCamDialog, showHospitalCamDialog] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);

  const [prf_Data, setPrfData] = useState({
    tangos : {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details : {},
    patient_history : {},
    chronic_conditions : {},
    haemorrhage : {},
    airway : {},
    breathing : {},
    circulation : {},
    immobilisation : {},
    mornitoring : {},
    iv_access1 : {},
    iv_access2 : {},
    medication_slots : {},
    vital_1 : {},
    vital_2 : {},
    vital_3 : {},
    vital_4 : {},
    vital_5 : {},
    vital_6 : {},
    patient_consent : {},
    signature : {
      patient : 'patient_signature',
      hospital : 'hospital_signature'
    },
    license_img : {
      driver : 'driver_photo',
      hospital : 'hospital_photo'
    },
    
  });

  let registered_user = _sessionStorage.getItem('user');
  useEffect(() => {
    get_auth_status();
    get_prf_data();
  }, []);

  const get_auth_status = async () => {
    setPageLoad(true);
  }

  const get_prf_data =  () => {
    const parsed = qs.parse(document.location.search);
    let prfId = parsed.prf;
    _http.doPost('main/get_one_prf', 
      {
        prf_id : prfId
      }, 
      (data) => {
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          
          setPrfData(data.data);
        }
      },
      (err) => {
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const goHomeUrl =  () => {
    document.location.href = 'home?tab=1';
  }

  const getAcceptFormHtmlString = (data) => {
    let html = ReactDOMServer.renderToString(
      <div
        id="print1"
        style = {{width : '100%'}}
      >
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                align = "left"
                width="33%"
              >
                <img
                  src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                  width="80px"
                />
              </td>
              <td
                style= {{textAlign: 'right', }}
                width="62%"
              >
                <strong>Legacy Emergency Specialists</strong>
                <br/>
                <strong>Route N4 Business Park, Corridor Crescent</strong>
                <br/>
                <strong>9 Bureau de Paul</strong>
                <br/>
                <strong>Ben Fleur</strong>
                <br/>
                <strong>Witbank</strong>
                <br/>
                <strong>www.legacyems.co.za</strong>
                <br/>
                <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
                <br/>
                <strong>Practice Number: 009 003 064 1332</strong>
                <br/>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td style={{textAlign : 'center'}}><strong>PATIENT PAYMENT AGREEMENT AND INDEMNITY FORM</strong></td></tr></tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderBottomWidth : 0 , borderTopWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td width="50%">
                <strong>I. the undersigned:</strong>
              </td>
            </tr>
            <tr><td
              height = "12"
              width="100%"
            /></tr>
            <tr>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000',  }}
                width="33%"
              >
                <strong />
              </td>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
                width="33%"
              >
                <strong />
              </td>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
                width="33%"
              >
                <strong />
              </td>
            </tr>
            <tr>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>Name & Surname of Guarantor/Patient</strong>
              </td>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>ID Number</strong>
              </td>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>PRF No</strong>
              </td>
            </tr>
            <tr><td
              height = "5"
              width="100%"
            /></tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >1)</td>
              <td valign = {'top'}>Hereby give Legacy Emergency Specialists (Pty) Ltd with Registration No. 2016/121004/07, trading as Legacy Emergency Specialists, my consent and permission to transport me to a hospital of my choice, and in the event that the afore-mentioned hospital is not available, any hospital Legacy Emergency Specialists deems fit.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >2)</td>
              <td valign = {'top'}>I further give Legacy Emergency Specialists my consent and permission to stabilize me and/or give me life support and/or any other function or support if needed in the circumstances at hand and/or  as requested by myself.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >3)</td>
              <td valign = {'top'}>By making use of Legacy Emergency Specialist services, i accept that there are costs and fees involved for provided services. I hereby take full responsibility and liability for the payment of their fees and costs as set out in Annexure "A" below.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >4)</td>
              <td valign = {'top'}>I admit that on rendering of the services, all accounts become immediately due and payable to Legacy  Emergency Specialists.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >5)</td>
              <td valign = {'top'}>I agree to Legacy Emergency Specialists submitting the invoice (even before i receive an account) on my behalf to my medical aid where applicable, i confirm that i wil always be responsible and liable for payment of Legacy Emergency Specialists account.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >6)</td> 
              <td valign = {'top'}>In the event that legal action commences regarding the non-payment of Legacy Emergency Specialists fees, I accept that i will be liable for the legal costs on an attorney and client scale, which shall include, but is not limited, to tracing fees and 10% collection commission.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >7)</td>
              <td valign = {'top'}>I hereby indemnify Legacy Emergency Specialists, any of it's director(s), shareholder(s), employee(s) or agent(s)  from any loss and/or damage(s) and/or injury and/or death which i many suffer as a result of any act or omission, whether such loss and/or damages and/or injury and/or death is a result of the negligence of any person relating to any of the services rendered by Legacy Emergency Specialists.</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "15"
            style={{textAlign : 'left'}}
          ><strong>Annexure A</strong></td></tr></tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Description</b></td>
              <td valign = {'top'}><b>Amount</b></td>
            </tr>
            <tr>
              <td valign = {'top'} >Call Out Fee, no Transport, RHT</td>
              <td valign = {'top'}>R 800.00</td>
            </tr>
            <tr>
              <td valign = {'top'} >Declaration of Death</td>
              <td valign = {'top'}>R 1,500.00</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support up to 60 min</td>
              <td valign = {'top'}>R 2,241.00</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support every 15min thereafter</td>
              <td valign = {'top'}>R 561.07</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 27.93</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 12.55</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support up to 60 min</td>
              <td valign = {'top'}>R 2,962.42</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support every 15 min thereafter</td>
              <td valign = {'top'}>R 757.22</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 37.82</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 12.55</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support up to 60 min</td>
              <td valign = {'top'}>R 4,701.46</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support every 15 min thereafter</td>
              <td valign = {'top'}>R 1,534.76</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 68.04</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 12.55</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                height = "12"
                style={{textAlign : 'left'}}
              >
                <span>Signed in acceptance at </span>
                <span style = {{minWidth : '50px', display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span> on the  </span>
                <span style = {{minWidth : 20, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span>day of </span><span style = {{minWidth : 50, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span> year</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                height = "12"
                style = {{borderBottomWidth : 1, borderBottomColor : '#000', borderBottomStyle : 'solid', width : '50%'}}
                width = "50%"
              />
              <td />
            </tr>
            <tr>
              <td
                style = {{fontStyle : 'italic'}}
                width = "50%"
              >
                Signature of Guarantor/Patient
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    )
    return html
  }

  const getRefuseFormHtmlString = (data) => {
    let html = ReactDOMServer.renderToString(
      <div
        id="print1"
        style = {{width : '100%'}}
      >
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                align = "left"
                width="33%"
              >
                <img
                  src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                  width="80px"
                />
              </td>
              <td
                style= {{textAlign: 'right', }}
                width="62%"
              >
                <strong>Legacy Emergency Specialists</strong>
                <br/>
                <strong>Route N4 Business Park, Corridor Crescent</strong>
                <br/>
                <strong>9 Bureau de Paul</strong>
                <br/>
                <strong>Ben Fleur</strong>
                <br/>
                <strong>Witbank</strong>
                <br/>
                <strong>www.legacyems.co.za</strong>
                <br/>
                <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
                <br/>
                <strong>Practice Number: 009 003 064 1332</strong>
                <br/>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td style={{textAlign : 'center'}}><strong>REFUSAL OF TREATMENT, TRANSPORT AND/OR EVALUATION</strong></td></tr></tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Patient Name</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Patient Surname</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>ID Number</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Mobile Number</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>E-mail Address</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Date</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Incident</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Time</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Incident Address</b></td>
              <td
                colSpan = {3}
                valign = {'top'}
              />
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr >
              <td
                style = {{paddingTop : 8, fontWeight : 'bold'}}
                valign = {'top'}
              >Indemnity Form and Legal Waiver</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >Please read completely before signing below</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >Because it is sometimes impossible to recognize actual or potential medical problems outside the hospital, Legacy Emergency Specialists strongly encourage you to be evaluated, treated if neccessary, and transported to a hospital by ambulance for a more complete examination by the Emergency Unit physician.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >You as a patient have the right to choose not to be evaluated, treated or transported if you wish; however, there is the possiblilty that you could suffer serious complications or even death from conditions that are not apparent at this time.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >By signing below, you are acknowledging that Legacy Emergency Specialists personnel have advised you, and that you understand, the potential harm to your health that may result from your refusal of the recommended care; you release Legacy Emergency Specialists (Pty) Ltd and supporting personnel from liability resulting from refusal of treatement, transport and/or Evaluation.</td>
            </tr>
            <tr  >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >If you change your mind and desire evaluation, treatment, and/or transport to a hospital, you may contact us again telephonically at any time.</td>
            </tr>
            <tr >
              <td
                style = {{paddingTop : 3}}
                valign = {'top'}
              >I acknowledge that Legacy Emergency Specialists personnel explained my condition to me and advised me of some of the potential risk(s) and/or complication(s) which could or would arise from refusal of medical care. I have also been advised that other common risk(s) and/or complication(s) are possible up to and including the loss of life or limb. Being aware that there are known and unknown potential risk(s) and/or complication(s), it is still my desire to refuse the advised medical care.</td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Patient Signature</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Signature</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Legacy Signature</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Name</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>Legacy Crew</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Withness ID</b></td>
              <td valign = {'top'} />
            </tr>
            <tr>
              <td valign = {'top'}><b>HPCSA Reg</b></td>
              <td valign = {'top'} />
              <td valign = {'top'}><b>Witness Relation</b></td>
              <td valign = {'top'} />
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "12"
            style={{textAlign : 'left'}}
          ><strong>Person Capacity</strong></td></tr></tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr >
              <td valign = {'top'}  >Orientated to person, time and place?</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Coherent Speech?</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Auditory and/or visual hallucinations</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr >
              <td valign = {'top'} >Suicidal or Homicida ? SAPS notified</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              />
            </tr>
            <tr>
              <td valign = {'top'} >Under influence of alcohol/narcotics</td>
              <td
                align = "center"
                valign = {'top'}
                width = "50"
              >yes</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "12"
            style={{textAlign : 'left'}}
          ><strong>Vital Signs</strong></td></tr></tbody>
        </table>
        <table
          border = "1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
          width="99%"
        >
          <tbody>
            <tr >
              <td valign = {'top'}>BP</td>
              <td valign = {'top'} />
              <td valign = {'top'}>Pulse</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>Sats</td>
              <td valign = {'top'} />
              <td valign = {'top'}>R Rate</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>GCS</td>
              <td valign = {'top'} />
              <td valign = {'top'}>Pain</td>
              <td valign = {'top'} />
            </tr>
            <tr >
              <td valign = {'top'}>Pupils</td>
              <td valign = {'top'} />
              <td valign = {'top'}>HGT</td>
              <td valign = {'top'} />
            </tr>
          </tbody>
        </table>
      </div>
    )
    return html
  }

  const send_MailMedcor = () => {
    // to : 'feliciat@telkomsa.net, gene@legacyems.co.za, hesmarie@legacyems.co.za, test-mail@legacyems.co.za', 
    setLoading_MailMedcor(true);
    let acceptform_html = getAcceptFormHtmlString({test1: 'aaaaaaa', test2 : 'bbbbbbb', test3 : 'ccccccc'});
    console.log('acceptform_html')
    console.log(acceptform_html)
    _http.doPost('main/send_email', 
      {
        to : 'test-mail@legacyems.co.za', 
        orientation : 'portrait',
        subject : 'Please find attached from Legacy Emergency Specialists',
        pdf_name : 'Patient-Agreement-' + prf_Data.id + '-' + prf_Data.patient_details.first_name + '-' +
           prf_Data.patient_details.last_name + '-' + prf_Data.patient_details.id_no + '-' + prf_Data.medical_aid.medical_aid_name + '.pdf',
        html_str : acceptform_html
      }, 
      (data) => {
        setLoading_MailMedcor(false);
        if(data.status == false)
        {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        }
        else
        {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      (err) => {
        setLoading_MailMedcor(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const send_MailGems = () => {
    // to : 'medclaims8@medcorebilling.co.za, gene@legacyems.co.za, admin@legacyems.co.za, emsprimary@gems.gov.za, test-mail@legacyems.co.za', 
    setLoading_MailGems(true);
    let refuseform_html = getRefuseFormHtmlString();
    console.log('refuseform_html')
    console.log(refuseform_html)

    _http.doPost('main/send_email', 
      {
        to : 'test-mail@legacyems.co.za', 
        orientation : 'portrait',
        subject : 'Please find attached from Legacy Emergency Specialists',
        pdf_name : 'Refusal-Treatment-' + prf_Data.id + '-' + prf_Data.patient_details.first_name + '-' +
           prf_Data.patient_details.last_name + '-' + prf_Data.patient_details.id_no + '-' + prf_Data.medical_aid.medical_aid_name + '.pdf',
        html_str : refuseform_html
      }, 
      (data) => {
        setLoading_MailGems(false);
        if(data.status == false)
        {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        }
        else
        {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      (err) => {
        setLoading_MailGems(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }


  return (
    <div className={classes.root} >
      <Grid
        container
        style = {{justifyContent : 'center', width : '100%'}}
      >
        <Grid
          container
          item
          lg={12}
          md = {12}
          sm = {12}
          style = {{paddingTop : 20}}
        >
          <Grid
            container
            item
            lg={2}
            md = {2}
            sm = {12}
            style = {{padding : 20}}
          >
            <Button
              color = "primary"
              onClick = {() => goHomeUrl()}
              startIcon = {<ArrowBack />}
            >
              Go Back to List</Button> 
          </Grid>
          <Grid
            container
            item
            lg={2}
            md = {2}
            sm = {12}
            style = {{paddingLeft : 10, paddingRight : 10, paddingTop : 8}}
          >
            <LoadingButton
              fullWidth
              label = "Mail to Medcor"
              label_loading = "Mail to Medcor"  
              loading = {loading_mail_medcor}
              onClick = {send_MailMedcor}
              style = {{width : '100%'}}
            /> 
          </Grid>
          <Grid
            container
            item
            lg={2}
            md = {2}
            sm = {12}
            style = {{paddingLeft : 10, paddingRight : 10, paddingTop : 8}}
          >
            <LoadingButton
              fullWidth
              label = "Mail to Medcor & GEMS"
              label_loading = "Mail to Medcor & GEMS"  
              loading = {loading_mail_gems}
              onClick = {send_MailGems}
              style = {{width : '100%'}}
            /> 
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md = {12}
          sm = {12}
          style = {{width : '100%', padding : '10px', paddingTop : 20}}
        >
          <h4
            id="document-heading8"
            style= {{color:'#000000', paddingLeft : 15}}
          >Accept FORM</h4>
          <div
            ref = {printNetCareFormRef}
            style = {{width : '100%', }}
          >
            <AcceptForm prfData = {prf_Data} />   
          </div>
          <h4
            id="document-heading8"
            style= {{color:'#000000', paddingLeft : 15}}
          >GEMS FORM</h4>
          <div
            ref = {printGemsFormRef}
            style = {{width : '100%',}}
          >
            <RefuseForm  prfData = {prf_Data} />  
          </div>
          {/* <h4 id="document-heading9" style= {{color:'#000000', paddingLeft : 15}}>PRF DATA</h4>   
          <NetcareForm prfData = {prf_Data} />    */}
        </Grid>
      </Grid>
      
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showDriverCamDialog(false)}
        onClose={() => showDriverCamDialog(false)}
        open={isDriverCamDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          {/* <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
              <IconButton onClick = {() =>showDialog(false)}><Close /></IconButton>
              <span style = {{fontSize : '16'}}>Capture Image</span>
            </div> */}
            
        </DialogContent>
      </Dialog>
        
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showHospitalCamDialog(false)}
        onClose={() => showHospitalCamDialog(false)}
        open={isHospitalCamDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          {/* <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
              <IconButton onClick = {() =>showDialog(false)}><Close /></IconButton>
              <span style = {{fontSize : '16'}}>Capture Image</span>
            </div> */}
        
        </DialogContent>
      </Dialog>
    </div>
  );
};

SendForm.propTypes = {
  history: PropTypes.object
};

export default withRouter(SendForm);
