import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GroupAdd,
  Add,
  Close,
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBox,
  ExpandMore,
  CloudUpload,
  PhotoCamera
} from '@material-ui/icons';
import {
  config,
  _http,
  _sessionStorage,
  _auth_check,
  _crypto,
  _localStorage,
  getInitials
} from 'helpers';
import { LoadingButton, PageLoading } from 'components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import SignatureCanvas from 'react-signature-canvas';
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff'
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  table_font: {
    fontSize: '14px !important',

    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      fontSize: '10px !important'
    }
  },
  table1: {
    fontSize: '60%',
    lineHeight: '10px'
  },
  license_img_wrapper_pc: {
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  license_img_wrapper_phone: {
    [theme.breakpoints != null && theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const ClinicReport = props => {
  const { history, report_data, site_name, reportDate, fontSize } = props;
  const classes = useStyles();

  const [green_cnt, setGreenCnt] = useState(0);
  const [amber_cnt, setAmberCnt] = useState(0);
  const [red_consult_cnt, setRedConsultCnt] = useState(0);
  const [red_unfit_cnt, setRedUnfitCnt] = useState(0);
  const [hiv_cnt, setHivCnt] = useState(0);
  const [drug_cnt, setDrugCnt] = useState(0);
  const [bp_cnt, setBPCnt] = useState(0);
  const [bg_cnt, setBGCnt] = useState(0);
  const [pulse_cnt, setPulseCnt] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [report_date, setReportDate] = useState(
    moment(new Date()).format('D MMMM YYYY')
  );

  useEffect(() => {
    setReportDate(moment(reportDate).format('D MMMM YYYY'));
    setReportData(report_data);
    let tmp_green_cnt = 0;
    let tmp_amber_cnt = 0;
    let tmp_red_consult_cnt = 0;
    let tmp_red_unfit_cnt = 0;
    let tmp_hiv_cnt = 0;
    let tmp_drug_cnt = 0;
    let tmp_bp_cnt = 0;
    let tmp_bg_cnt = 0;
    let tmp_pulse_cnt = 0;
    report_data.map((report, index) => {
      if (
        report.tests.length > 0 &&
        moment(new Date(report.tests[0].consult_date)).format('D MMMM YYYY') ==
          moment(reportDate).format('D MMMM YYYY')
      ) {
        if (report.tests[0].hiv_test != 'Not done') {
          tmp_hiv_cnt = tmp_hiv_cnt + 1;
        }
        if (report.tests[0].drug_test != 'Not done') {
          tmp_drug_cnt = tmp_drug_cnt + 1;
        }
        if (
          (report.tests[0].blood_pressure_higher != null &&
            report.tests[0].blood_pressure_higher != '') ||
          (report.tests[0].blood_pressure_lower != null &&
            report.tests[0].blood_pressure_lower != '')
        ) {
          tmp_bp_cnt = tmp_bp_cnt + 1;
        }
        if (
          report.tests[0].blood_glucose != null &&
          report.tests[0].blood_glucose != ''
        ) {
          tmp_bg_cnt = tmp_bg_cnt + 1;
        }
        if (
          report.tests[0].pulse_rate != null &&
          report.tests[0].pulse_rate != ''
        ) {
          tmp_pulse_cnt = tmp_pulse_cnt + 1;
        }
        let tmp_bloodpressure_color = getBloodPressureColor(
          report.tests[0].blood_pressure_higher || '',
          report.tests[0].blood_pressure_lower || ''
        );
        let tmp_pulserate_color = getPulseRateColor(
          report.tests[0].pulse_rate || ''
        );
        let tmp_bloodglucose_color = getGluCoseColor(
          report.tests[0].blood_glucose || ''
        );
        if (
          tmp_bloodpressure_color == 'lightgreen' &&
          tmp_pulserate_color == 'lightgreen' &&
          tmp_bloodglucose_color == 'lightgreen'
        ) {
          tmp_green_cnt = tmp_green_cnt + 1;
        } else if (
          tmp_bloodpressure_color == 'red' ||
          tmp_pulserate_color == 'red' ||
          tmp_bloodglucose_color == 'red'
        ) {
          if (report.tests[0].patient_unfit == true) {
            tmp_red_unfit_cnt = tmp_red_unfit_cnt + 1;
          } else {
            tmp_red_consult_cnt = tmp_red_consult_cnt + 1;
          }
        } else {
          tmp_amber_cnt = tmp_amber_cnt + 1;
        }
      }
    });
    setGreenCnt(tmp_green_cnt);
    setAmberCnt(tmp_amber_cnt);
    setRedConsultCnt(tmp_red_consult_cnt);
    setRedUnfitCnt(tmp_red_unfit_cnt);
    setHivCnt(tmp_hiv_cnt);
    setDrugCnt(tmp_drug_cnt);
    setBPCnt(tmp_bp_cnt);
    setBGCnt(tmp_bg_cnt);
    setPulseCnt(tmp_pulse_cnt);
  }, [report_data, reportDate]);

  const getGluCoseColor = blood_glucose => {
    if (blood_glucose == '') return '';
    if (blood_glucose >= 0 && blood_glucose <= 3.5) return 'red';
    if (blood_glucose >= 3.6 && blood_glucose <= 6.3) return 'lightgreen';
    if (blood_glucose >= 6.4 && blood_glucose <= 9.0) return 'yellow';
    if (blood_glucose >= 9.1) return 'red';
  };

  const getPulseRateColor = pulse_rate => {
    if (pulse_rate == '') return '';
    if (pulse_rate > 0 && pulse_rate <= 50) return 'red';
    if (pulse_rate >= 51 && pulse_rate <= 85) return 'lightgreen';
    if (pulse_rate >= 86) return 'red';
  };

  const getBloodPressureColor = (high, low) => {
    if (high == '' && low == '') return '';

    if (high <= 120 && low <= 80) {
      return 'lightgreen';
    } else if (high > 120 && high <= 129 && low <= 80) {
      return '#f8f000';
    } else if ((high > 130 && high <= 139) || (low > 80 && low <= 89)) {
      return '#f7a20b';
    } else if ((high > 140 && high <= 180) || (low > 90 && low <= 120)) {
      return 'red'; //'#a34a21'
    } else if (high > 180 || low > 120) {
      return 'red'; // '#a01a20'
    } else {
      return 'red';
    }
  };

  const getHivDrugColor = value => {
    if (value == '') return '';
    if (value == 'Positive') return 'red';
    if (value == 'Negative') return 'lightgreen';
  };

  return (
    <Grid
      className={classes.table_font}
      container
      item
      lg={12}
      md={12}
      sm={12}
      style={{ width: '100%' }}>
      <div id="print1" style={{ width: '100%' }}>
        <div data-first-table style={{ width: '100%' }}>
          <table
            style={{
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td width="20%" />
                <td width="60%">
                  <center>
                    <img
                      src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                      width="140px"
                    />
                    <div
                      style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        marginTop: 15
                      }}>
                      Clinical Report
                    </div>
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        marginTop: 20
                      }}>
                      {site_name}
                    </div>
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        marginTop: 15
                      }}>
                      {report_date}
                    </div>
                  </center>
                </td>
                <td style={{ textAlign: 'right' }} width="20%" />
              </tr>
            </tbody>
          </table>
          <div style={{ width: '100%', marginTop: 25, textAlign: 'center' }}>
            <center>
              <table
                border="1"
                style={{
                  overflowWrap: 'anywhere',
                  tableLayout: 'fixed',
                  wordWrap: 'break-word',
                  borderCollapse: 'collapse'
                }}
                width="96%">
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ backgroundColor: 'lightgray' }}
                      width="50%">
                      <strong>Patients</strong>
                    </td>
                    <td style={{ backgroundColor: 'lightgray' }} width="50%">
                      Description
                    </td>
                  </tr>
                  <tr>
                    <td width="30%">
                      <strong>Green</strong>
                    </td>
                    <td width="20%">{green_cnt}</td>
                    <td width="50%">FIT FOR WORK</td>
                  </tr>
                  <tr>
                    <td width="30%">
                      <strong>Amber</strong>
                    </td>
                    <td width="20%">{amber_cnt}</td>
                    <td width="50%">Patient needs to be monitored</td>
                  </tr>
                  <tr>
                    <td width="30%">
                      <strong>Yellow - Consult</strong>
                    </td>
                    <td width="20%">{red_consult_cnt}</td>
                    <td width="50%">Consult Dr immediately</td>
                  </tr>
                  <tr>
                    <td width="30%">
                      <strong>Red - Unfit</strong>
                    </td>
                    <td width="20%">{red_unfit_cnt}</td>
                    <td width="50%">
                      Do not continue work, to be referred to occupational
                      clinic.
                    </td>
                  </tr>
                  <tr>
                    <td width="30%">
                      <strong>Total</strong>
                    </td>
                    <td colSpan={2} width="70%">
                      {green_cnt + amber_cnt + red_consult_cnt + red_unfit_cnt}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                border="1"
                style={{
                  overflowWrap: 'anywhere',
                  marginTop: 20,
                  tableLayout: 'fixed',
                  wordWrap: 'break-word',
                  borderCollapse: 'collapse'
                }}
                width="96%">
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ backgroundColor: 'lightgray' }}>
                      <strong>Tests performed</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="70%">
                      <strong>HIV tests</strong>
                    </td>
                    <td width="30%">{hiv_cnt}</td>
                  </tr>
                  <tr>
                    <td width="70%">
                      <strong>Drug tests</strong>
                    </td>
                    <td width="30%">{drug_cnt}</td>
                  </tr>
                  <tr>
                    <td width="70%">
                      <strong>Hypertension</strong>
                    </td>
                    <td width="30%">{bp_cnt}</td>
                  </tr>
                  <tr>
                    <td width="70%">
                      <strong>Blood Glucose</strong>
                    </td>
                    <td width="30%">{bg_cnt}</td>
                  </tr>
                  <tr>
                    <td width="70%">
                      <strong>Pulse</strong>
                    </td>
                    <td width="30%">{pulse_cnt}</td>
                  </tr>
                </tbody>
              </table>
            </center>
          </div>
        </div>
        {reportData.map((report, index) =>
          report.tests.length > 0 &&
          moment(new Date(report.tests[0].consult_date)).format(
            'D MMMM YYYY'
          ) == moment(reportDate).format('D MMMM YYYY') ? (
            <div data-patient-table style={{ width: '100%' }}>
              <table
                border="1"
                style={{
                  overflowWrap: 'anywhere',
                  tableLayout: 'fixed',
                  wordWrap: 'break-word',
                  borderCollapse: 'collapse'
                }}
                width="99%">
                <tbody>
                  <tr>
                    <td colSpan={5} style={{ backgroundColor: 'lightgray' }}>
                      <strong>
                        {report.patient_name +
                          ' ' +
                          report.patient_surname +
                          ', ' +
                          report.id_no +
                          ', ' +
                          site_name}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Consultation Date</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? moment(new Date(report.tests[0].consult_date)).format(
                            'D MMMM YYYY'
                          )
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? moment(new Date(report.tests[1].consult_date)).format(
                            'D MMMM YYYY'
                          )
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? moment(new Date(report.tests[2].consult_date)).format(
                            'D MMMM YYYY'
                          )
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? moment(new Date(report.tests[3].consult_date)).format(
                            'D MMMM YYYY'
                          )
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Age</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].patient_age
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].patient_age
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].patient_age
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].patient_age
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Chronic Conditions</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].chronic_conditions
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].chronic_conditions
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].chronic_conditions
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].chronic_conditions
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Chronic Medication</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].chronic_medication
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].chronic_medication
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].chronic_medication
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].chronic_medication
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Smoker</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].smoker
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].smoker
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[1].smoker
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[1].smoker
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Weight</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].weight
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].weight
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].weight
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].weight
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Height</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].height
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].height
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].height
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].height
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Hypertension</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 0
                            ? getBloodPressureColor(
                                report.tests[0].blood_pressure_higher || '',
                                report.tests[0].blood_pressure_lower || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? (report.tests[0].blood_pressure_higher || '') +
                          ' over ' +
                          (report.tests[0].blood_pressure_lower || '')
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 1
                            ? getBloodPressureColor(
                                report.tests[1].blood_pressure_higher || '',
                                report.tests[1].blood_pressure_lower || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? (report.tests[1].blood_pressure_higher || '') +
                          ' over ' +
                          (report.tests[1].blood_pressure_lower || '')
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 2
                            ? getBloodPressureColor(
                                report.tests[2].blood_pressure_higher || '',
                                report.tests[2].blood_pressure_lower || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? (report.tests[2].blood_pressure_higher || '') +
                          ' over ' +
                          (report.tests[2].blood_pressure_lower || '')
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 3
                            ? getBloodPressureColor(
                                report.tests[3].blood_pressure_higher || '',
                                report.tests[3].blood_pressure_lower || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? (report.tests[3].blood_pressure_higher || '') +
                          ' over ' +
                          (report.tests[3].blood_pressure_lower || '')
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Pulse Rate</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 0
                            ? getPulseRateColor(
                                report.tests[0].pulse_rate || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].pulse_rate
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 1
                            ? getPulseRateColor(
                                report.tests[1].pulse_rate || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].pulse_rate
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 2
                            ? getPulseRateColor(
                                report.tests[2].pulse_rate || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].pulse_rate
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          report.tests != null && report.tests.length > 3
                            ? getPulseRateColor(
                                report.tests[3].pulse_rate || ''
                              )
                            : ''
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].pulse_rate
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Blood Glucose</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: getGluCoseColor(
                          report.tests != null && report.tests.length > 0
                            ? report.tests[0].blood_glucose
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].blood_glucose
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getGluCoseColor(
                          report.tests != null && report.tests.length > 1
                            ? report.tests[1].blood_glucose
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].blood_glucose
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getGluCoseColor(
                          report.tests != null && report.tests.length > 2
                            ? report.tests[2].blood_glucose
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].blood_glucose
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getGluCoseColor(
                          report.tests != null && report.tests.length > 3
                            ? report.tests[3].blood_glucose
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].blood_glucose
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>HIV Test</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 0
                            ? report.tests[0].hiv_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].hiv_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 1
                            ? report.tests[1].hiv_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].hiv_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 2
                            ? report.tests[2].hiv_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].hiv_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 3
                            ? report.tests[3].hiv_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].hiv_test
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Drug Test</strong>
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 0
                            ? report.tests[0].drug_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].drug_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 1
                            ? report.tests[1].drug_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].drug_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 2
                            ? report.tests[2].drug_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].drug_test
                        : ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: getHivDrugColor(
                          report.tests != null && report.tests.length > 3
                            ? report.tests[3].drug_test
                            : ''
                        )
                      }}
                      width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].drug_test
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Advice given</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].advice_given
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].advice_given
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].advice_given
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].advice_given
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Improvement</strong>
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 0
                        ? report.tests[0].improvement
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 1
                        ? report.tests[1].improvement
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 2
                        ? report.tests[2].improvement
                        : ''}
                    </td>
                    <td width="20%">
                      {report.tests != null && report.tests.length > 3
                        ? report.tests[3].improvement
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null
        )}
      </div>
    </Grid>
  );
};

export default ClinicReport;
