import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GroupAdd,
  Add,
  Close,
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBox,
  ExpandMore,
  CloudUpload,
  PhotoCamera
} from '@material-ui/icons';
import {
  config,
  _http,
  _sessionStorage,
  _auth_check,
  _crypto,
  _localStorage,
  getInitials
} from 'helpers';
import { LoadingButton, PageLoading } from 'components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import NetcareForm from './NetcareForm';
import GemsForm from './GemsForm';
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff'
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  }
}));

const PrfView = props => {
  const { history } = props;

  const printNetCareFormRef = useRef(null);
  const printGemsFormRef = useRef(null);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loading_mail_medcor, setLoading_MailLegacy] = useState(false);
  const [loading_mail_gems, setLoading_MailGems] = useState(false);
  const [loading_mail_raf, setLoading_MailRaf] = useState(false);
  const [loading_mail_comsol, setLoading_MailComsol] = useState(false);
  const [isConfirmDialog, showConfirmDialog] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);

  const [cur_mail_target, setCurMailTarget] = useState(0);
  const mail_target_name = ['Legacy', 'Mail option 2'];

  const [prf_Data, setPrfData] = useState({
    tangos: {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details: {},
    patient_history: {},
    chronic_conditions: {},
    haemorrhage: {},
    airway: {},
    breathing: {},
    circulation: {},
    immobilisation: {},
    mornitoring: {},
    iv_access1: {},
    iv_access2: {},
    medication_slots: {},
    vital_1: {},
    vital_2: {},
    vital_3: {},
    vital_4: {},
    vital_5: {},
    vital_6: {},
    patient_consent: {},
    signature: {
      patient: 'patient_signature',
      hospital: 'hospital_signature'
    },
    license_img: {
      driver: 'driver_photo',
      hospital: 'hospital_photo'
    }
  });

  let registered_user = _sessionStorage.getItem('user');
  useEffect(() => {
    get_auth_status();
    get_prf_data();
  }, []);

  const get_auth_status = async () => {
    setPageLoad(true);
  };

  const get_prf_data = () => {
    const parsed = qs.parse(document.location.search);
    let prfId = parsed.prf;
    _http.doPost(
      'main/get_one_prf',
      {
        prf_id: prfId
      },
      data => {
        if (data.status == false) {
          alert(data.data);
        } else {
          setPrfData(data.data);
        }
      },
      err => {
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const goHomeUrl = () => {
    document.location.href = 'home?tab=1';
  };

  const doSaveDraft = () => {
    // let html = ReactDOMServer.renderToString(
    //  <div></div>
    //  )
    // console.log('html', html)
  };

  const send_MailLegacy = () => {
    setLoading_MailLegacy(true);
    let netcareform_html = printNetCareFormRef.current.innerHTML;
    console.log('netcareform_html');
    netcareform_html = netcareform_html.replaceAll(
      'overflow-wrap: break-word; table-layout: fixed;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );
    // for safari
    netcareform_html = netcareform_html.replaceAll(
      'table-layout: fixed; word-wrap: break-word;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );

    netcareform_html = netcareform_html.replaceAll(
      'style="width: 400px; object-fit: contain;',
      'style="width: 100%; object-fit: contain;'
    );
    netcareform_html = netcareform_html.replaceAll(
      'width: 100%; border: none; margin-top: 10px;',
      'width: 100%; height: 200mm; margin-top: 10px;'
    );
    netcareform_html = netcareform_html.replaceAll();
    console.log(netcareform_html);
    _http.doPost(
      'main/send_email',
      {
        to: 'prf@legacyems.co.za',
        orientation: 'portrait',
        subject: 'Please find attached from Legacy Emergency Medical Services',
        pdf_name:
          'PRF-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: netcareform_html
      },
      data => {
        //setLoading_MailLegacy(false);
        if (data.status == false) {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        } else {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      err => {
        //setLoading_MailLegacy(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const send_MailGems = () => {
    setLoading_MailGems(true);
    console.log('gemseform_html');
    let gemseform_html = printGemsFormRef.current.innerHTML;
    gemseform_html = gemseform_html.replaceAll(
      'overflow-wrap: break-word; table-layout: fixed;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 50%; line-height : 10px;'
    );
    // for safari
    gemseform_html = gemseform_html.replaceAll(
      'table-layout: fixed; word-wrap: break-word;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 50%; line-height : 10px;'
    );
    gemseform_html = gemseform_html.replaceAll(
      'style="width: 400px; object-fit: contain;',
      'style="width: 100%; object-fit: contain;'
    );
    console.log(gemseform_html);
    _http.doPost(
      'main/send_email',
      {
        to: 'admin@legacyems.co.za, emsprimary@gems.gov.za',
        orientation: 'landscape',
        subject: 'Please find attached from Legacy Emergency Specialists',
        pdf_name:
          'PRF-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: gemseform_html
      },
      data => {
        setLoading_MailGems(false);
        if (data.status == false) {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        } else {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      err => {
        setLoading_MailGems(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const send_MailRAF = () => {
    setLoading_MailRaf(true);
    let netcareform_html = printNetCareFormRef.current.innerHTML;
    netcareform_html = netcareform_html.replaceAll(
      'overflow-wrap: break-word; table-layout: fixed;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );
    // for safari
    netcareform_html = netcareform_html.replaceAll(
      'table-layout: fixed; word-wrap: break-word;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );

    netcareform_html = netcareform_html.replaceAll(
      'break-after: page; overflow: hidden; margin-top: 10px;',
      'display : none'
    );
    netcareform_html = netcareform_html.replaceAll(
      'width: 100%; border: none; margin-top: 10px;',
      'width: 100%; height: 200mm; margin-top: 10px;'
    );

    _http.doPost(
      'main/send_email',
      {
        to: 'admin@legacyems.co.za, melissa@beerco.co.za',
        orientation: 'portrait',
        subject: 'Please find attached from Legacy Emergency Specialists',
        pdf_name:
          'PRF-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: netcareform_html
      },
      data => {
        setLoading_MailRaf(false);
        if (data.status == false) {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        } else {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      err => {
        setLoading_MailRaf(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const send_MailComsol = () => {
    setLoading_MailComsol(true);
    let netcareform_html = printNetCareFormRef.current.innerHTML;
    netcareform_html = netcareform_html.replaceAll(
      'overflow-wrap: break-word; table-layout: fixed;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );
    // for safari
    netcareform_html = netcareform_html.replaceAll(
      'table-layout: fixed; word-wrap: break-word;',
      'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;'
    );

    netcareform_html = netcareform_html.replaceAll(
      'break-after: page; overflow: hidden; margin-top: 10px;',
      'display : none'
    );
    netcareform_html = netcareform_html.replaceAll(
      'width: 100%; border: none; margin-top: 10px;',
      'width: 100%; height: 200mm; margin-top: 10px;'
    );

    console.log('comsol', netcareform_html);
    _http.doPost(
      'main/send_email',
      {
        to: 'admin@legacyems.co.za',
        orientation: 'portrait',
        subject: 'Please find attached from Legacy Emergency Specialists',
        pdf_name:
          'PRF-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: netcareform_html
      },
      data => {
        setLoading_MailComsol(false);
        if (data.status == false) {
          alert(JSON.stringify(data.data));
          console.log('reply', data.data);
        } else {
          alert(data.data);
          console.log('reply', data.data);
        }
      },
      err => {
        setLoading_MailComsol(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const confirmSend = () => {
    if (cur_mail_target == 0) {
      send_MailLegacy();
    } else if (cur_mail_target == 1) {
      send_MailGems();
    } else if (cur_mail_target == 2) {
      send_MailRAF();
    } else if (cur_mail_target == 3) {
      send_MailComsol();
    }
    showConfirmDialog(false);
  };

  return pageLoaded == false ? (
    <PageLoading />
  ) : (
    <div className={classes.root}>
      <Grid container style={{ justifyContent: 'center', width: '100%' }}>
        <Grid container item lg={12} md={12} sm={12} style={{ paddingTop: 20 }}>
          <Grid container item lg={2} md={2} sm={12} style={{ padding: 20 }}>
            <Button
              color="primary"
              onClick={() => goHomeUrl()}
              startIcon={<ArrowBack />}>
              Go Back to List
            </Button>
          </Grid>
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={12}
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 8 }}>
            <LoadingButton
              fullWidth
              label="Mail to Legacy"
              label_loading="Mail to Legacy"
              loading={loading_mail_medcor}
              onClick={() => {
                setCurMailTarget(0);
                showConfirmDialog(true);
              }}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <h4
            id="document-heading8"
            style={{ color: '#000000', paddingLeft: 15 }}>
            NETCARE FORM
          </h4>
          <div ref={printNetCareFormRef} style={{ width: '100%' }}>
            <NetcareForm prfData={prf_Data} />
          </div>
          
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showConfirmDialog(false)}
        open={isConfirmDialog}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>
            Are you sure to send this prf to {mail_target_name[cur_mail_target]}
            ?
          </span>
        </DialogTitle>
        <DialogContent className={classes.dialog} />
        <DialogActions>
          <Button color="primary" onClick={() => showConfirmDialog(false)}>
            Close
          </Button>
          <Button color="primary" onClick={() => confirmSend()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PrfView.propTypes = {
  history: PropTypes.object
};

export default withRouter(PrfView);
