import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    backgroundColor : 'green'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <div style = {{display : 'inline-flex'}}>
            <img
              alt="Logo"
              height = "30"
              src="/images/system/logo.png"
              width = "45"
            />
            <p style = {{marginLeft : 6, paddingTop : 5, color : '#fff', fontWeight : 'bold'}}>LEGACY EMERGENCY MEDICAL SERVICES</p>
          </div>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
