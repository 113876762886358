import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Divider,
  FormControlLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GroupAdd,
  Add,
  Close,
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBox,
  ExpandMore,
  CloudUpload,
  PhotoCamera,
  Crop,
  Refresh
} from '@material-ui/icons';
import {
  config,
  _http,
  _sessionStorage,
  _auth_check,
  _crypto,
  _localStorage,
  getInitials
} from 'helpers';
import { LoadingButton, PageLoading, Camera } from 'components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import SignatureCanvas from 'react-signature-canvas';
import * as qs from 'query-string';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  send_AcceptForm,
  send_RefusalForm,
  getAcceptFormHtmlString,
  getRefuseFormHtmlString
} from '../SendForm/SendFormLib';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff'
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    overflowY: 'auto'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 35,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    // marginTop: theme.spacing(1),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'none'
  },
  avatar: {
    width: 120,
    height: 120
  },
  formControl: {
    minWidth: 150
  },
  tablecell: {
    padding: 0,
    paddingTop: 6,
    paddingBottom: 6
  },
  sigCanvas: {
    width: '100%',
    height: '250px'
  }
}));

const Users = props => {
  const { history } = props;

  const driver_photo_file_input = useRef(null);
  const hospital_photo_file_input = useRef(null);
  const signature_canvas_item = useRef(null);
  const witness_signature_canvas_item = useRef(null);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [save_draft_loading, setDraftLoading] = useState(false);
  const [delete_draft_loading, setDeleteDraftLoading] = useState(false);
  const [start_index, setStartIndex] = useState(0);
  const [isDriverCamDialog, showDriverCamDialog] = useState(false);
  const [isHospitalCamDialog, showHospitalCamDialog] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);
  const [crew_members, setCrewMembers] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [driver_photo, setDriverPhoto] = useState('');
  const [hospital_photo, setHospitalPhoto] = useState('');
  const [patient_signature, setPatientSignature] = useState(
    'http://www.legacyems.co.za/placeholders/patient.jpg'
  );
  const [hospital_signature, setHospitalSignature] = useState(
    'http://www.legacyems.co.za/placeholders/hospital.jpg'
  );

  const [formState, setFormState] = useState({
    tangos: {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details: {},
    patient_history: {},
    chronic_conditions: {},
    haemorrhage: {},
    airway: {},
    breathing: {},
    circulation: {},
    immobilisation: {},
    mornitoring: {},
    iv_access1: {},
    iv_access2: {},
    medication_slots: {},
    vital_1: {},
    vital_2: {},
    vital_3: {},
    vital_4: {},
    vital_5: {},
    vital_6: {},
    patient_consent: {}
  });

  let registered_user = _sessionStorage.getItem('user');

  useEffect(() => {
    get_auth_status();
    // get_all_users();
    // get_all_vehicles();
    // get_draft();
    //
    get_all_data();
  }, []);

  const get_auth_status = async () => {
    // setPageLoad(true);
  };

  const get_all_data = async () => {
    setPageLoad(false);
    // get_all_users
    let res1 = await _http.doPost_NoCallback('auth/users/get', {});
    if (res1 == null) {
      // alert('Error occured');
    } else {
      if (res1.status == false) {
        alert(res1.data);
      } else {
        setUsers(res1.data);
      }
    }
    // get_all_vehicles
    let res2 = await _http.doPost_NoCallback('main/get_vehicles', {});
    if (res2 == null) {
      // alert('Error occured');
    } else {
      if (res2.status == false) {
        alert(res2.data);
      } else {
        setVehicles(res2.data);
      }
    }
    // get_draft
    let res3 = await _http.doPost_NoCallback('main/get_draft_prf', {
      user_id: registered_user.id
    });
    if (res3 == null) {
      // alert('Error occured');
    } else {
      if (res3.status == false) {
        alert(res3.data);
      } else {
        if (res3.data != null) {
          setFormState({
            tangos: res3.data.tangos,
            call_information: res3.data.call_information,
            patient_details: res3.data.patient_details,
            medical_aid: res3.data.medical_aid,
            crew: res3.data.crew,
            raf_details: res3.data.raf_details,
            patient_history: res3.data.patient_history,
            chronic_conditions: res3.data.chronic_conditions,
            haemorrhage: res3.data.haemorrhage,
            airway: res3.data.airway,
            breathing: res3.data.breathing,
            circulation: res3.data.circulation,
            immobilisation: res3.data.immobilisation,
            mornitoring: res3.data.mornitoring,
            iv_access1: res3.data.iv_access1,
            iv_access2: res3.data.iv_access2,
            medication_slots: res3.data.medication_slots,
            vital_1: res3.data.vital_1,
            vital_2: res3.data.vital_2,
            vital_3: res3.data.vital_3,
            vital_4: res3.data.vital_4,
            vital_5: res3.data.vital_5,
            vital_6: res3.data.vital_6,
            patient_consent: res3.data.patient_consent
          });

          setCrewMembers(res3.data.crew);
          setPatientSignature(res3.data.signature.patient);
          setHospitalSignature(res3.data.signature.hospital);
          setDriverPhoto(res3.data.license_img.driver);
          setHospitalPhoto(res3.data.license_img.hospital);
        }
      }
    }
    setPageLoad(true);
  };

  const get_all_users = () => {
    _http.doPost(
      'auth/users/get',
      {},
      data => {
        if (data.status == false) {
          // alert(data.data);
        } else {
          setUsers(data.data);
        }
      },
      err => {
        console.log(err);
        // alert('Error occured');
      }
    );
  };

  const get_all_vehicles = () => {
    setPageLoad(false);
    _http.doPost(
      'main/get_vehicles',
      {},
      data => {
        setPageLoad(true);
        if (data.status == false) {
          alert(data.data);
        } else {
          setVehicles(data.data);
        }
      },
      err => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const verifyTangoKM = () => {
    try {
      for (var i = 1; i < 5; i++) {
        if (
          formState.tangos['tango_' + i + '_km'] != null &&
          parseInt(formState.tangos['tango_' + i + '_km']) != NaN
        ) {
          for (var j = i + 1; j < 6; j++) {
            if (
              formState.tangos['tango_' + j + '_km'] != null &&
              parseInt(formState.tangos['tango_' + j + '_km']) != NaN
            ) {
              if (
                parseInt(formState.tangos['tango_' + i + '_km']) >
                parseInt(formState.tangos['tango_' + j + '_km'])
              ) {
                alert(
                  'The tango ' +
                    i +
                    ' km can not be set as bigger than tango ' +
                    j +
                    ' km.'
                );
                return false;
              }
            }
          }
        }
      }

      return true;
    } catch (error) {
      alert('invalid tango values');
      return false;
    }
  };

  const doJobAfterCreateCall = async prf_Data => {
    // email sending of accept payment form
    if (
      formState.patient_consent.accept_treatment != null &&
      formState.patient_consent.accept_treatment == true
    ) {
      let acceptform_html = getAcceptFormHtmlString(prf_Data);
      let res1 = await _http.doPost_NoCallback('main/send_email', {
        to: 'prf@legacyems.co.za',
        orientation: 'portrait',
        subject: 'Please find attached from Legacy Emergency Medical Services',
        pdf_name:
          'Patient-Agreement-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: acceptform_html
      });
      if (res1 != null) {
        console.log('reply', res1.data);
      } else {
        setLoading(false);
        alert('Error occured');
        return;
      }
    } // email sending of refusal payment form
    else {
      let refuseform_html = getRefuseFormHtmlString(prf_Data);
      let res2 = await _http.doPost_NoCallback('main/send_email', {
        to: 'prf@legacyems.co.za',
        orientation: 'portrait',
        subject: 'Please find attached from Legacy Emergency Medical Services',
        pdf_name:
          'Refusal-Treatment-' +
          prf_Data.id +
          '-' +
          prf_Data.patient_details.first_name +
          '-' +
          prf_Data.patient_details.last_name +
          '-' +
          prf_Data.patient_details.id_no +
          '-' +
          prf_Data.medical_aid.medical_aid_name +
          '.pdf',
        html_str: refuseform_html
      });

      if (res2 != null) {
        console.log('reply', res2.data);
      } else {
        setLoading(false);
        alert('Error occured');
        return;
      }
    }

    let res3 = await _http.doPost_NoCallback('main/delete_draft_prf', {
      user_id: registered_user.id
    });
    if (res3 != null) {
      alert('success');
      document.location.reload();
    } else {
      alert('Error occured');
    }
    setLoading(false);
  };

  const doLogCall = () => {
    if (verifyTangoKM() == false) {
      return;
    }

    let witness_sign_img = '';
    if (
      witness_signature_canvas_item != null &&
      witness_signature_canvas_item.current != null
    ) {
      witness_sign_img = witness_signature_canvas_item.current.toDataURL();
    }

    setLoading(true);
    _http.doPost(
      'main/creat_call_prf',
      {
        user_id: registered_user.id,
        tangos: formState.tangos,
        call_information: formState.call_information,
        patient_details: formState.patient_details,
        medical_aid: formState.medical_aid,
        crew: crew_members,
        raf_details: formState.raf_details,
        patient_history: formState.patient_history,
        chronic_conditions: formState.chronic_conditions,
        haemorrhage: formState.haemorrhage,
        airway: formState.airway,
        breathing: formState.breathing,
        circulation: formState.circulation,
        immobilisation: formState.immobilisation,
        mornitoring: formState.mornitoring,
        iv_access1: formState.iv_access1,
        iv_access2: formState.iv_access2,
        medication_slots: formState.medication_slots,
        vital_1: formState.vital_1,
        vital_2: formState.vital_2,
        vital_3: formState.vital_3,
        vital_4: formState.vital_4,
        vital_5: formState.vital_5,
        vital_6: formState.vital_6,
        patient_consent: formState.patient_consent,
        signature: {
          patient: patient_signature,
          hospital: hospital_signature,
          witness: witness_sign_img
        },
        license_img: {
          driver: driver_photo,
          hospital: hospital_photo
        }
      },
      data => {
        if (data.status == false) {
          setLoading(false);
          alert(data.data);
        } else {
          doJobAfterCreateCall(data.data);
        }
      },
      err => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const get_draft = () => {
    setPageLoad(false);
    _http.doPost(
      'main/get_draft_prf',
      {
        user_id: registered_user.id
      },
      data => {
        if (data.status == false) {
          setPageLoad(true);
          alert(data.data);
        } else {
          setPageLoad(true);
          if (data.data != null) {
            setFormState({
              tangos: data.data.tangos,
              call_information: data.data.call_information,
              patient_details: data.data.patient_details,
              medical_aid: data.data.medical_aid,
              crew: data.data.crew,
              raf_details: data.data.raf_details,
              patient_history: data.data.patient_history,
              chronic_conditions: data.data.chronic_conditions,
              haemorrhage: data.data.haemorrhage,
              airway: data.data.airway,
              breathing: data.data.breathing,
              circulation: data.data.circulation,
              immobilisation: data.data.immobilisation,
              mornitoring: data.data.mornitoring,
              iv_access1: data.data.iv_access1,
              iv_access2: data.data.iv_access2,
              medication_slots: data.data.medication_slots,
              vital_1: data.data.vital_1,
              vital_2: data.data.vital_2,
              vital_3: data.data.vital_3,
              vital_4: data.data.vital_4,
              vital_5: data.data.vital_5,
              vital_6: data.data.vital_6,
              patient_consent: data.data.patient_consent
            });

            setPatientSignature(data.data.signature.patient);
            setHospitalSignature(data.data.signature.hospital);
            setDriverPhoto(data.data.license_img.driver);
            setHospitalPhoto(data.data.license_img.hospital);
          }
        }
      },
      err => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const doSaveDraft = () => {
    setDraftLoading(true);
    _http.doPost(
      'main/save_draft_prf',
      {
        user_id: registered_user.id,
        tangos: formState.tangos,
        call_information: formState.call_information,
        patient_details: formState.patient_details,
        medical_aid: formState.medical_aid,
        crew: crew_members,
        raf_details: formState.raf_details,
        patient_history: formState.patient_history,
        chronic_conditions: formState.chronic_conditions,
        haemorrhage: formState.haemorrhage,
        airway: formState.airway,
        breathing: formState.breathing,
        circulation: formState.circulation,
        immobilisation: formState.immobilisation,
        mornitoring: formState.mornitoring,
        iv_access1: formState.iv_access1,
        iv_access2: formState.iv_access2,
        medication_slots: formState.medication_slots,
        vital_1: formState.vital_1,
        vital_2: formState.vital_2,
        vital_3: formState.vital_3,
        vital_4: formState.vital_4,
        vital_5: formState.vital_5,
        vital_6: formState.vital_6,
        patient_consent: formState.patient_consent,
        signature: {
          patient: patient_signature,
          hospital: hospital_signature
        },
        license_img: {
          driver: driver_photo,
          hospital: hospital_photo
        }
      },
      data => {
        setDraftLoading(false);
        if (data.status == false) {
          alert(data.data);
        } else {
          alert(data.data);
        }
      },
      err => {
        setDraftLoading(true);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const deleteDraft = () => {
    setDeleteDraftLoading(true);
    _http.doPost(
      'main/delete_draft_prf',
      {
        user_id: registered_user.id
      },
      data => {
        setDeleteDraftLoading(false);
        if (data.status == false) {
          alert(data.data);
        } else {
          alert(data.data);
          document.location.reload();
        }
      },
      err => {
        setDeleteDraftLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const handleChange = (event, group_name) => {
    event.persist();

    if (group_name == 'tangos') {
      setFormState(formState => ({
        ...formState,
        tangos: {
          ...formState.tangos,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'call_information') {
      setFormState(formState => ({
        ...formState,
        call_information: {
          ...formState.call_information,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'patient_details') {
      setFormState(formState => ({
        ...formState,
        patient_details: {
          ...formState.patient_details,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'medical_aid') {
      setFormState(formState => ({
        ...formState,
        medical_aid: {
          ...formState.medical_aid,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'crew') {
      setFormState(formState => ({
        ...formState,
        crew: {
          ...formState.crew,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'raf_details') {
      setFormState(formState => ({
        ...formState,
        raf_details: {
          ...formState.raf_details,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'patient_history') {
      setFormState(formState => ({
        ...formState,
        patient_history: {
          ...formState.patient_history,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'chronic_conditions') {
      setFormState(formState => ({
        ...formState,
        chronic_conditions: {
          ...formState.chronic_conditions,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'haemorrhage') {
      setFormState(formState => ({
        ...formState,
        haemorrhage: {
          ...formState.haemorrhage,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'airway') {
      setFormState(formState => ({
        ...formState,
        airway: {
          ...formState.airway,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'breathing') {
      setFormState(formState => ({
        ...formState,
        breathing: {
          ...formState.breathing,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'circulation') {
      setFormState(formState => ({
        ...formState,
        circulation: {
          ...formState.circulation,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'immobilisation') {
      setFormState(formState => ({
        ...formState,
        immobilisation: {
          ...formState.immobilisation,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'mornitoring') {
      setFormState(formState => ({
        ...formState,
        mornitoring: {
          ...formState.mornitoring,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'iv_access1') {
      setFormState(formState => ({
        ...formState,
        iv_access1: {
          ...formState.iv_access1,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'iv_access2') {
      setFormState(formState => ({
        ...formState,
        iv_access2: {
          ...formState.iv_access2,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'medication_slots') {
      setFormState(formState => ({
        ...formState,
        medication_slots: {
          ...formState.medication_slots,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_1') {
      setFormState(formState => ({
        ...formState,
        vital_1: {
          ...formState.vital_1,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_2') {
      setFormState(formState => ({
        ...formState,
        vital_2: {
          ...formState.vital_2,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_3') {
      setFormState(formState => ({
        ...formState,
        vital_3: {
          ...formState.vital_3,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_4') {
      setFormState(formState => ({
        ...formState,
        vital_4: {
          ...formState.vital_4,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_5') {
      setFormState(formState => ({
        ...formState,
        vital_5: {
          ...formState.vital_5,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'vital_6') {
      setFormState(formState => ({
        ...formState,
        vital_6: {
          ...formState.vital_6,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    } else if (group_name == 'patient_consent') {
      setFormState(formState => ({
        ...formState,
        patient_consent: {
          ...formState.patient_consent,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    }
  };

  const _handleImageChange = (e, flag) => {
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        // setAvatarForm(file);
        if (flag == true) {
          setDriverPhoto(reader.result);
        } else {
          setHospitalPhoto(reader.result);
        }
      };

      reader.readAsDataURL(file);
    } catch (err) {
      console.log('handle image error');
    }
  };

  const onAddSignature_patient = () => {
    let sign_img = signature_canvas_item.current.toDataURL();
    setPatientSignature(sign_img);
  };

  const onAddSignature_hospital = () => {
    let sign_img = signature_canvas_item.current.toDataURL();
    setHospitalSignature(sign_img);
  };

  const onClearSignature = () => {
    signature_canvas_item.current.clear();
  };

  const [crop_flag, setCropFlag] = useState(false);
  const [cropper, setCropper] = useState('');
  const [isCropDialog, showCropDialog] = useState(false);

  const getCropData = flag => {
    if (cropper != null) {
      if (flag == true) {
        setDriverPhoto(cropper.getCroppedCanvas().toDataURL());
      } else {
        setHospitalPhoto(cropper.getCroppedCanvas().toDataURL());
      }
    }
  };

  const medicationSlots = [1, 2, 3, 4, 5, 6, 7, 8].map(i => (
    <Grid
      container
      item
      key={i}
      lg={12}
      md={12}
      sm={12}
      spacing={2}
      style={{ paddingTop: 20 }}>
      <div style={{ fontSize: '16', fontWeight: 'bold', padding: 15 }}>
        Medication Slot {i}
      </div>
      <Grid
        item
        lg={12}
        md={12}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        xs={12}>
        <TextField
          className={classes.textField}
          fullWidth
          inputProps={{ autoCapitalize: 'sentences' }}
          label="Medication Type"
          name={'type_' + i}
          onChange={e => handleChange(e, 'medication_slots')}
          size="medium"
          style={{ textTransform: 'capitalize' }}
          type="text"
          value={formState.medication_slots['type_' + i] || ''}
          variant="outlined"
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        xs={12}>
        <TextField
          className={classes.textField}
          fullWidth
          inputProps={{ autoCapitalize: 'sentences' }}
          label="Medication Dosage"
          name={'dosage_' + i}
          onChange={e => handleChange(e, 'medication_slots')}
          size="medium"
          type="text"
          value={formState.medication_slots['dosage_' + i] || ''}
          variant="outlined"
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        xs={12}>
        <TextField
          className={classes.textField}
          fullWidth
          inputProps={{ autoCapitalize: 'sentences' }}
          label="Time (hh:mm)"
          name={'time_' + i}
          onChange={e => handleChange(e, 'medication_slots')}
          size="medium"
          type="text"
          value={formState.medication_slots['time_' + i] || ''}
          variant="outlined"
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Route</InputLabel>
          <Select
            inputProps={{
              name: 'route_' + i,
              id: 'outlined-age-native-simple'
            }}
            label="Route"
            native
            onChange={e => handleChange(e, 'medication_slots')}
            value={formState.medication_slots['route_' + i]}>
            <option aria-label="None" value="" />
            {[
              'P.O',
              'IVI',
              'IMI',
              'ETT',
              'SUPP',
              'S/C',
              'NASAL',
              'I.O',
              'NEB',
              'P.V'
            ].map(type => (
              <option value={type}>{type}</option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  ));

  return pageLoaded == false ? (
    <PageLoading />
  ) : (
    <div className={classes.root}>
      <Grid container style={{ justifyContent: 'center', width: '100%' }}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          spacing={2}
          style={{ paddingTop: 20 }}>
          <Grid
            item
            lg={2}
            md={2}
            style={{ padding: 10, paddingRight: 15 }}
            xs={12}>
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 1 Km"
              name="tango_1_km"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              type="number"
              value={formState.tangos.tango_1_km || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 1 Time (hh:mm)"
              name="tango_1_time"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              style={{ marginTop: 10 }}
              type="text"
              value={formState.tangos.tango_1_time || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            style={{ padding: 10, paddingRight: 15 }}
            xs={12}>
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 2 Km"
              name="tango_2_km"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              type="number"
              value={formState.tangos.tango_2_km || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 2 Time (hh:mm)"
              name="tango_2_time"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              style={{ marginTop: 10 }}
              type="text"
              value={formState.tangos.tango_2_time || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            style={{ padding: 10, paddingRight: 15 }}
            xs={12}>
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 3 Km"
              name="tango_3_km"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              type="number"
              value={formState.tangos.tango_3_km || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 3 Time (hh:mm)"
              name="tango_3_time"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              style={{ marginTop: 10 }}
              type="text"
              value={formState.tangos.tango_3_time || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            style={{ padding: 10, paddingRight: 15 }}
            xs={12}>
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 4 Km"
              name="tango_4_km"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              type="number"
              value={formState.tangos.tango_4_km || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 4 Time (hh:mm)"
              name="tango_4_time"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              style={{ marginTop: 10 }}
              type="text"
              value={formState.tangos.tango_4_time || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            style={{ padding: 10, paddingRight: 15 }}
            xs={12}>
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 5 Km"
              name="tango_5_km"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              type="number"
              value={formState.tangos.tango_5_km || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              inputProps={{ autoCapitalize: 'sentences' }}
              label="Tango 5 Time (hh:mm)"
              name="tango_5_time"
              onChange={e => handleChange(e, 'tangos')}
              size="medium"
              style={{ marginTop: 10 }}
              type="text"
              value={formState.tangos.tango_5_time || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input call information"
              title={<h4 style={{ fontWeight: 'bold' }}>Call information</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Reference No"
                    name="reference_no"
                    onChange={e => handleChange(e, 'call_information')}
                    size="medium"
                    type="text"
                    value={formState.call_information.reference_no || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Call Type
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'call_type',
                        id: 'outlined-age-native-simple'
                      }}
                      label="Call Type"
                      native
                      onChange={e => handleChange(e, 'call_information')}
                      value={formState.call_information.call_type}>
                      <option aria-label="None" value="" />
                      {['Primary', 'Transfer'].map(type => (
                        <option value={type}>{type}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Vehicle
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'vehicle',
                        id: 'outlined-age-native-simple'
                      }}
                      label="Vehicle"
                      native
                      onChange={e => handleChange(e, 'call_information')}
                      value={formState.call_information.vehicle}>
                      <option aria-label="None" value="" />
                      {vehicles.map((vehicle, index) => (
                        <option
                          key={index}
                          value={vehicle.id + '_@@==@@_' + vehicle.name}>
                          {vehicle.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Level Of Care
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'level_of_care',
                        id: 'outlined-age-native-simple'
                      }}
                      label="Level Of Care"
                      native
                      onChange={e => handleChange(e, 'call_information')}
                      value={formState.call_information.level_of_care}>
                      <option aria-label="None" value="" />
                      {['BLS', 'ILS', 'ALS'].map(type => (
                        <option value={type}>{type}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Priority
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'priority',
                        id: 'outlined-age-native-simple'
                      }}
                      label="Priority"
                      native
                      onChange={e => handleChange(e, 'call_information')}
                      value={formState.call_information.priority}>
                      <option aria-label="None" value="" />
                      {[1, 2, 3, 4].map(type => (
                        <option value={type}>{type}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Transport From"
                    name="transport_from"
                    onChange={e => handleChange(e, 'call_information')}
                    size="medium"
                    type="text"
                    value={formState.call_information.transport_from || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Transport To"
                    name="transport_to"
                    onChange={e => handleChange(e, 'call_information')}
                    size="medium"
                    type="text"
                    value={formState.call_information.transport_to || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Referring Dr/Ward"
                    name="referring_dr_ward"
                    onChange={e => handleChange(e, 'call_information')}
                    size="medium"
                    type="text"
                    value={formState.call_information.referring_dr_ward || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Receiving Person/Ward"
                    name="receiving_dr_ward"
                    onChange={e => handleChange(e, 'call_information')}
                    size="medium"
                    type="text"
                    value={formState.call_information.receiving_dr_ward || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input patient details"
              title={<h4 style={{ fontWeight: 'bold' }}>Patient Details</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={20}
                md={20}
                sm={20}
                spacing={5}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="First Name"
                    name="first_name"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.first_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Middle Name"
                    name="middle_name"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.middle_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Last Name"
                    name="last_name"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.last_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Gender"
                    name="gender"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.gender || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="ID No"
                    name="id_no"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.id_no || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Date of Birth"
                    name="date_of_birth"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.date_of_birth || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Mobile Number"
                    name="mobile_number"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.mobile_number || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Email Address"
                    name="email_address"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.email_address || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Home Address"
                    name="home_address"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.home_address || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Postal Address"
                    name="postal_address"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.postal_address || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Next Of Kin Name & Surname"
                    name="next_of_kin_name"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.next_of_kin_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Next Of Kin Contact No"
                    name="next_of_kin_contact_no"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={
                      formState.patient_details.next_of_kin_contact_no || ''
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Employed By"
                    name="employed_by"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.employed_by || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="IOD No"
                    name="iod_no"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.iod_no || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Employer Contact No"
                    name="employer_contact_no"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.employer_contact_no || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Name and Surname of Person Responsible for Payment"
                    name="first_name_responsible"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.first_name_responsible || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="ID number of Person Responsible for Payment"
                    name="id_responsible"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.id_responsible || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Name and Surname of Practitioner Completing the PRF"
                    name="id_practitioner"
                    onChange={e => handleChange(e, 'patient_details')}
                    size="medium"
                    type="text"
                    value={formState.patient_details.id_practitioner || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input Driver / Hospital License Image"
              title={
                <h4 style={{ fontWeight: 'bold' }}>
                  Driver / Hospital License Image Scan
                </h4>
              }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid item lg={6} style={{ padding: 20 }} xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Driver License
                    </span>
                    <input
                      accept="image/*"
                      hidden
                      onChange={e => _handleImageChange(e, true)}
                      ref={driver_photo_file_input}
                      type="file"
                    />
                    <IconButton onClick={() => showDriverCamDialog(true)}>
                      <PhotoCamera />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (driver_photo_file_input != null) {
                          driver_photo_file_input.current.click();
                        }
                      }}></IconButton>
                    <IconButton
                      onClick={() => {
                        setCropFlag(true);
                        showCropDialog(true);
                      }}>
                      <Crop />
                    </IconButton>
                    <IconButton onClick={() => setDriverPhoto('')}>
                      <Close />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderWidth: 1,
                      borderColor: '#000',
                      borderStyle: 'solid',
                      borderRadius: 5
                    }}>
                    {driver_photo == null || driver_photo == '' ? null : (
                      <img
                        src={driver_photo}
                        style={{
                          height: '250px',
                          width: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item lg={6} md={6} style={{ padding: 20 }} xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Hospital Sticker
                    </span>
                    <input
                      accept="image/*"
                      hidden
                      onChange={e => _handleImageChange(e, false)}
                      ref={hospital_photo_file_input}
                      type="file"
                    />
                    <IconButton onClick={() => showHospitalCamDialog(true)}>
                      <PhotoCamera />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (hospital_photo_file_input != null) {
                          hospital_photo_file_input.current.click();
                        }
                      }}></IconButton>
                    <IconButton
                      onClick={() => {
                        setCropFlag(false);
                        showCropDialog(true);
                      }}>
                      <Crop />
                    </IconButton>
                    <IconButton onClick={() => setHospitalPhoto('')}>
                      <Close />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderWidth: 1,
                      borderColor: '#000',
                      borderStyle: 'solid',
                      borderRadius: 5
                    }}>
                    {hospital_photo == null || hospital_photo == '' ? null : (
                      <img
                        src={hospital_photo}
                        style={{
                          height: '250px',
                          width: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input medical aid"
              title={<h4 style={{ fontWeight: 'bold' }}>Medical Aid</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Medical Aid Name"
                    name="medical_aid_name"
                    onChange={e => handleChange(e, 'medical_aid')}
                    size="medium"
                    type="text"
                    value={formState.medical_aid.medical_aid_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Medical Aid No"
                    name="medical_aid_no"
                    onChange={e => handleChange(e, 'medical_aid')}
                    size="medium"
                    type="text"
                    value={formState.medical_aid.medical_aid_no || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Dependant Code"
                    name="dependant_code"
                    onChange={e => handleChange(e, 'medical_aid')}
                    size="medium"
                    type="text"
                    value={formState.medical_aid.dependant_code || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Main Member"
                    name="main_member"
                    onChange={e => handleChange(e, 'medical_aid')}
                    size="medium"
                    type="text"
                    value={formState.medical_aid.main_member || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input crew members"
              title={<h4 style={{ fontWeight: 'bold' }}>Crew</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={8}
                  md={8}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={11}>
                  <Autocomplete
                    disableCloseOnSelect
                    fullWidth
                    getOptionLabel={option => option.name}
                    id="checkboxes-tags-demo"
                    multiple
                    onChange={(e, val) => setCrewMembers(val)}
                    options={users}
                    renderInput={params => (
                      <TextField
                        inputProps={{ autoCapitalize: 'sentences' }}
                        {...params}
                        fullWidth
                        label="Crew members"
                        placeholder="crew members"
                        variant="outlined"
                      />
                    )}
                    // style={{ width: 500 }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          checked={selected}
                          checkedIcon={checkedIcon}
                          icon={icon}
                          style={{ marginRight: 8 }}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    value={crew_members}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={1}>
                  <IconButton onClick={() => get_all_users()}>
                    <Refresh />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              title={<h4 style={{ fontWeight: 'bold' }}>RAF Details</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="vehicle 1" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Make"
                          name="vehicle1_make"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={formState.raf_details.vehicle1_make || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Registration"
                          name="vehicle1_registration"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={
                            formState.raf_details.vehicle1_registration || ''
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Driver Name"
                          name="vehicle1_driver_name"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={
                            formState.raf_details.vehicle1_driver_name || ''
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="vehicle 2" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Make"
                          name="vehicle2_make"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={formState.raf_details.vehicle2_make || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Registration"
                          name="vehicle2_registration"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={
                            formState.raf_details.vehicle2_registration || ''
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Driver Name"
                          name="vehicle2_driver_name"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={
                            formState.raf_details.vehicle2_driver_name || ''
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="SAPS Official on Scene / Reg No"
                          name="vehicle2_saps"
                          onChange={e => handleChange(e, 'raf_details')}
                          size="medium"
                          type="text"
                          value={formState.raf_details.vehicle2_saps || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input Patient History"
              title={<h4 style={{ fontWeight: 'bold' }}>Patient History</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Allergies"
                    name="allergies"
                    onChange={e => handleChange(e, 'patient_history')}
                    size="medium"
                    type="text"
                    value={formState.patient_history.allergies || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Chronic Medication"
                    name="chronic_medication"
                    onChange={e => handleChange(e, 'patient_history')}
                    size="medium"
                    type="text"
                    value={formState.patient_history.chronic_medication || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Chief Complaint"
                    name="chief_complaint"
                    onChange={e => handleChange(e, 'patient_history')}
                    size="medium"
                    type="text"
                    value={formState.patient_history.chief_complaint || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Examinations / Observations"
                    multiline
                    name="exam_objservation"
                    onChange={e => handleChange(e, 'patient_history')}
                    rowsMax={5}
                    size="medium"
                    type="text"
                    value={formState.patient_history.exam_objservation || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input Chronic Conditions"
              title={<h4 style={{ fontWeight: 'bold' }}>Chronic Conditions</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.asthma}
                        name="asthma"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Asthma"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.diabetes}
                        name="diabetes"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Diabetes"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.cardiac}
                        name="cardiac"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Cardiac"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.gastrointestinal}
                        name="gastrointestinal"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Gastrointestinal"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.nervous}
                        name="nervous"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Nervous System"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.blood_pressure}
                        name="blood_pressure"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Hypertension"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.renal}
                        name="renal"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Renal"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.chronic_conditions.hepatic}
                        name="hepatic"
                        onChange={e => handleChange(e, 'chronic_conditions')}
                      />
                    }
                    label="Hepatic"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Other"
                    multiline
                    name="other"
                    onChange={e => handleChange(e, 'chronic_conditions')}
                    rowsMax={5}
                    size="medium"
                    type="text"
                    value={formState.chronic_conditions.other || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="Diagnosis"
                    multiline
                    name="diagnosis"
                    onChange={e => handleChange(e, 'chronic_conditions')}
                    rowsMax={5}
                    size="medium"
                    type="text"
                    value={formState.chronic_conditions.diagnosis || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    inputProps={{ autoCapitalize: 'sentences' }}
                    label="MOI"
                    multiline
                    name="moi"
                    onChange={e => handleChange(e, 'chronic_conditions')}
                    rowsMax={5}
                    size="medium"
                    type="text"
                    value={formState.chronic_conditions.moi || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              title={<h4 style={{ fontWeight: 'bold' }}>Interventions</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Haemorrhage" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'type',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Type"
                            native
                            onChange={e => handleChange(e, 'haemorrhage')}
                            value={formState.haemorrhage.type}>
                            <option aria-label="None" value="" />
                            {['internal', 'external'].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formState.haemorrhage.type == '' ||
                      formState.haemorrhage.type == null ? null : (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.haemorrhage.head}
                                name="head"
                                onChange={e => handleChange(e, 'haemorrhage')}
                              />
                            }
                            label="Head"
                          />
                        </Grid>
                      )}
                      {formState.haemorrhage.type == '' ||
                      formState.haemorrhage.type == null ? null : (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.haemorrhage.chest}
                                name="chest"
                                onChange={e => handleChange(e, 'haemorrhage')}
                              />
                            }
                            label="Chest"
                          />
                        </Grid>
                      )}
                      {formState.haemorrhage.type == '' ||
                      formState.haemorrhage.type == null ? null : (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.haemorrhage.abdomen}
                                name="abdomen"
                                onChange={e => handleChange(e, 'haemorrhage')}
                              />
                            }
                            label="Abdomen"
                          />
                        </Grid>
                      )}
                      {formState.haemorrhage.type == '' ||
                      formState.haemorrhage.type == null ? null : (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.haemorrhage.pelvis}
                                name="pelvis"
                                onChange={e => handleChange(e, 'haemorrhage')}
                              />
                            }
                            label="Pelvis"
                          />
                        </Grid>
                      )}
                      {formState.haemorrhage.type == '' ||
                      formState.haemorrhage.type == null ||
                      formState.haemorrhage.type == 'internal' ? null : (
                        <Grid
                          item
                          lg={2}
                          md={2}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState.haemorrhage.extremities}
                                name="extremities"
                                onChange={e => handleChange(e, 'haemorrhage')}
                              />
                            }
                            label="Extremities"
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          inputProps={{ autoCapitalize: 'sentences' }}
                          label="Volume (ml)"
                          name="volume"
                          onChange={e => handleChange(e, 'haemorrhage')}
                          size="medium"
                          type="text"
                          value={formState.haemorrhage.volume || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            margin: 10
                          }}>
                          Treatment
                        </div>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Direct / Indirect pressure
                            </InputLabel>
                            <Select
                              inputProps={{
                                name: 'pressure',
                                id: 'outlined-age-native-simple'
                              }}
                              label="Direct / Indirect pressure"
                              native
                              onChange={e => handleChange(e, 'haemorrhage')}
                              value={formState.haemorrhage.pressure}>
                              <option aria-label="None" value="" />
                              {[
                                'Direct pressure',
                                'Indirect pressure',
                                'Tourniquet'
                              ].map(type => (
                                <option value={type}>{type}</option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Dressing / None
                            </InputLabel>
                            <Select
                              inputProps={{
                                name: 'dressing',
                                id: 'outlined-age-native-simple'
                              }}
                              label="Dressing / None"
                              native
                              onChange={e => handleChange(e, 'haemorrhage')}
                              value={formState.haemorrhage.dressing}>
                              <option aria-label="None" value="" />
                              {['Dressing', 'None'].map(dressing => (
                                <option value={dressing}>{dressing}</option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Airway" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'type',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Type"
                            native
                            onChange={e => handleChange(e, 'airway')}
                            value={formState.airway.type}>
                            <option aria-label="None" value="" />
                            {['Open / Patent', 'Compromised'].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Treatment
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'treatment',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Treatment"
                            native
                            onChange={e => handleChange(e, 'airway')}
                            value={formState.airway.treatment}>
                            <option aria-label="None" value="" />
                            {[
                              'Lateral',
                              'Head Tilt Chin Lift',
                              'Jaw Thrust',
                              'OP Airway',
                              'LMA',
                              'ETT',
                              'Needle CRICO',
                              'Surgical',
                              'CRICO'
                            ].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formState.airway.treatment == 'OP Airway' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_000}
                                  name="op_airway_000"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="000"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_00}
                                  name="op_airway_00"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="00"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_0}
                                  name="op_airway_0"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_1}
                                  name="op_airway_1"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="1"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_2}
                                  name="op_airway_2"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="2"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_3}
                                  name="op_airway_3"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="3"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_4}
                                  name="op_airway_4"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="4"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.op_airway_5}
                                  name="op_airway_5"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="5"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.airway.treatment == 'LMA' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.lma_1}
                                  name="lma_1"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="1"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.lma_2}
                                  name="lma_2"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="2"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.lma_3}
                                  name="lma_3"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="3"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.lma_4}
                                  name="lma_4"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="4"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.lma_5}
                                  name="lma_5"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="5"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.airway.treatment == 'ETT' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_10}
                                  name="ett_10"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="1.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_15}
                                  name="ett_15"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="1.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_20}
                                  name="ett_20"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="2.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_25}
                                  name="ett_25"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="2.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_30}
                                  name="ett_30"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="3.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_35}
                                  name="ett_35"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="3.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_40}
                                  name="ett_40"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="4.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_45}
                                  name="ett_45"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="4.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_50}
                                  name="ett_50"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="5.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_55}
                                  name="ett_55"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="5.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_60}
                                  name="ett_60"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="6.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_65}
                                  name="ett_65"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="6.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_70}
                                  name="ett_70"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="7.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_75}
                                  name="ett_75"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="7.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_80}
                                  name="ett_80"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="8.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_85}
                                  name="ett_85"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="8.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_90}
                                  name="ett_90"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="9.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_95}
                                  name="ett_95"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="9.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.ett_100}
                                  name="ett_100"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="10.0"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.airway.treatment == 'Surgical' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_10}
                                  name="surgical_10"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 1.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_15}
                                  name="surgical_15"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 1.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_20}
                                  name="surgical_20"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 2.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_25}
                                  name="surgical_25"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 2.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_30}
                                  name="surgical_30"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 3.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_35}
                                  name="surgical_35"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 3.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_40}
                                  name="surgical_40"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 4.0"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_45}
                                  name="surgical_45"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 4.5"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.airway.surgical_50}
                                  name="surgical_50"
                                  onChange={e => handleChange(e, 'airway')}
                                />
                              }
                              label="ETT 5.0"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Breathing / Ventillation" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'type',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Type"
                            native
                            onChange={e => handleChange(e, 'breathing')}
                            value={formState.breathing.type}>
                            <option aria-label="None" value="" />
                            {[
                              'Breathing Normal',
                              'Not Breathing',
                              'Breathing Difficulty'
                            ].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {formState.breathing.type == 'Breathing Difficulty' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.difficulty_copd}
                                  name="difficulty_copd"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="COPD"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.difficulty_asthman
                                  }
                                  name="difficulty_asthman"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Asthma"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing
                                      .difficulty_pulmonary_edema
                                  }
                                  name="difficulty_pulmonary_edema"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Pulmonary Edema"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.difficulty_allegy
                                  }
                                  name="difficulty_allegy"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Allergy"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              className={classes.textField}
                              fullWidth
                              inputProps={{ autoCapitalize: 'sentences' }}
                              label="Other"
                              multiline
                              name="difficulty_other"
                              onChange={e => handleChange(e, 'breathing')}
                              rowsMax={5}
                              size="medium"
                              type="text"
                              value={formState.breathing.difficulty_other || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Treatment
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'treatment',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Treatment"
                            native
                            onChange={e => handleChange(e, 'breathing')}
                            value={formState.breathing.treatment}>
                            <option aria-label="None" value="" />
                            {['No Treatment', 'Oxygen'].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formState.breathing.treatment == 'Oxygen' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.oxygen_24_nasal}
                                  name="oxygen_24_nasal"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="24% Nasal"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.oxygen_40_venturi
                                  }
                                  name="oxygen_40_venturi"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="40% Venturi"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.oxygen_60_rebreather
                                  }
                                  name="oxygen_60_rebreather"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="60% Rebreather"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.oxygen_100_bvm}
                                  name="oxygen_100_bvm"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="100% BVM"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.oxygen_simple_face_mask
                                  }
                                  name="oxygen_simple_face_mask"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Simple Face Mask"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.breathing.oxygen_non_rebreather
                                  }
                                  name="oxygen_non_rebreather"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Non Rebreather"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.oxygen_tpiece}
                                  name="oxygen_tpiece"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="T-Piece"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.oxygen_nebuliser}
                                  name="oxygen_nebuliser"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="Nebuliser"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.breathing.oxygen_cpap}
                                  name="oxygen_cpap"
                                  onChange={e => handleChange(e, 'breathing')}
                                />
                              }
                              label="CPAP"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Circulation" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Pulse
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'pulse',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Pulse"
                            native
                            onChange={e => handleChange(e, 'circulation')}
                            value={formState.circulation.pulse}>
                            <option aria-label="None" value="" />
                            {['Strong', 'Weak', 'No Pulse', 'Dysrhythmia'].map(
                              type => (
                                <option value={type}>{type}</option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Treatment
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'treatment',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Treatment"
                            native
                            onChange={e => handleChange(e, 'circulation')}
                            value={formState.circulation.treatment}>
                            <option aria-label="None" value="" />
                            {['CPR', 'None', 'Anti-Arrythmia Medication'].map(
                              type => (
                                <option value={type}>{type}</option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Immobilisation & Extrication" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.spinal}
                              name="spinal"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="Spinal Immobilization"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.stretcher}
                              name="stretcher"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="Stretcher"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.straps}
                              name="straps"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="Straps"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.blanket}
                              name="blanket"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="Blanket"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.ked}
                              name="ked"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="K.E.D"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.immobilisation.rescue}
                              name="rescue"
                              onChange={e => handleChange(e, 'immobilisation')}
                            />
                          }
                          label="Rescue"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Monitoring" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.ecg}
                              name="ecg"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="ECG"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.nibp}
                              name="nibp"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="NIBP"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.pulseox}
                              name="pulseox"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="PULSEOX"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.hgt}
                              name="hgt"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="HGT"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.hb}
                              name="hb"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="Hb (g/dl)"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.doppler}
                              name="doppler"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="Doppler"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.syr_drive}
                              name="syr_drive"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="Syr.Drive"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formState.mornitoring.ventilator}
                              name="ventilator"
                              onChange={e => handleChange(e, 'mornitoring')}
                            />
                          }
                          label="Ventilator"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="IV Access 1" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'type',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Type"
                            native
                            onChange={e => handleChange(e, 'iv_access1')}
                            value={formState.iv_access1.type}>
                            <option aria-label="None" value="" />
                            {[
                              'Needle',
                              'Site',
                              'Admin Set',
                              'Fluid',
                              'Init Amount ml',
                              'Admin amount ml'
                            ].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formState.iv_access1.type == 'Needle' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_24g}
                                  name="needle_24g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="24g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_22g}
                                  name="needle_22g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="22g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_20g}
                                  name="needle_20g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="20g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_18g}
                                  name="needle_18g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="18g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_16g}
                                  name="needle_16g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="16g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.needle_14g}
                                  name="needle_14g"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="14g"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access1.type == 'Site' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_lacf}
                                  name="site_lacf"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="L ACF"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_racf}
                                  name="site_racf"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="R ACF"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_pedis}
                                  name="site_pedis"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="PEDIS"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_l_ext_jug}
                                  name="site_l_ext_jug"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="L EXT JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_r_ext_jug}
                                  name="site_r_ext_jug"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="R EXT JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_l_femor}
                                  name="site_l_femor"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="L FEMOR"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_r_femor}
                                  name="site_r_femor"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="R FEMOR"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_l_hand}
                                  name="site_l_hand"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="L HAND"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_r_hand}
                                  name="site_r_hand"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="R HAND"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_cvp}
                                  name="site_cvp"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="CVP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access1.site_external_jug
                                  }
                                  name="site_external_jug"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="EXTERNAL JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_fem_vein}
                                  name="site_fem_vein"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="FEM VEIN"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_l_forearm}
                                  name="site_l_forearm"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="L FOREARM"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.site_r_forearm}
                                  name="site_r_forearm"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="R FOREARM"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access1.type == 'Admin Set' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.adminset_10drp}
                                  name="adminset_10drp"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="10 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.adminset_15drp}
                                  name="adminset_15drp"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="15 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.adminset_20drp}
                                  name="adminset_20drp"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="20 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.adminset_60drp}
                                  name="adminset_60drp"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="60 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access1.adminset_burotrol
                                  }
                                  name="adminset_burotrol"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Burotrol"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access1
                                      .adminset_blood_infusion_set
                                  }
                                  name="adminset_blood_infusion_set"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Blood Infusion Set"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.high_cap_line}
                                  name="high_cap_line"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="High Cap Line"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access1.type == 'Fluid' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_nacl}
                                  name="fluid_nacl"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="NACL"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_ringers}
                                  name="fluid_ringers"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Ringers"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_balsol}
                                  name="fluid_balsol"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Balsol"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_5dext}
                                  name="fluid_5dext"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="5% Dext"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_susten}
                                  name="fluid_susten"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Susten"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_blood}
                                  name="fluid_blood"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Blood"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.fluid_colloid}
                                  name="fluid_colloid"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="Colloid"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              className={classes.textField}
                              fullWidth
                              inputProps={{ autoCapitalize: 'sentences' }}
                              label="Other"
                              multiline
                              name="fluid_other"
                              onChange={e => handleChange(e, 'iv_access1')}
                              rowsMax={5}
                              size="medium"
                              type="text"
                              value={formState.iv_access1.fluid_other || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access1.type == 'Init Amount ml' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access1.init_amount_1000
                                  }
                                  name="init_amount_1000"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="1000 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.init_amount_500}
                                  name="init_amount_500"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="500 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.init_amount_200}
                                  name="init_amount_200"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="200 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.init_amount_100}
                                  name="init_amount_100"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="100 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access1.init_amount_50}
                                  name="init_amount_50"
                                  onChange={e => handleChange(e, 'iv_access1')}
                                />
                              }
                              label="50 ml"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access1.type == 'Admin amount ml' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              className={classes.textField}
                              fullWidth
                              inputProps={{ autoCapitalize: 'sentences' }}
                              label="Amount (1 ~ 9000)"
                              multiline
                              name="admin_amount"
                              onChange={e => handleChange(e, 'iv_access1')}
                              rowsMax={5}
                              size="medium"
                              type="text"
                              value={formState.iv_access1.admin_amount || ''}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              className={classes.textField}
                              defaultValue="07:30"
                              id="time"
                              inputProps={{
                                step: 300 // 5 min
                              }}
                              label="Time"
                              name="admin_amount_time"
                              onChange={e => handleChange(e, 'iv_access1')}
                              style={{ width: 200 }}
                              type="time"
                              value={
                                formState.iv_access1.admin_amount_time || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 20 }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader title="IV Access 2" />
                  <CardContent>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        style={{ padding: 5, paddingRight: 8 }}
                        xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: 'type',
                              id: 'outlined-age-native-simple'
                            }}
                            label="Type"
                            native
                            onChange={e => handleChange(e, 'iv_access2')}
                            value={formState.iv_access2.type}>
                            <option aria-label="None" value="" />
                            {[
                              'Needle',
                              'Site',
                              'Admin Set',
                              'Fluid',
                              'Init Amount ml',
                              'Admin amount ml'
                            ].map(type => (
                              <option value={type}>{type}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formState.iv_access2.type == 'Needle' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_24g}
                                  name="needle_24g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="24g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_22g}
                                  name="needle_22g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="22g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_20g}
                                  name="needle_20g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="20g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_18g}
                                  name="needle_18g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="18g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_16g}
                                  name="needle_16g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="16g"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.needle_14g}
                                  name="needle_14g"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="14g"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access2.type == 'Site' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_lacf}
                                  name="site_lacf"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="L ACF"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_racf}
                                  name="site_racf"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="R ACF"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_pedis}
                                  name="site_pedis"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="PEDIS"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_l_ext_jug}
                                  name="site_l_ext_jug"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="L EXT JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_r_ext_jug}
                                  name="site_r_ext_jug"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="R EXT JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_l_femor}
                                  name="site_l_femor"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="L FEMOR"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_r_femor}
                                  name="site_r_femor"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="R FEMOR"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_l_hand}
                                  name="site_l_hand"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="L HAND"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_r_hand}
                                  name="site_r_hand"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="R HAND"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_cvp}
                                  name="site_cvp"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="CVP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access2.site_external_jug
                                  }
                                  name="site_external_jug"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="EXTERNAL JUG"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_fem_vein}
                                  name="site_fem_vein"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="FEM VEIN"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_l_forearm}
                                  name="site_l_forearm"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="L FOREARM"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.site_r_forearm}
                                  name="site_r_forearm"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="R FOREARM"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access2.type == 'Admin Set' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.adminset_10drp}
                                  name="adminset_10drp"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="10 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.adminset_15drp}
                                  name="adminset_15drp"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="15 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.adminset_20drp}
                                  name="adminset_20drp"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="20 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.adminset_60drp}
                                  name="adminset_60drp"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="60 DRP"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access2.adminset_burotrol
                                  }
                                  name="adminset_burotrol"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Burotrol"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access2
                                      .adminset_blood_infusion_set
                                  }
                                  name="adminset_blood_infusion_set"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Blood Infusion Set"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.high_cap_line}
                                  name="high_cap_line"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="High Cap Line"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access2.type == 'Fluid' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_nacl}
                                  name="fluid_nacl"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="NACL"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_ringers}
                                  name="fluid_ringers"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Ringers"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_balsol}
                                  name="fluid_balsol"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Balsol"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_5dext}
                                  name="fluid_5dext"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="5% Dext"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_susten}
                                  name="fluid_susten"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Susten"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_blood}
                                  name="fluid_blood"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Blood"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.fluid_colloid}
                                  name="fluid_colloid"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="Colloid"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              className={classes.textField}
                              fullWidth
                              inputProps={{ autoCapitalize: 'sentences' }}
                              label="Other"
                              multiline
                              name="fluid_other"
                              onChange={e => handleChange(e, 'iv_access2')}
                              rowsMax={5}
                              size="medium"
                              type="text"
                              value={formState.iv_access2.fluid_other || ''}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access2.type == 'Init Amount ml' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.iv_access2.init_amount_1000
                                  }
                                  name="init_amount_1000"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="1000 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.init_amount_500}
                                  name="init_amount_500"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="500 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.init_amount_200}
                                  name="init_amount_200"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="200 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.init_amount_100}
                                  name="init_amount_100"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="100 ml"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.iv_access2.init_amount_50}
                                  name="init_amount_50"
                                  onChange={e => handleChange(e, 'iv_access2')}
                                />
                              }
                              label="50 ml"
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      {formState.iv_access2.type == 'Admin amount ml' ? (
                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          style={{ padding: 5, paddingRight: 8 }}
                          xs={12}>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              className={classes.textField}
                              fullWidth
                              inputProps={{ autoCapitalize: 'sentences' }}
                              label="Amount (1 ~ 9000)"
                              multiline
                              name="admin_amount"
                              onChange={e => handleChange(e, 'iv_access2')}
                              rowsMax={5}
                              size="medium"
                              type="text"
                              value={formState.iv_access2.admin_amount || ''}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            style={{ padding: 5, paddingRight: 8 }}
                            xs={12}>
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              className={classes.textField}
                              defaultValue="07:30"
                              id="time"
                              inputProps={{
                                step: 300 // 5 min
                              }}
                              label="Time"
                              name="admin_amount_time"
                              onChange={e => handleChange(e, 'iv_access2')}
                              style={{ width: 200 }}
                              type="time"
                              value={
                                formState.iv_access2.admin_amount_time || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input medication slots"
              title={<h4 style={{ fontWeight: 'bold' }}>Medication Slots</h4>}
            />
            <Divider />
            <CardContent>
              <Grid container item lg={12} md={12} sm={12} spacing={2}>
                <AliceCarousel
                  autoPlayDirection="rtl"
                  fadeOutAnimation
                  items={medicationSlots}
                  onSlideChanged={e => {
                    console.log(e);
                    setStartIndex(e.slide);
                  }}
                  responsive={{ 0: { items: 1 }, 1024: { items: 1 } }}
                  // mouseTrackingEnabled={true}
                  // onSlideChange={this.onSlideChange}
                  startIndex={start_index}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input vitals"
              title={<h4 style={{ fontWeight: 'bold' }}>Vitals</h4>}
            />
            <Divider />
            <CardContent>
              {[1, 2, 3, 4, 5, 6].map(i => (
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMore />}
                    id="panel1a-header">
                    <h5 className={classes.heading}>vital {i}</h5>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      spacing={2}
                      style={{ paddingTop: 20 }}>
                      <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        spacing={2}
                        style={{ paddingTop: 20 }}>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Time (hh:mm:ss)"
                            name="time"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].time || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pulse (/min)"
                            name="pulse"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pulse || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Blood Pressure (mmHg)"
                            name="blood_pressure"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].blood_pressure || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="SpO2 (%)"
                            name="spo2"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].spo2 || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="O2 (l/min)"
                            name="o2"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].o2 || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Respiratory Rate (/min)"
                            name="respiratory_rate"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={
                              formState['vital_' + i].respiratory_rate || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Temperature (°C)"
                            name="temperature"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].temperature || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="HGT (mmol/L)"
                            name="hgt"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].hgt || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Hb (g/dl)"
                            name="hb"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].hb || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Eye (4)"
                            name="eye"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].eye || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Verbal (5)"
                            name="verbal"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].verbal || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Motor (6)"
                            name="motor"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].motor || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="GCS (/15)"
                            name="gcs"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].gcs || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pain Score (/10)"
                            name="pain_score"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pain_score || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pacing (mAH)"
                            name="pacing_mah"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pacing_mah || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pacing (Rate)"
                            name="pacing_rate"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pacing_rate || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Defib (Joules)"
                            name="defib"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].defib || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pupils (Left)"
                            name="pupils_left"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pupils_left || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Pupils (Right)"
                            name="pupils_right"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={formState['vital_' + i].pupils_right || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Ventilator (T-Volume)"
                            name="ventilator_tvolumn"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={
                              formState['vital_' + i].ventilator_tvolumn || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            inputProps={{ autoCapitalize: 'sentences' }}
                            label="Ventilator (Peep)"
                            name="ventilator_peep"
                            onChange={e => handleChange(e, 'vital_' + i)}
                            size="medium"
                            type="text"
                            value={
                              formState['vital_' + i].ventilator_peep || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        spacing={2}
                        style={{ paddingTop: 20 }}>
                        <div
                          style={{
                            width: '100%',
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingLeft: 20
                          }}>
                          E.C.G. Analysis
                        </div>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState['vital_' + i].ecg_sinus}
                                name="ecg_sinus"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Sinus Rhythm"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_tachycardia
                                }
                                name="ecg_tachycardia"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Sinus Tachycardia"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_bradycardia
                                }
                                name="ecg_bradycardia"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Sinus Bradycardia"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_ventricular_fibrillation
                                }
                                name="ecg_ventricular_fibrillation"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Ventricular Fibrillation"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_atrial_fibrillation
                                }
                                name="ecg_atrial_fibrillation"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Atrial Fibrillation"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_artiral_flutter
                                }
                                name="ecg_artiral_flutter"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Atrial Flutter"
                          />
                        </Grid>
                        {/* <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_ventricular_flutter
                                }
                                name="ecg_ventricular_flutter"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Ventricular Flutter"
                          />
                        </Grid> */}
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_bundle_branch_block
                                }
                                name="ecg_bundle_branch_block"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Bundle Branch Block"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_heart_block
                                }
                                name="ecg_heart_block"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="AV Block"
                          />
                        </Grid>
                        {/* <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_idioventricular_rhythm
                                }
                                name="ecg_idioventricular_rhythm"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Idioventricular Rhythm"
                          />
                        </Grid> */}
                        {/* <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_junc_escape_rhythm
                                }
                                name="ecg_junc_escape_rhythm"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Junc Escape Rhythm"
                          />
                        </Grid> */}
                     
                     
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_pm_failure_to_capture
                                }
                                name="ecg_pm_failure_to_capture"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="PM failure to capture"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_prem_ventricular_complex
                                }
                                name="ecg_prem_ventricular_complex"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Prem Ventricular Complex"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_sinus_arrest
                                }
                                name="ecg_sinus_arrest"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Sinus Arrest"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_sinus_arrhythmia
                                }
                                name="ecg_sinus_arrhythmia"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Sinus Arrhythmia"
                          />
                        </Grid>
                        {/* <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_ventricular_tachycardia
                                }
                                name="ecg_ventricular_tachycardia"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Ventricular Tachycardia"
                          />
                        </Grid> */}
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_v_tach_monomorphic
                                }
                                name="ecg_v_tach_monomorphic"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="V-Tach Monomorphic"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_v_tach_polymorphic
                                }
                                name="ecg_v_tach_polymorphic"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="V-Tach Polymorphic"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_torsade_de_pointes
                                }
                                name="ecg_torsade_de_pointes"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Torsade de Pointes"
                          />
                        </Grid>
                        {/* <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_wandering_atrial_pacemaker
                                }
                                name="ecg_wandering_atrial_pacemaker"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Wandering Atrial Pacemaker"
                          />
                        </Grid> */}
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formState['vital_' + i].ecg_asystole}
                                name="ecg_asystole"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="Asystole"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_st_elevation
                                }
                                name="ecg_st_elevation"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="ST Elevation"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_st_depression
                                }
                                name="ecg_st_depression"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="ST Depression"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i].ecg_pacemaker_rhythm
                                }
                                name="ecg_pacemaker_rhythm"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="PEA"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          style={{ padding: 10, paddingRight: 15 }}
                          xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formState['vital_' + i]
                                    .ecg_wolff_park_white_syndrome
                                }
                                name="ecg_wolff_park_white_syndrome"
                                onChange={e => handleChange(e, 'vital_' + i)}
                              />
                            }
                            label="OTHER"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              subheader="Input patient consent"
              title={<h4 style={{ fontWeight: 'bold' }}>Patient Consent</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                style={{ paddingTop: 15 }}>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ padding: 5 }}>
                  <Card style={{ width: '100%' }}>
                    <CardHeader
                      title={
                        <h4 style={{ fontWeight: 'bold' }}>
                          Patient Payment Agreement And Indemnity
                        </h4>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <table>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            1)
                          </td>
                          <td valign={'top'}>
                          1)	Hereby give Legacy Emergency Medical Services (Pty) Ltd with Registration No. 2016/121004/07, trading as Legacy Emergency Medical Services, my consent and permission to transport me to a hospital of my choice, and in the event that the afore-mentioned hospital is not available, any hospital Legacy Emergency Medical Services deems fit.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            2)
                          </td>
                          <td valign={'top'}>
                          2)	I further give Legacy Emergency Medical Services my consent and permission to stabilize me and/or give me life support and/or any other function or support if needed in the circumstances at hand and/or as requested by myself.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            3)
                          </td>
                          <td valign={'top'}>
                          3)	By making use of Legacy Emergency Medical Services, I accept that there are costs and fees involved for provided services. I hereby take full responsibility and liability for the payment of their fees and costs as set out in Annexure “A” below.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            4)
                          </td>
                          <td valign={'top'}>
                          4)	I admit that on rendering of the services, all accounts become immediately due and payable to Legacy Emergency Medical Services.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            5)
                          </td>
                          <td valign={'top'}>
                          5)	I agree to Legacy Emergency Medical Services submitting the invoice (even before I receive an account) on my behalf to my medical aid where applicable. I confirm that I will always be responsible and liable for payment of Legacy Emergency Medical Services account.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            6)
                          </td>
                          <td valign={'top'}>
                          6)	In the event that legal action commences regarding the non-payment of Legacy Emergency Medical Services fees, I accept that I will be liable for the legal costs on an attorney and client scale, which shall include, but is not limited, to tracing fees and 10% collection commission.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }} valign={'top'}>
                            7)
                          </td>
                          <td valign={'top'}>
                          7)	I hereby indemnify Legacy Emergency Medical Services, any of it’s director(s), shareholder(s), employee(s) or agent(s) from any loss and/or damage(s) and/or injury and/or death which I may suffer as a result of any act or omission, whether such loss and/or damage and/or injury and/or death is as a result of the negligence of any person relating to any of the services rendered by Legacy Emergency Medical Services.
                          </td>
                        </tr>
                      </table>
                      <div
                        style={{
                          width: '100%',
                          textDecorationLine: 'underline',
                          marginTop: 10,
                          marginBottom: 10
                        }}>
                        Annexure A
                      </div>
                      <table border={1}>
                        <tr>
                          <td valign={'top'}>Description</td>
                          <td valign={'top'}>Amount</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                          Call Out Fee, no Transport, RHT
                          </td>
                          <td valign={'top'}>R 800.00 </td>
                        </tr>
                        <tr>
                          <td valign={'top'}>Declaration of Death</td>
                          <td valign={'top'}>R 1,500.00 </td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Basic Life Support up to 60 min
                          </td>
                          <td valign={'top'}>R 3,012.16</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Basic Life Support every 15min thereafter
                          </td>
                          <td valign={'top'}>R 753.88</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Basic Life Support above 100km patient in-situ per
                            km
                          </td>
                          <td valign={'top'}>R 37.66</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Basic Life Support above 100km return no patient per
                            km
                          </td>
                          <td valign={'top'}>R 13.50</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Intermediate Life Support up to 60 min
                          </td>
                          <td valign={'top'}>R 4,070.45</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Intermediate Life Support every 15 min thereafter
                          </td>
                          <td valign={'top'}>R 1,017.65</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Intermediate Life Support above 100km patient
                            in-situ per km
                          </td>
                          <td valign={'top'}>R 50.68</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Intermediate Life Support above 100km return no
                            patient per km
                          </td>
                          <td valign={'top'}>R 14.63</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Advanced Life Support up to 60 min
                          </td>
                          <td valign={'top'}>R 5,406.68</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Advanced Life Support every 15 min thereafter
                          </td>
                          <td valign={'top'}>R 1,764.97</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Advanced Life Support above 100km patient in-situ
                            per km
                          </td>
                          <td valign={'top'}>R 78.25</td>
                        </tr>
                        <tr>
                          <td valign={'top'}>
                            Advanced Life Support above 100km return no patient
                            per km
                          </td>
                          <td valign={'top'}>R 16.31</td>
                        </tr>
                      </table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ padding: 5 }}>
                  <Card style={{ width: '100%' }}>
                    <CardHeader
                      title={
                        <h4 style={{ fontWeight: 'bold' }}>
                          Refusal of Treatment, Transport And/Or Evaluation
                        </h4>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ padding: 5 }}>
                        <table>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              Please read completely before signing below
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              Because it is sometimes impossible to recognize
                              actual or potential medical problems outside the
                              hospital, Legacy Emergency Specialists strongly
                              encourage you to be evaluated, treated if
                              neccessary, and transported to a hospital by
                              ambulance for a more complete examination by the
                              Emergency Unit physician.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              You as a patient have the right to choose not to
                              be evaluated, treated or transported if you wish;
                              however, there is the possiblilty that you could
                              suffer serious complications or even death from
                              conditions that are not apparent at this time.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              By signing below, you are acknowledging that
                              Legacy Emergency Specialists personnel have
                              advised you, and that you understand, the
                              potential harm to your health that may result from
                              your refusal of the recommended care; you release
                              Legacy Emergency Specialists (Pty) Ltd and
                              supporting personnel from liability resulting from
                              refusal of treatement, transport and/or
                              Evaluation.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              If you change your mind and desire evaluation,
                              treatment, and/or transport to a hospital, you may
                              contact us again telephonically at any time.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: 10 }} valign={'top'}>
                              I acknowledge that Legacy Emergency Specialists
                              personnel explained my condition to me and advised
                              me of some of the potential risk(s) and/or
                              complication(s) which could or would arise from
                              refusal of medical care. I have also been advised
                              that other common risk(s) and/or complication(s)
                              are possible up to and including the loss of life
                              or limb. Being aware that there are known and
                              unknown potential risk(s) and/or complication(s),
                              it is still my desire to refuse the advised
                              medical care.
                            </td>
                          </tr>
                        </table>
                      </Grid>
                      <Grid container item lg={12} md={12} sm={12}>
                        <Grid container item lg={5} md={5} sm={12}>
                          <div
                            style={{
                              width: '100%',
                              textDecorationLine: 'underline',
                              marginTop: 10,
                              marginBottom: 10
                            }}>
                            Person Capacity
                          </div>
                          <table border={1}>
                            <tr>
                              <td valign={'top'}>
                                Orientated to person, time and place?
                              </td>
                              <td valign={'top'}>
                                <RadioGroup
                                  name="oriented_person"
                                  onChange={e =>
                                    handleChange(e, 'patient_consent')
                                  }
                                  value={
                                    formState.patient_consent.oriented_person
                                  }>
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="no"
                                  />
                                </RadioGroup>
                              </td>
                            </tr>
                            <tr>
                              <td valign={'top'}>Coherent Speech?</td>
                              <td valign={'top'}>
                                <RadioGroup
                                  name="coherent_speech"
                                  onChange={e =>
                                    handleChange(e, 'patient_consent')
                                  }
                                  value={
                                    formState.patient_consent.coherent_speech
                                  }>
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="no"
                                  />
                                </RadioGroup>
                              </td>
                            </tr>
                            <tr>
                              <td valign={'top'}>
                                Auditory and/or visual hallucinations
                              </td>
                              <td valign={'top'}>
                                <RadioGroup
                                  name="auditory"
                                  onChange={e =>
                                    handleChange(e, 'patient_consent')
                                  }
                                  value={formState.patient_consent.auditory}>
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="no"
                                  />
                                </RadioGroup>
                              </td>
                            </tr>
                            <tr>
                              <td valign={'top'}>
                                Suicidal or Homicida ? SAPS notified
                              </td>
                              <td valign={'top'}>
                                <RadioGroup
                                  name="suicidal"
                                  onChange={e =>
                                    handleChange(e, 'patient_consent')
                                  }
                                  value={formState.patient_consent.suicidal}>
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="no"
                                  />
                                </RadioGroup>
                              </td>
                            </tr>
                            <tr>
                              <td valign={'top'}>
                                Under influence of alcohol/narcotics
                              </td>
                              <td valign={'top'}>
                                <RadioGroup
                                  name="alcohol"
                                  onChange={e =>
                                    handleChange(e, 'patient_consent')
                                  }
                                  value={formState.patient_consent.alcohol}>
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="no"
                                  />
                                </RadioGroup>
                              </td>
                            </tr>
                          </table>
                        </Grid>
                        <Grid
                          container
                          item
                          lg={7}
                          md={7}
                          sm={12}
                          style={{ paddingTop: 35 }}>
                          <Grid container item lg={12} md={12} xs={12}>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              style={{ padding: 3 }}
                              xs={12}>
                              <TextField
                                className={classes.textField}
                                fullWidth
                                inputProps={{ autoCapitalize: 'sentences' }}
                                label="Witness Name"
                                name="witness_name"
                                onChange={e =>
                                  handleChange(e, 'patient_consent')
                                }
                                size="medium"
                                type="text"
                                value={
                                  formState.patient_consent.witness_name || ''
                                }
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              style={{ padding: 3 }}
                              xs={12}>
                              <TextField
                                className={classes.textField}
                                fullWidth
                                inputProps={{ autoCapitalize: 'sentences' }}
                                label="Witness ID"
                                name="witness_id"
                                onChange={e =>
                                  handleChange(e, 'patient_consent')
                                }
                                size="medium"
                                type="text"
                                value={
                                  formState.patient_consent.witness_id || ''
                                }
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              style={{ padding: 3 }}
                              xs={12}>
                              <TextField
                                className={classes.textField}
                                fullWidth
                                inputProps={{ autoCapitalize: 'sentences' }}
                                label="Witness Relation"
                                name="witness_relation"
                                onChange={e =>
                                  handleChange(e, 'patient_consent')
                                }
                                size="medium"
                                type="text"
                                value={
                                  formState.patient_consent.witness_relation ||
                                  ''
                                }
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            style={{ padding: 3 }}
                            xs={12}>
                            <div
                              style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: 10,
                                width: '100%'
                              }}>
                              Witness Signature
                            </div>
                            <div
                              style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                width: '100%',
                                borderRadius: 20,
                                borderColor: 'green',
                                borderWidth: '1px',
                                borderStyle: 'solid'
                              }}>
                              <SignatureCanvas
                                canvasProps={{ className: classes.sigCanvas }}
                                penColor="black"
                                ref={witness_signature_canvas_item}
                              />
                            </div>
                            <div style={{ padding: 10, width: '100%' }}>
                              <Button
                                onClick={() => {
                                  if (
                                    witness_signature_canvas_item != null &&
                                    witness_signature_canvas_item.current !=
                                      null
                                  ) {
                                    witness_signature_canvas_item.current.clear();
                                  }
                                }}
                                variant="contained">
                                Reset
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  style={{ padding: 10, paddingRight: 15 }}
                  xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.patient_consent.accept_treatment}
                        name="accept_treatment"
                        onChange={e => handleChange(e, 'patient_consent')}
                      />
                    }
                    label="I accept treatment"
                  />
                </Grid>
                <Grid
                  item
                  lg={9}
                  md={9}
                  style={{ padding: 5, paddingRight: 5 }}
                  xs={12}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 10,
                      width: '100%'
                    }}>
                    Signature
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      width: '100%',
                      borderRadius: 20,
                      borderColor: 'green',
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }}>
                    <SignatureCanvas
                      canvasProps={{ className: classes.sigCanvas }}
                      penColor="black"
                      ref={signature_canvas_item}
                    />
                  </div>
                  <div style={{ padding: 10, width: '100%' }}>
                    <Button
                      onClick={onAddSignature_patient}
                      variant="contained">
                      Patient
                    </Button>
                    <Button
                      onClick={onAddSignature_hospital}
                      variant="contained">
                      Hospital
                    </Button>
                    <Button onClick={onClearSignature} variant="contained">
                      Reset
                    </Button>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  spacing={2}
                  style={{ padding: 20 }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 10,
                      width: '100%'
                    }}>
                    Patient Signature
                  </div>
                  <img
                    src={patient_signature}
                    style={{ width: '100%', height: 250 }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  spacing={2}
                  style={{ padding: 20 }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 10,
                      width: '100%'
                    }}>
                    Hospital Signature
                  </div>
                  <img
                    src={hospital_signature}
                    style={{ width: '100%', height: 250 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              title={<h4 style={{ fontWeight: 'bold' }}>Action</h4>}
            />
            <Divider />
            <CardContent>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                spacing={2}
                style={{ paddingTop: 20 }}>
                <LoadingButton
                  label="LOG CALL"
                  label_loading="LOG CALL"
                  loading={loading}
                  onClick={doLogCall}
                  style={{ marginLeft: 20 }}
                />
                <LoadingButton
                  label="SAVE DRAFT"
                  label_loading="SAVE DRAFT"
                  loading={save_draft_loading}
                  onClick={doSaveDraft}
                  style={{ marginLeft: 20 }}
                />
                <LoadingButton
                  label="Delete DRAFT"
                  label_loading="Delete DRAFT"
                  loading={delete_draft_loading}
                  onClick={deleteDraft}
                  style={{ marginLeft: 20 }}
                />

                {/* <Button variant = "contained" color = "primary" style = {{marginLeft : 20}} onClick = {() => doSaveDraft()}>SAVE DRAFT</Button> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick={() => showDriverCamDialog(false)}
        onClose={() => showDriverCamDialog(false)}
        open={isDriverCamDialog}>
        <DialogContent style={{ alignItems: 'flex-start', width: '100%' }}>
          {/* <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
            <IconButton onClick = {() =>showDialog(false)}><Close /></IconButton>
            <span style = {{fontSize : '16'}}>Capture Image</span>
          </div> */}
          <Camera
            handleTakePhoto={data => {
              setDriverPhoto(data);
              showDriverCamDialog(false);
            }}
            handleTakePhotoAnimationDone={data => {
              setDriverPhoto(data);
              showDriverCamDialog(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick={() => showHospitalCamDialog(false)}
        onClose={() => showHospitalCamDialog(false)}
        open={isHospitalCamDialog}>
        <DialogContent style={{ alignItems: 'flex-start', width: '100%' }}>
          {/* <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
            <IconButton onClick = {() =>showDialog(false)}><Close /></IconButton>
            <span style = {{fontSize : '16'}}>Capture Image</span>
          </div> */}
          <Camera
            handleTakePhoto={data => {
              setHospitalPhoto(data);
              showHospitalCamDialog(false);
            }}
            handleTakePhotoAnimationDone={data => {
              setHospitalPhoto(data);
              showHospitalCamDialog(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick={() => showCropDialog(false)}
        onClose={() => showCropDialog(false)}
        open={isCropDialog}>
        <DialogContent style={{ alignItems: 'flex-start', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Button
              onClick={() => {
                showCropDialog(false);
                getCropData(crop_flag);
              }}
              startIcon={<Crop />}
              variant="outlined">
              {' '}
              Crop
            </Button>
          </div>
          <Cropper
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            guides
            initialAspectRatio={1}
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            onInitialized={instance => {
              setCropper(instance);
            }}
            responsive
            src={crop_flag == true ? driver_photo : hospital_photo}
            style={{ height: 400, width: '100%' }} // https://github.com/fengyuanchen/cropperjs/issues/671
            viewMode={1}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

Users.propTypes = {
  history: PropTypes.object
};

export default withRouter(Users);
