import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider, FormControlLabel, Checkbox,  } from '@material-ui/core';
import {Add, Close, CloudUpload, PhotoCamera } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import SiteItem from './Component/SiteItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const SiteManage = () => {
  const classes = useStyles();
  const vehicle_photo_file_input = useRef(null);
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isAddDialog, showAddDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);

  
  const [cur_site, setCurSite] = useState({});

  const [sites, setSites] = useState([]);
  const [site_name, setSiteName] = useState('');
  const [contact_no, setContactNo] = useState('');
  const [contact_name, setContactName] = useState('');


  useEffect(() => {
    get_epr_sites();
  }, []);

  const get_epr_sites = () => {
    setPageLoad(false);
    _http.doPost('epr/get_sites', 
      {}, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setSites(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const add_epr_site = () => {
    setLoading(true);
    _http.doPost('epr/add_site', 
      {
        site_name : site_name,
        contact_no: contact_no,
        contact_name : contact_name
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showAddDialog(false);
          get_epr_sites();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const update_site = () => {
    if(cur_site == null || cur_site.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/update_site', 
      {
        site_id : cur_site.id,
        site_name : site_name,
        contact_no: contact_no,
        contact_name : contact_name
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showEditDialog(false);
          get_epr_sites();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const delete_site = () => {
    if(cur_site == null || cur_site.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/delete_site', 
      {
        site_id : cur_site.id,
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_epr_sites();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              <Button
                color = "primary"
                onClick = {() => {
                  setSiteName(''); setContactNo(''); setContactName(''); showAddDialog(true);
                }}
                startIcon = {<Add />}
                variant = "contained"
              >Add Site</Button> 
            </Grid>
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              <Card style = {{width : '100%'}}>
                <CardContent style = {{padding : 8}}>
                  <Grid
                    container
                    item
                    lg={12}
                    md = {12}
                    sm = {12}
                  >
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Site Name</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Contact No</Grid>
                    <Grid
                      item
                      lg={3}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Contact Name</Grid>
                    <Grid
                      item
                      lg={5}
                      md = {5}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Action</Grid>
                  </Grid>
                </CardContent>
              </Card>
              {
                sites.map((site, index) => (
                  <SiteItem
                    onAudit = {(site_item) => {
                      setCurSite(site_item);
                      document.location.href = '/epr_form/audit?tab=0&site=' + site_item.id
                    }} 
                    onDelete = {(site_item) => {
                      setCurSite(site_item);
                      setSiteName(site_item.site_name); setContactNo(site_item.contact_no); setContactName(site_item.contact_name);
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(site_item) => {
                      setCurSite(site_item);
                      setSiteName(site_item.site_name); setContactNo(site_item.contact_no); setContactName(site_item.contact_name);
                      showEditDialog(true)
                    }} 
                    onEquip = {(site_item) => {
                      setCurSite(site_item);
                      document.location.href = '/epr_form/equip?tab=0&site=' + site_item.id
                    }}
                    site_data = {site}
                  />
                ))
              }
            </Grid>
          </Grid>
      } 

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showAddDialog(false)}
        onEntered = {() => {
        }}
        open={isAddDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Add details of site.</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Site Name"
            margin="dense"
            onChange = {event => setSiteName(event.target.value)}
            value = {site_name}
          />
          <TextField
            fullWidth
            label="Contact No"
            margin="dense"
            onChange = {event => setContactNo(event.target.value)}
            value = {contact_no}
          />
          <TextField
            fullWidth
            label="Contact Name"
            margin="dense"
            onChange = {event => setContactName(event.target.value)}
            value = {contact_name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showAddDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Add Site"
            label_loading = "Add Site"
            loading = {loading}
            onClick = {add_epr_site}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered = {() => {
        }}
        open={isEditDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Edit details of site.</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Site Name"
            margin="dense"
            onChange = {event => setSiteName(event.target.value)}
            value = {site_name}
          />
          <TextField
            fullWidth
            label="Contact No"
            margin="dense"
            onChange = {event => setContactNo(event.target.value)}
            value = {contact_no}
          />
          <TextField
            fullWidth
            label="Contact Name"
            margin="dense"
            onChange = {event => setContactName(event.target.value)}
            value = {contact_name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showEditDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Update"
            label_loading = "Update"
            loading = {loading}
            onClick = {update_site}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this site ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {delete_site}
          />
        </DialogActions>
      </Dialog>
 
    </div>
  );
};

export default SiteManage;
