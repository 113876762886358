import React, {useEffect, useState, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Grid, IconButton, Breadcrumbs, Link, Button, } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {ArrowBack, MailOutline} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import ClinicReport from './Component/ClinicReport';
import AdminReport from './Component/AdminReport';
import * as qs from 'query-string';
import moment from 'moment';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 'calc(100% - 54px)'
  },
  padding: {
    // padding: theme.spacing(3),
  },
  demo1: {
    padding : 20,
    width : '100%',
    height : '100%',  
    display : 'flex',
    flexDirection : 'column',
    backgroundColor: theme.palette.background.paper,
  },
}));


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff'
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab
  disableRipple
  {...props}
               />);

const TestPreview = () => {
  const classes = useStyles();
  const clinicReportRef = useRef(null);
  const adminReportRef = useRef(null);
  
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);

  const [siteName, setSiteName] = React.useState('');
  const [report_data, setReport] = React.useState([]);
  const [reportDate, setReportDate] = useState(new Date());

  const parsed = qs.parse(document.location.search);
  let site_id = parsed.site;

  useEffect(() => {
    get_site_data();
    get_report();
  }, []);

  
  const get_site_data = () => {
    _http.doPost('cwr/get_cwr_site_data', 
      {
        site_id : site_id
      }, 
      (data) => {
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setSiteName(data.data.site_name);
        }
      },
      (err) => {
        console.log(err); 
        alert('Error occured');
      }
    );
  }

  const get_report = () => {
    setPageLoad(false)
    _http.doPost('cwr/get_cwr_report', 
      {
        site_id : site_id
      }, 
      (data) => {
        setPageLoad(true)
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setReport(data.data);
        }
      },
      (err) => {
        setPageLoad(true)
        console.log(err); 
        alert('Error occured');
      }
    );
  }


  const send_Report = async () => {
    setLoading(true)
    let clinicReport_html = clinicReportRef.current.innerHTML;
    // console.log('clinicReport_html')
    clinicReport_html = clinicReport_html.replaceAll('data-first-table="true" style="width: 100%;"', 'data-first-table="true" style="width: 100%; height: 750px;"');
    clinicReport_html = clinicReport_html.replaceAll('data-patient-table="true" style="width: 100%;"', 
      'data-patient-table="true" style="width: 100%; height: 750px;"' );
    // // for safari
    // clinicReport_html = clinicReport_html.replaceAll('table-layout: fixed; word-wrap: break-word;', 'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;');
    // clinicReport_html = clinicReport_html.replaceAll('break-after: page; overflow: hidden; margin-top: 10px;', 'display : none');
    // clinicReport_html = clinicReport_html.replaceAll('width: 100%; border: none; margin-top: 10px;', 'width: 100%; height: 200mm; margin-top: 10px;');
    let adminReport_html = adminReportRef.current.innerHTML;
    // console.log('adminReport_html', adminReport_html)
    adminReport_html = adminReport_html.replaceAll('data-first-table="true" style="width: 100%;"', 'data-first-table="true" style="width: 100%; height: 750px;"');
    adminReport_html = adminReport_html.replaceAll('data-patient-table="true" style="width: 100%;"', 
      'data-patient-table="true" style="width: 100%; height: 750px;"' );

    let clinic_res = await _http.doPost_NoCallback('cwr/send_email',  {
      to : 'test-mail@legacyems.co.za',  //'admin@legacyems.co.za, gavin@legacyems.co.za',
      orientation : 'portrait',
      subject : 'Please find attached from Legacy Emergency Specialists',
      pdf_name : 'Clinical-Report-' + moment(reportDate).format('YYYY-MM-DD') + '_' + new Date().getTime() + '.pdf',
      html_str : clinicReport_html
    });
    if (clinic_res == null)
    {
      setLoading(false)
      alert('Error occured');
      return
    }
     
    // ///////////
    let admin_res = await _http.doPost_NoCallback('cwr/send_email',  {
      to : 'test-mail@legacyems.co.za', //'admin@legacyems.co.za, gavin@legacyems.co.za', 
      orientation : 'portrait',
      subject : 'Please find attached from Legacy Emergency Specialists',
      pdf_name : 'Admin-Report-' + moment(reportDate).format('YYYY-MM-DD') + '_' + new Date().getTime() + '.pdf',
      html_str : adminReport_html
    });
    if (admin_res == null)
    {
      setLoading(false)
      alert('Error occured');
      return
    }
    setLoading(false)
    alert('Report is sent successfully');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // document.location.href = 'home?tab=' + newValue;
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        lg={12}
        md = {12}
        style = {{height : '100%'}}
        xs = {12}
      >
        <Grid
          container
          item
          lg={12}
          md = {12}
          style = {{marginBottom : 8, marginTop : 10}}
          xs = {12}
        >
          <Grid
            container
            item
            lg={6}
            md = {4}
            style = {{alignItems : 'center'}}
            xs = {12}
          >
            <IconButton
              onClick = {() => {document.location.href = '/cwr_form/patient?site=' + site_id}}
              variant = "contained"
            >
              <ArrowBack />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              style = {{marginLeft : 10}}
            >
              <Link color="inherit"  >{siteName}</Link>
            </Breadcrumbs>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md = {3}
            style = {{alignItems : 'center'}}
            xs = {12}
          >
            <span style = {{fontSize : 12, fontWeight : 'bold', marginRight : 6}}>Report Date : </span>
            <DatePicker
              closeOnScroll
              dateFormat="dd/MM/yyyy"
              onChange={date => setReportDate(date)}
              selected={reportDate}
            />
          </Grid>
          <Grid
            container
            item
            lg={3}
            md = {4}
            xs = {12}
          >
            <LoadingButton
              color = "primary"
              label = "Send REPORT"
              label_loading = "Send REPORT"
              loading = {loading}
              onClick = {() => {
                send_Report()
              }}
              startIcon = {<MailOutline />}
              style = {{height : 40, marginLeft : 10}}
              variant = "contained"
            >Send REPORT</LoadingButton>  
          </Grid>
        </Grid>
            
        {
          pageLoaded == true ? 
            <div className={classes.demo1}>
              <AntTabs
                aria-label="ant example"
                onChange={handleChange}
                value={value}
              >
                <AntTab label="Clinical Report" />
                <AntTab label="Admin Report" />
              </AntTabs>
              <div
                ref = {clinicReportRef}
                style = {{width : '100%', display : value == 0 && report_data.length > 0? 'inherit': 'none'}}
              > <ClinicReport
                  report_data = {report_data}
                site_name = {siteName}
                reportDate = {reportDate}
                /> </div>
              <div
                ref = {adminReportRef}
                style = {{width : '100%', display : value == 1 && report_data.length > 0? 'inherit': 'none'}}
              > <AdminReport
                  report_data = {report_data}
                site_name = {siteName}
                reportDate = {reportDate}
                /> </div>
            </div>
            :
            <PageLoading />
        }  
      </Grid>
    </div>
  );
};

export default TestPreview;
