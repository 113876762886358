import React, {useState, useEffect} from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
 
function App (props) {
  const { handleTakePhoto, handleTakePhotoAnimationDone } = props;

  function handleCameraError (error) {
    console.log('handleCameraError', error);
  }
    
  function handleCameraStart (stream) {
    console.log('handleCameraStart');
  }
    
  function handleCameraStop () {
    console.log('handleCameraStop');
  }
 
  return (
    <Camera
      idealFacingMode = {FACING_MODES.ENVIRONMENT}
      idealResolution = {{width: 1440, height: 2560}}
      imageCompression = {0}
      imageType = {IMAGE_TYPES.JPG}
      isDisplayStartCameraError
      isFullscreen = {false}
      isImageMirror = {false}
      isMaxResolution = {false}
      isSilentMode = {false}
      onCameraError = {(error) => { handleCameraError(error); }}
      onCameraStart = {(stream) => { handleCameraStart(stream); }}
      onCameraStop = {() => { handleCameraStop(); }}
      onTakePhoto = {(dataUri) => { handleTakePhoto(dataUri); }}
      onTakePhotoAnimationDone = {(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
      sizeFactor = {0.3}
    />
  );
}
 
export default App;