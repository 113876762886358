import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider, FormControlLabel, Checkbox,  } from '@material-ui/core';
import {Add, Close, CloudUpload, PhotoCamera } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import KitItem from './Component/KitItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const FirstAitKitManage = () => {
  const classes = useStyles();
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isAddDialog, showAddDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);

  
  const [cur_kit, setCurKit] = useState({});
  const [kits, setkits] = useState([]);

  const parsed = qs.parse(document.location.search);

  useEffect(() => {
    get_kits();
  }, []);

  const get_kits = () => {
    setPageLoad(false);
    _http.doPost('epr/get_kits', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setkits(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const add_kit = () => {
    setLoading(true);
    _http.doPost('epr/add_kit', 
      {
        site_id : parsed.site,
        fakit_info : fakit_info
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showAddDialog(false);
          get_kits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const update_kit = () => {
    if(cur_kit == null || cur_kit.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/update_kit', 
      {
        site_id : parsed.site,
        kit_id : cur_kit.id,
        fakit_info : fakit_info
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showEditDialog(false);
          get_kits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const delete_kit = () => {
    if(cur_kit == null || cur_kit.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/delete_kit', 
      {
        site_id : parsed.site,
        kit_id : cur_kit.id
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_kits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  

  const [fakit_info, setFakitInfo] = useState({});
  const handleFakitInfoChange = (event) => {
    event.persist();
    setFakitInfo(fakit_info => ({
      ...fakit_info,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              <Button
                color = "primary"
                onClick = {() => {
                  setFakitInfo({}); showAddDialog(true);
                }}
                startIcon = {<Add />}
                variant = "contained"
              >Add Kit</Button> 
            </Grid>
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              <Card style = {{width : '100%'}}>
                <CardContent style = {{padding : 8}}>
                  <Grid
                    container
                    item
                    lg={12}
                    md = {12}
                    sm = {12}
                  >
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Type</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Identification No</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Location</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Seal No 1</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Seal No 2</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Action</Grid>
                  </Grid>
                </CardContent>
              </Card>
              {
                kits.map((kit, index) => (
                  <KitItem 
                    kit_data = {kit} 
                    onDelete = {(kit_item) => {
                      setCurKit(kit_item);
                      setFakitInfo({});
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(kit_item) => {
                      setCurKit(kit_item);
                      setFakitInfo(kit_item);
                      showEditDialog(true)
                    }} 
                  />
                ))
              }
            </Grid>
          </Grid>
      } 

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showAddDialog(false)}
        onEntered = {() => {
        }}
        open={isAddDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Add details of First Aid Kit.</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
            <Select
              inputProps={{
                name: 'type',
                id: 'outlined-age-native-simple',
              }}
              label="Type"
              native
              onChange={(e) => handleFakitInfoChange(e)}
              value={fakit_info.type || ''}
            >
              <option
                aria-label="None"
                value=""
              />
              {
                ['Regulation 7', 'Mine Spec'].map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Identification No"
            margin="dense"
            name = "id_no"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.id_no || ''}
          />
          <TextField
            fullWidth
            label="Location"
            margin="dense"
            name = "location"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.location || ''}
          />
          <TextField
            fullWidth
            label="Seal No 1"
            margin="dense"
            name = "seal_no_1"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.seal_no_1 || ''}
          />
          <TextField
            fullWidth
            label="Seal No 2"
            margin="dense"
            name = "seal_no_2"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.seal_no_2 || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showAddDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Add Kit"
            label_loading = "Add Kit"
            loading = {loading}
            onClick = {add_kit}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered = {() => {
        }}
        open={isEditDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Edit details of First Aid Kit.</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
            <Select
              inputProps={{
                name: 'type',
                id: 'outlined-age-native-simple',
              }}
              label="Type"
              native
              onChange={(e) => handleFakitInfoChange(e)}
              value={fakit_info.type || ''}
            >
              <option
                aria-label="None"
                value=""
              />
              {
                ['Regulation 7', 'Mine Spec'].map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Identification No"
            margin="dense"
            name = "id_no"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.id_no || ''}
          />
          <TextField
            fullWidth
            label="Location"
            margin="dense"
            name = "location"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.location || ''}
          />
          <TextField
            fullWidth
            label="Seal No 1"
            margin="dense"
            name = "seal_no_1"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.seal_no_1 || ''}
          />
          <TextField
            fullWidth
            label="Seal No 2"
            margin="dense"
            name = "seal_no_2"
            onChange={(e) => handleFakitInfoChange(e)}
            value={fakit_info.seal_no_2 || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showEditDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Update"
            label_loading = "Update"
            loading = {loading}
            onClick = {update_kit}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this Kit ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {delete_kit}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FirstAitKitManage;
