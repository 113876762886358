import config from './config';
import axios from 'axios';

const doGet = (url, body, onSuccess, onFail) => {
} 

const doPost_NoCallback = async (url, body) => {
  try{
    let response = await axios({
      method  :'post',
      url : config.api_url + url,
      withCredentials: false,
      headers : {
        Accept : 'application/json',
        'Content-Type' : 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      },
      data : body
    })
    let data = await response.data;
    return data;
  }
  catch(err)
  {
    return null;
  }
} 

const doPost = async (url, body, onSuccess, onFail) => {
  try{
    let response = await axios({
      method  :'post',
      url : config.api_url + url,
      withCredentials: false,
      headers : {
        Accept : 'application/json',
        'Content-Type' : 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      data : body
    })
    let data = await response.data;
    onSuccess(data);
  }
  catch(err)
  {
    onFail(err);
  }
} 

const doPostFile = async (url, formData, onSuccess, onFail) => {
  try{
    let response = await axios({
      method  :'post',
      url : config.api_url + url,
      headers : {
        'Content-Type': 'multipart/form-data'
      },
      data : formData
    })
    let data = await response.data;
    onSuccess(data);
  }
  catch(err)
  {
    onFail(err);
  }
} 


export default {doGet, doPost_NoCallback, doPost, doPostFile}