import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GroupAdd, Add, Close, ArrowBack, CheckBoxOutlineBlank, CheckBox, ExpandMore, CloudUpload, PhotoCamera} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import SignatureCanvas from 'react-signature-canvas'
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  grid: {
    height: '100%'
  },
  table_font : {
    fontSize : '14px !important',
    
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      fontSize : '10px !important'
    }
  },
  table1 : {
    fontSize: '60%',
    lineHeight: '10px',
  },
  license_img_wrapper_pc : {
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      display : 'none !important',
    }
  },
  license_img_wrapper_phone : {
    [theme.breakpoints != null && theme.breakpoints.up('md')]: {
      display : 'none',
    }
  }
}));

const AuditReport = props => {
  const { history, fakitRegData, fakitMineData, feAuditData, reportDate, site_name, } = props;
  const classes = useStyles();

  const [fakit_RegData, setFakitRegData] = useState([])
  const [fakit_MineData, setFakitMineData] = useState([])
  const [feData, setFeReportData] = useState([])
  const [report_date, setReportDate] = useState(moment(new Date()).format('ll'))
  let registered_user = _sessionStorage.getItem('user');
  
  useEffect(()=>{
    setReportDate(moment(reportDate).format('ll'))
    setFakitRegData(fakitRegData);
    setFakitMineData(fakitMineData);
    setFeReportData(feAuditData);
  }, [fakitRegData, fakitMineData, feAuditData, reportDate])

  const getColor = (value) => {
    if (value == '') return '';
    if (value == 'Yes') return 'lightgreen';
    if (value == 'No') return 'red';
    if (value == 'Safe') return 'lightgreen';
    if (value == 'Unsafe') return 'red';
  }


  return (
    <Grid
      className = {classes.table_font}
      container
      item
      lg={12}
      md = {12}
      sm = {12}
      style = {{width : '100%'}}
    >
      <div
        id="print1"
        style = {{width : '100%'}}
      >
        <div
          data-first-table
          style = {{width : '100%'}}
        >
          <table
            style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
            width="99%"
          >
            <tbody>
              <tr>
                <td
                  colSpan = {2}
                  width="100%"
                >
                  <img
                    src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                    width="80px"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan = {2}
                  style = {{textAlign : 'center'}}
                  width="100%"
                >
                  <h1>Legacy Emergency Specialists Audit conducted for  {site_name}</h1>
                </td>
              </tr>
              <tr> <td
                colSpan = {2}
                style = {{height : '120px'}}
                width="100%"
              > <span /> </td> </tr>
              <tr>
                <td
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="60%"
                >
                  <span>Conducted on</span>
                </td>
                <td
                  align = "right"
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="40%"
                >
                  <span>{report_date}</span>
                </td>
              </tr>
              <tr>
                <td
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="60%"
                >
                  <span>Prepared by</span>
                </td>
                <td
                  align = "right"
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="40%"
                >
                  <span>{registered_user.name}</span>
                </td>
              </tr>
              <tr>
                <td
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="60%"
                >
                  <span>Location</span>
                </td>
                <td
                  align = "right"
                  style = {{borderBottomColor : '#000', borderBottomStyle : 'solid', borderBottomWidth : 1}}
                  width="40%"
                >
                  <span>{site_name} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {
          fakit_RegData.map((fakit, index) => 
            moment(fakit.createdAt).format('ll') == report_date ?
              <div
                data-patient-table
                key = {index}
                style = {{width : '100%'}}
              >
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td  style = {{backgroundColor : 'lightgray'}}>
                        <h3>Reg 7 FirstAid Kit {fakit.fakit_id_no} - {fakit.fakit_location}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                          width="99%"
                        >
                          <tbody>
                            <tr>
                              <td width="33%">
                                <div>Old Seal Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.old_seal_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                              <td width="33%">
                                <div>New Seal Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.new_seal_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                              <td width="33%">
                                <div>Kit Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.kit_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width="80%">
                        <strong>STERILE GAUZE (5’s) (75mm x 75mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.sterile_gauze)}}
                        width="20%"
                      >{fakit.audit_data == null ? '' : fakit.audit_data.sterile_gauze}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>ANTISEPTIC SOLUTION (100ml)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.antiseptic_solution)}}
                        width="20%"
                      >{fakit.audit_data == null ? '' : fakit.audit_data.antiseptic_solution}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>COTTON WOOL ROLLS (50g)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cotton_wool_rolls)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cotton_wool_rolls}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>SCISSOR</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.scissor)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.scissor}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>SAFETY PINS</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.safety_pins)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.safety_pins}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>FORCEPS STAINLESS STEEL</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.forceps_stainless_steel)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.forceps_stainless_steel}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>PLASTER STRIPS</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.plaster_strips)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.plaster_strips}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>TRIANGULAR BANDAGES</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.triangular_bandages)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.triangular_bandages}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>CONFORM BANDAGES (75mm x 4,5m)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.conform_bandages_75)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.conform_bandages_75}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>CONFORM BANDAGES (100mm x 4,5m)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.conform_bandages_100)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.conform_bandages_100}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>PLASTER ROLL (25mm x 3m)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.plaster_roll)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.plaster_roll}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>FAD Dressing No. 5 (150mm x 200mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_5)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_5}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>FAD Dressing No. 3 (75mm x 100mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_3)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_3}</td>
                    </tr>

                    <tr>
                      <td width="80%">
                        <strong>EXAMINATION GLOVES (Large & Medium)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.examination_gloves)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.examination_gloves}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>PLASTIC INTERLOCKING SPLINTS</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.plastic_interlocking_splints)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.plastic_interlocking_splints}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>CPR MOUTHPIECES</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cpr_mouthpieces)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cpr_mouthpieces}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>ABSORBENT PAD SPILL</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.absorbent_pad_spill)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.absorbent_pad_spill}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>DISINFECTANT SACHETS (30g)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.disinfectant_sachets)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.disinfectant_sachets}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>HIGH RISK GLOVES (Large)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.high_risk_gloves)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.high_risk_gloves}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>MEDICAL WASTE BAG RED (Medium)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.medical_waste_bag_red)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.medical_waste_bag_red}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Date of Inspection</strong>
                      </td>
                      <td width="20%">{moment(fakit.createdAt).format('llll')}</td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Additional Notes / Recommendations</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>{fakit.audit_data == null ? '' : fakit.audit_data.additional_note}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Inspector on behalf of Legacy Emergency Specialists</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width = "100">
                        <img
                          src={fakit.author == null ? '' : fakit.author.sign_img}
                          style = {{height : '60px', width : '60px' }}
                        />
                      </td>
                      <td>
                        <div>{fakit.author == null ? '' : fakit.author.name}</div>
                        <div>{moment(fakit.createdAt).format('llll')}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : null
          )
        }
        {
          fakit_MineData.map((fakit, index) => 
            moment(fakit.createdAt).format('ll') == report_date ?
              <div
                data-patient-table
                key = {index}
                style = {{width : '100%'}}
              >
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td  style = {{backgroundColor : 'lightgray'}}>
                        <h3>Mine Spec FirstAid Kit {fakit.fakit_id_no} - {fakit.fakit_location}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                          width="99%"
                        >
                          <tbody>
                            <tr>
                              <td width="33%">
                                <div>Old Seal Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.old_seal_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                              <td width="33%">
                                <div>New Seal Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.new_seal_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                              <td width="33%">
                                <div>Kit Photo</div>
                                <img
                                  src={fakit.audit_data == null ? '' : fakit.audit_data.kit_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width="80%">
                        <strong>4 x Burnshield Dressings 10cm x 10cm</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.burnshield_dressings)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.burnshield_dressings}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>04	x	Sterile	Gauze	75mmx75mm	(5’s)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.sterile_gauze)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.sterile_gauze}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Saline	Pour	1000ml</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.saline_pour)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.saline_pour}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>02	x	Cetrimide	0.9%	100ml</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cetrimide)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cetrimide}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>04	x	Cotton	Wool	Roll	50g</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cotton_wool_rolls)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cotton_wool_rolls}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Scissor</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.scissor)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.scissor}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Safety	Pins	(12’s)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.safety_pins)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.safety_pins}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Forceps	Stainless	Steel</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.forceps_stainless_steel)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.forceps_stainless_steel}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>50	x	Plaster	Strips</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.plaster_strips)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.plaster_strips}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>06	x	Triangular	Bandages	(Disposable)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.triangular_bandages)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.triangular_bandages}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>04	x	FAD	Dressing	No.	5	(150mmx200mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_5)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_5}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>08	x	FAD	Dressing	No.	3	(75mmx100mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_3)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_3}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>12	x	FAD	Dressing	No.	2	(50mmx100mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_2)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.fad_dressing_2}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>02	x	Shell	Abdominal	Bandage	(300mmx300mm)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.shell_abdominal_bandage)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.shell_abdominal_bandage}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>02	x	Crepe	Bandage	(75mmx4,5m)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.crepe_bandage)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.crepe_bandage}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Gloves	Examination	– Large	(6	pairs)</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.gloves_examination)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.gloves_examination}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>12	x	Splint	Plastic	Interlocking</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.splint_plastic_interlocking)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.splint_plastic_interlocking}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>02	x	CPR	Mouthpiece	&	Instructions</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cpr_mouthpiece_instruction)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cpr_mouthpiece_instruction}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	150ml	Eyewash	Bottle</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.eyewash_bottle)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.eyewash_bottle}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Cervical	Collar	Disposable	– Large</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.cervical_collar_disposable)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.cervical_collar_disposable}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Rescue	Space	Blanket</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.rescue_space_blanket)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.rescue_space_blanket}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Basic	First	Aid	Booklet</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.basic_first_aid_booklet)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.basic_first_aid_booklet}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>01	x	Medical	Waste	Bag	Red</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fakit.audit_data == null ? '' : fakit.audit_data.medical_waste_bag_red)}}
                        width="20%"
                      >
                        {fakit.audit_data == null ? '' : fakit.audit_data.medical_waste_bag_red}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Date of Inspection</strong>
                      </td>
                      <td width="20%">{moment(fakit.createdAt).format('llll')}</td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Additional Notes / Recommendations</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>{fakit.audit_data == null ? '' : fakit.audit_data.additional_note}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Inspector on behalf of Legacy Emergency Specialists</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width = "100">
                        <img
                          src={fakit.author == null ? '' : fakit.author.sign_img}
                          style = {{height : '60px', width : '60px' }}
                        />
                      </td>
                      <td>
                        <div>{fakit.author == null ? '' : fakit.author.name}</div>
                        <div>{moment(fakit.createdAt).format('llll')}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : null
          )
        }
        {
          feData.map((fe_item, index) => 
            moment(fe_item.createdAt).format('ll') == report_date ?
              <div
                data-patient-table
                key = {index}
                style = {{width : '100%'}}
              >
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td  style = {{backgroundColor : 'lightgray'}}>
                        <h3>FireExtinguisher - {fe_item.fe_id_no} - {fe_item.fe_location} - {fe_item.fe_type}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                          width="99%"
                        >
                          <tbody>
                            <tr>
                              <td width="33%">
                                <div>Current Seal Photo</div>
                                <img
                                  src={fe_item.audit_data == null ? '' : fe_item.audit_data.old_seal_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                              <td width="33%">
                                <div>Extinguisher Photo</div>
                                <img
                                  src={fe_item.audit_data == null ? '' : fe_item.audit_data.fe_photo}
                                  style = {{height : '100px', width : '100px' }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width="80%">
                        <strong>Type of Extinguisher</strong>
                      </td>
                      <td width="20%">{fe_item.fe_type}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Access to, or visibility of, the fire extinguisher is not obstructed</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.access_visible)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.access_visible}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>The operating instructions on the fire extinguisher are legible and facing outward</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.operating_instruction)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.operating_instruction}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Has the fire extinguisher been actuated or tampered with?</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.actuated)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.actuated}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Visually examine externally. Check that there is no obvious physical damage or condition that would prevent operation</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.physical_damage)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.physical_damage}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Still within service date?</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.within_service_date)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.within_service_date}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Still within Pressure Test Date?</strong>
                      </td>
                      <td
                        style = {{backgroundColor : getColor(fe_item.audit_data == null ? '' : fe_item.audit_data.within_test_date)}}
                        width="20%"
                      >
                        {fe_item.audit_data == null ? '' : fe_item.audit_data.within_test_date}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Date Serviced</strong>
                      </td>
                      <td width="20%">{fe_item.audit_data == null ? '' : moment(fe_item.audit_data.date_serviced).format('ll')}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Next Service</strong>
                      </td>
                      <td width="20%">{fe_item.audit_data == null ? '' : moment(fe_item.audit_data.next_service_date).format('ll')}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Next Pressure Test Date</strong>
                      </td>
                      <td width="20%">{fe_item.audit_data == null ? '' : moment(fe_item.audit_data.next_test_date).format('ll')}</td>
                    </tr>
                    <tr>
                      <td width="80%">
                        <strong>Date of Inspection</strong>
                      </td>
                      <td width="20%">{moment(fe_item.createdAt).format('llll')}</td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Additional notes / remarks?</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>{fe_item.audit_data == null ? '' : fe_item.audit_data.additional_note}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan = {2}>
                        <strong>Inspector on behalf of Legacy Emergency Specialists</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
                  width="99%"
                >
                  <tbody>
                    <tr>
                      <td width = "100">
                        <img
                          src={fe_item.author == null ? '' : fe_item.author.sign_img}
                          style = {{height : '60px', width : '60px' }}
                        />
                      </td>
                      <td>
                        <div>{fe_item.author == null ? '' : fe_item.author.name}</div>
                        <div>{moment(fe_item.createdAt).format('llll')}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : null
          )
        }
      </div>
        
    </Grid>
  );
};


export default AuditReport;
