import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider, Breadcrumbs, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import {Add, Close, CloudUpload, PhotoCamera, ArrowBack, MailOutline, Assistant } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import TestItem from './Component/TestItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const TestManage = () => {
  const classes = useStyles();
  const vehicle_photo_file_input = useRef(null);
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isTestDialog, showTestDialog] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isAddDialog, showAddDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);
  
  const [cur_test, setCurTest] = useState({});
  const [site_name, setSiteName] = useState('');
  const [patientName, setPatientName] = useState('');

  const [tests, setTests] = useState([]);
  const [consulatDate, setConsultDate] = useState(new Date());

  const parsed = qs.parse(document.location.search);
  let site_id = parsed.site;
  let patient_id = parsed.patient;

  useEffect(() => {
    get_site_data();
    get_patient_data();
    get_tests();
  }, []);

  const get_site_data = () => {
    _http.doPost('cwr/get_cwr_site_data', 
      {
        site_id : site_id
      }, 
      (data) => {
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setSiteName(data.data.site_name);
        }
      },
      (err) => {
        console.log(err); 
        alert('Error occured');
      }
    );
  }

  const get_patient_data = () => {
    console.log(patient_id)
    _http.doPost('cwr/get_cwr_patient_data', 
      {
        site_id : site_id,
        patient_id : patient_id
      }, 
      (data) => {
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setPatientName(data.data.patient_name + ' ' + data.data.patient_surname);
        }
      },
      (err) => {
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const get_tests = () => {
    setPageLoad(false);
    _http.doPost('cwr/get_tests', 
      {
        site_id : site_id,
        patient_id : patient_id
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setTests(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const update_test = () => {
    if(cur_test == null || cur_test.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('cwr/update_test', 
      {
        site_id : site_id,
        patient_id : patient_id,
        test_id : cur_test.id,
        consult_date : consulatDate.getTime(),
        test_data : test_data
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showEditDialog(false);
          get_tests();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const delete_test = () => {
    if(cur_test == null || cur_test.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('cwr/delete_test', 
      {
        site_id : site_id,
        patient_id : patient_id,
        test_id : cur_test.id
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_tests();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const doAddTest = () => {
    setLoading(true);
    _http.doPost('cwr/add_test', 
      {
        site_id : site_id,
        patient_id : patient_id,
        consult_date : consulatDate.getTime(),
        test_data : test_data
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showTestDialog(false);
          get_tests();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  
  const [test_data, setTestData] = useState({});
  const handleChange = (event) => {
    event.persist();
    setTestData(test_data => ({
      ...test_data,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              container
              item
              lg={12}
              md = {12}
              style = {{marginBottom : 8}}
              xs = {12}
            >
              <Grid
                container
                item
                lg={8}
                md = {7}
                style = {{alignItems : 'center'}}
                xs = {12}
              >
                <IconButton
                  onClick = {() => { 
                    document.location.href = '/cwr_form/patient?site=' + site_id
                  }}
                  variant = "contained"
                >
                  <ArrowBack />
                </IconButton>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  style = {{marginLeft : 10}}
                >
                  <Link color="inherit"  > 
                    {site_name}
                  </Link>
                  <Link color="inherit" >
                    {patientName}
                  </Link>
                </Breadcrumbs>
              </Grid>
              <Grid
                container
                item
                lg={4}
                md = {5}
                xs = {12}
              >
                <Button
                  color = "primary"
                  onClick = {() =>
                  {
                    setTestData({})
                    setConsultDate(new Date())
                    showTestDialog(true)
                  }}
                  startIcon = {<Add />}
                  style = {{height : 40}}
                  variant = "contained"
                >New Test</Button> 
              </Grid>
            </Grid>
            
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              <Card style = {{width : '100%'}}>
                <CardContent style = {{padding : 8}}>
                  <Grid
                    container
                    item
                    lg={12}
                    md = {12}
                    sm = {12}
                  >
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Consultation date</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Chronic Conditions</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {5}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Chronic Medication</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Improvement</Grid>
                    <Grid
                      item
                      lg={4}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Action</Grid>
                  </Grid>
                </CardContent>
              </Card>
              {
                tests.map((test, index) => (
                  <TestItem
                    onDelete = {(test_item) => {
                      setCurTest(test_item)
                      setConsultDate(new Date(test_item.consult_date));
                      setTestData(test_item)
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(test_item) => {
                      setCurTest(test_item)
                      console.log(test_item.id)
                      setConsultDate(new Date(test_item.consult_date));
                      setTestData(test_item)
                      showEditDialog(true)
                    }} 
                    test_data = {test}
                  />
                ))
              }
            </Grid>
          </Grid>
      } 


      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered = {() => {
        }}
        open={isEditDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Edit details of test data.</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={2}
              md = {4}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >Consultation date : </Grid>
            <Grid
              item
              lg={5}
              md = {4}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <DatePicker
                closeOnScroll
                dateFormat="dd/MM/yyyy"
                onChange={date => setConsultDate(date)}
                selected={consulatDate}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md = {4}
              xs = {12}
            >
              <FormControlLabel
                control={<Checkbox
                  checked={test_data.patient_unfit}
                  name="patient_unfit"
                  onChange={(e) => handleChange(e)}
                         />}
                fullWidth
                label="Patient Unfit"
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Age"
                margin="dense"
                name="patient_age"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.patient_age || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Chronic Conditions"
                margin="dense"
                name="chronic_conditions"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.chronic_conditions || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Chronic Medication"
                margin="dense"
                name="chronic_medication"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.chronic_medication || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Smoker"
                margin="dense"
                name="smoker"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.smoker || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Weight"
                margin="dense"
                name="weight"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.weight || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Height"
                margin="dense"
                name="height"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.height || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Systolic Pressure"
                margin="dense"
                name="blood_pressure_higher"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_pressure_higher || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Diastolic Pressure"
                margin="dense"
                name="blood_pressure_lower"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_pressure_lower || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Pulse Rate"
                margin="dense"
                name="pulse_rate"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.pulse_rate || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Blood Glucose"
                margin="dense"
                name="blood_glucose"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_glucose || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <FormControl
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="outlined-age-native-simple">HIV Test</InputLabel>
                <Select
                  inputProps={{
                    name: 'hiv_test',
                    id: 'outlined-age-native-simple',
                  }}
                  label="HIV Test"
                  native
                  onChange={(e) => handleChange(e)}
                  value={test_data.hiv_test || ''}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    ['Not done', 'Positive', 'Negative'].map((type) => (
                      <option value={type}>{type}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <FormControl
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="outlined-age-native-simple">Drug Test</InputLabel>
                <Select
                  inputProps={{
                    name: 'drug_test',
                    id: 'outlined-age-native-simple',
                  }}
                  label="Drug Test"
                  native
                  onChange={(e) => handleChange(e)}
                  value={test_data.drug_test || ''}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    ['Not done', 'Positive', 'Negative'].map((type) => (
                      <option value={type}>{type}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Last Meal"
                margin="dense"
                name="last_meal"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.last_meal || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Refferal Note"
                margin="dense"
                multiline
                name="refferal_note"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.refferal_note || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Advice given"
                margin="dense"
                multiline
                name="advice_given"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.advice_given || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Improvement"
                margin="dense"
                multiline
                name="improvement"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.improvement || ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showEditDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Update"
            label_loading = "Update"
            loading = {loading}
            onClick = {update_test}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this test data ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {delete_test}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showTestDialog(false)}
        open={isTestDialog}
      >
        <DialogTitle id="form-dialog-title"  >
          <span style = {{fontWeight : 'bold'}}>Test Form : {patientName}</span>
        </DialogTitle>
        <Divider />
        <DialogContent
          className = {classes.dialog}
          style = {{minHeight : '400px'}}
        >
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={2}
              md = {4}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >Consultation date : </Grid>
            <Grid
              item
              lg={5}
              md = {4}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <DatePicker
                closeOnScroll
                dateFormat="dd/MM/yyyy"
                onChange={date => setConsultDate(date)}
                selected={consulatDate}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md = {4}
              xs = {12}
            >
              <FormControlLabel
                control={<Checkbox
                  checked={test_data.patient_unfit}
                  name="patient_unfit"
                  onChange={(e) => handleChange(e)}
                         />}
                fullWidth
                label="Patient Unfit"
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Age"
                margin="dense"
                name="patient_age"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.patient_age || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Chronic Conditions"
                margin="dense"
                name="chronic_conditions"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.chronic_conditions || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Chronic Medication"
                margin="dense"
                name="chronic_medication"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.chronic_medication || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Smoker"
                margin="dense"
                name="smoker"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.smoker || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Weight"
                margin="dense"
                name="weight"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.weight || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Height"
                margin="dense"
                name="height"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.height || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Systolic Pressure"
                margin="dense"
                name="blood_pressure_higher"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_pressure_higher || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Diastolic Pressure"
                margin="dense"
                name="blood_pressure_lower"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_pressure_lower || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Pulse Rate"
                margin="dense"
                name="pulse_rate"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.pulse_rate || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Blood Glucose"
                margin="dense"
                name="blood_glucose"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.blood_glucose || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <FormControl
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="outlined-age-native-simple">HIV Test</InputLabel>
                <Select
                  inputProps={{
                    name: 'hiv_test',
                    id: 'outlined-age-native-simple',
                  }}
                  label="HIV Test"
                  native
                  onChange={(e) => handleChange(e)}
                  value={test_data.hiv_test || ''}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    ['Not done', 'Positive', 'Negative'].map((type) => (
                      <option value={type}>{type}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <FormControl
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="outlined-age-native-simple">Drug Test</InputLabel>
                <Select
                  inputProps={{
                    name: 'drug_test',
                    id: 'outlined-age-native-simple',
                  }}
                  label="Drug Test"
                  native
                  onChange={(e) => handleChange(e)}
                  value={test_data.drug_test || ''}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    ['Not done', 'Positive', 'Negative'].map((type) => (
                      <option value={type}>{type}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Last Meal"
                margin="dense"
                name="last_meal"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.last_meal || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Refferal Note"
                margin="dense"
                multiline
                name="refferal_note"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.refferal_note || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Advice given"
                margin="dense"
                multiline
                name="advice_given"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.advice_given || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md = {6}
              style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
              xs = {12}
            >
              <TextField
                fullWidth
                label="Improvement"
                margin="dense"
                multiline
                name="improvement"
                onChange={(e) => handleChange(e)}
                type="text"
                value={test_data.improvement || ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showTestDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Add"
            label_loading = "Add"
            loading = {loading}
            onClick = {doAddTest}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TestManage;
