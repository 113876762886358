import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import {Add, Close, CloudUpload, Crop, PhotoCamera } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import FEItem from './Component/FEItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import * as qs from 'query-string';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const FeAuditManage = () => {
  const classes = useStyles();
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);

  const [photo_id, setPhotoID] = useState('old_seal_photo');
  const [fe_dialog_id, setFeDialogID] = useState('Add');
  const [isFeDialog, showFeDialog] = useState(false);
  const [isCameraDialog, showCameraDialog] = useState(false);
  const [isCropDialog, showCropDialog] = useState(false);
  const [cropper, setCropper] = useState('');

  const [cur_fe_audit, setCurFeAudit] = useState({});
  const [fes, setFes] = useState([]);
  const [audits, setAudits] = useState([]);

  const parsed = qs.parse(document.location.search);
  let registered_user = _sessionStorage.getItem('user');

  useEffect(() => {
    get_fes();
    get_audits();
  }, []);

  const get_fes = () => {
    setPageLoad(false);
    _http.doPost('epr/get_fes', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setFes(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const get_audits = () => {
    setPageLoad(false);
    _http.doPost('epr/get_audits', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else 
        {
          let tmp_audits = []
          for(var i = 0; data.data.length != null && i < data.data.length; i ++)
          {
            if(data.data[i].type == 'fe')
            {
              tmp_audits.push(data.data[i]);
            }
          }
          setAudits(tmp_audits);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const delete_fe_audit = () => {
    if(cur_fe_audit == null || cur_fe_audit.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/delete_audit', 
      {
        site_id : parsed.site,
        audit_id : cur_fe_audit.id,
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  
  const addFeAudit = () => {
    if(fe_audit_data.fe_id_no == null || fe_audit_data.fe_id_no == '') {
      alert('please select fire extinguisher which you are going to inspect')
      return;
    }

    let target_fe = {}
    for(var i = 0; i < fes.length; i ++)
    {
      if(fes[i].id_no == fe_audit_data.fe_id_no)
      {
        target_fe = fes[i];
        break;
      }
    }
  
    setLoading(true);
    _http.doPost('epr/add_fe_audit', 
      {
        site_id : parsed.site,
        type : 'fe',
        fe_id : target_fe.id,
        fe_id_no : target_fe.id_no,
        fe_location : target_fe.location,
        fe_type : target_fe.type,
        audit_data : fe_audit_data,
        author : {
          id : registered_user.id,
          name : registered_user.name,
          sign_img : registered_user.sign_img
        }
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showFeDialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const editFeAudit = () => {
    if(cur_fe_audit == null || cur_fe_audit.id == null)
    {
      return;
    }
 
    if(fe_audit_data.fe_id_no == null || fe_audit_data.fe_id_no == '') {
      alert('please select fire extinguisher which you are going to inspect')
      return;
    }

    let target_fe = {}
    for(var i = 0; i < fes.length; i ++)
    {
      if(fes[i].id_no == fe_audit_data.fe_id_no)
      {
        target_fe = fes[i];
        break;
      }
    }
  
    setLoading(true);
    _http.doPost('epr/edit_fe_audit', 
      {
        site_id : parsed.site,
        audit_id : cur_fe_audit.id,
        type : 'fe',
        fe_id : target_fe.id,
        fe_id_no : target_fe.id_no,
        fe_location : target_fe.location,
        fe_type : target_fe.type,
        audit_data : fe_audit_data
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showFeDialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const [fe_audit_data, setFeAuditData] = useState({});
  const handleFeAuditChange = (event) => {
    event.persist();
    setFeAuditData(fe_audit_data => ({
      ...fe_audit_data,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const handleFe_SetPhoto = (photo, type) => {
    if(type == 'old_seal')
    {
      setFeAuditData(fe_audit_data => ({
        ...fe_audit_data,
        old_seal_photo : photo
      }));
    }
    else if(type == 'fe')
    {
      setFeAuditData(fe_audit_data => ({
        ...fe_audit_data,
        fe_photo : photo
      }));
    }
  };

  const handleFeServicedDateChange = (date) => {
    setFeAuditData(fe_audit_data => ({
      ...fe_audit_data,
      date_serviced : date
    }));
  };

  const handleFeNextServiceDateChange = (date) => {
    setFeAuditData(fe_audit_data => ({
      ...fe_audit_data,
      next_service_date : date
    }));
  };

  const handleFeNextTestDateChange = (date) => {
    setFeAuditData(fe_audit_data => ({
      ...fe_audit_data,
      next_test_date : date
    }));
  };

  const handleFeInspectionDateChange = (date) => {
    setFeAuditData(fe_audit_data => ({
      ...fe_audit_data,
      date_inspection : date
    }));
  };

  const getCropData = () => {
    if (cropper != null) {
      handleFe_SetPhoto(cropper.getCroppedCanvas().toDataURL(), photo_id)
    }
  };

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              <Button
                color = "primary"
                onClick = {() => {
                  setFeAuditData({}); setFeDialogID('Add'); showFeDialog(true);
                }}
                startIcon = {<Add />}
                variant = "contained"
              >Add Fire Extinguisher Audit</Button> 
            </Grid>
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              <Card style = {{width : '100%'}}>
                <CardContent style = {{padding : 8}}>
                  <Grid
                    container
                    item
                    lg={12}
                    md = {12}
                    sm = {12}
                  >
                    <Grid
                      item
                      lg={3}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Type</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Identification No</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Location</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Created Date</Grid>
                    <Grid
                      item
                      lg={3}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Action</Grid>
                  </Grid>
                </CardContent>
              </Card>
              {
                audits.map((audit, index) => (
                  <FEItem
                    fe_audit_data = {audit} 
                    onDelete = {(audit_item) => {
                      setCurFeAudit(audit_item);
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(audit_item) => {
                      setCurFeAudit(audit_item);
                      setFeAuditData(audit_item.audit_data);
                      setFeDialogID('Edit');
                      showFeDialog(true);
                    }} 
                  />
                ))
              }
            </Grid>
          </Grid>
      } 


      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showCameraDialog(false)}
        open={isCameraDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          <Camera  
            handleTakePhoto = {(data) => {
              handleFe_SetPhoto(data, photo_id); 
              showCameraDialog(false);
            }}
            handleTakePhotoAnimationDone  = {(data) => {
              handleFe_SetPhoto(data, photo_id); 
              showCameraDialog(false);
            }}
          />    
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showCropDialog(false)}
        open={isCropDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
            <Button 
              onClick = {() => {
                showCropDialog(false);
                getCropData();
              }}
              startIcon = {<Crop />}
              variant = "outlined"
            > Crop</Button>
          </div>
          <Cropper
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            guides
            initialAspectRatio={1}
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            responsive
            src={photo_id == 'old_seal' ? fe_audit_data.old_seal_photo : fe_audit_data.fe_photo}
            style={{ height: 400, width: '100%' }} // https://github.com/fengyuanchen/cropperjs/issues/671
            viewMode={1}
          />   
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown 
        onEntered = {() => {}}
        open={isFeDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>{fe_dialog_id} check list of fire extinguisher</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{marginTop : 20}}
              xs = {12}
            >
              <FormControl
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-age-native-simple">Select a registered Fire Extinguisher</InputLabel>
                <Select
                  inputProps={{
                    name: 'fe_id_no',
                    id: 'outlined-age-native-simple',
                  }} 
                  label="Select a registered Fire Extinguisher"
                  native
                  onChange={(e) => handleFeAuditChange(e)}
                  value={fe_audit_data.fe_id_no}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    fes.map((fe) => (
                      <option value={fe.id_no}>{fe.id_no}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
              
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Old Seal Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleFe_SetPhoto('', 'old_seal')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fe_audit_data.old_seal_photo == null || fe_audit_data.old_seal_photo == '' ?
                        null : <img
                          src = {fe_audit_data.old_seal_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Fire Extinguisher Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('fe')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('fe')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleFe_SetPhoto('', 'fe')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fe_audit_data.fe_photo == null || fe_audit_data.fe_photo == '' ?
                        null : <img
                          src = {fe_audit_data.fe_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Access to, or visibility of, the fire extinguisher is not obstructed</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="access_visible"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.access_visible}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Safe"
                      value="Safe"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Unsafe"
                      value="Unsafe"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>The operating instructions on the fire extinguisher are legible and facing outward</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="operating_instruction"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.operating_instruction}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Safe"
                      value="Safe"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Unsafe"
                      value="Unsafe"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Has the fire extinguisher been actuated or tampered with?</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="actuated"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.actuated}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Safe"
                      value="Safe"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Unsafe"
                      value="Unsafe"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Visually examine externally. Check that there is no obvious physical damage or condition that would prevent operation</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="physical_damage"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.physical_damage}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Safe"
                      value="Safe"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Unsafe"
                      value="Unsafe"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Still within service date?</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="within_service_date"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.within_service_date}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Safe"
                      value="Safe"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Unsafe"
                      value="Unsafe"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Still within Pressure Test Date?</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="within_test_date"
                    onChange={(e) => handleFeAuditChange(e)}
                    style = {{display : 'block'}}
                    value={fe_audit_data.within_test_date}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Additional notes / remarks?</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={12}
                  md = {12}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    multiline
                    name="additional_note"
                    onChange={(e) => handleFeAuditChange(e)}
                    size = "medium"
                    value={fe_audit_data.additional_note || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </fieldset> 
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Date Serviced</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={12}
                  md = {12}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleFeServicedDateChange(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={
                      fe_audit_data.date_serviced == null ?
                        null : new Date(fe_audit_data.date_serviced)}
                  />
                </Grid>
              </Grid>
            </fieldset>  
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Next Service</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={12}
                  md = {12}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleFeNextServiceDateChange(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={
                      fe_audit_data.next_service_date == null ?
                        null : new Date(fe_audit_data.next_service_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>  
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Next Pressure Test Date</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={12}
                  md = {12}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleFeNextTestDateChange(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={
                      fe_audit_data.next_test_date == null ?
                        null : new Date(fe_audit_data.next_test_date)}
                  />
                </Grid>
              </Grid>
            </fieldset> 
            {/* <fieldset style = {{width  : '100%', marginTop : 20}}>
                <legend><span style = {{fontSize : 14, padding : 4}}>Date of Inspection</span></legend>
                <Grid container lg={12} md = {12} xs = {12}>
                  <Grid item lg={12} md = {12} xs = {12} style = {{padding : 10, paddingRight : 8}}>
                    <DatePicker dateFormat="dd/MM/yyyy" closeOnScroll={true}
                      placeholderText = "dd/mm/yyyy"
                      selected={
                        fe_audit_data.date_inspection == null ?
                        null : new Date(fe_audit_data.date_inspection)}
                      onChange={date => handleFeInspectionDateChange(date)}
                    />
                  </Grid>
                </Grid>
              </fieldset>    */}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showFeDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = {fe_dialog_id}
            label_loading = {fe_dialog_id}
            loading = {loading}
            onClick = {() =>{
              if(fe_dialog_id == 'Add')
              {
                addFeAudit();
              }
              else{
                editFeAudit();
              }
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this FE ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {delete_fe_audit}
          />
        </DialogActions>
      </Dialog>
      
    </div>
  );
};

export default FeAuditManage;
