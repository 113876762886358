import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  TextField,
  Collapse, 
  Drawer,
  DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, 
  InputLabel, FormControl, Divider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Add, ArrowDropDown, ArrowDropUp, MoreHoriz, Visibility} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    // height: '100%',
    marginBottom : 70,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  grid: {
    // height: '100%'
  },
  contentContainer: {},
  content: {
    // height: '100%',
    overflowY: 'auto'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 35,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  button: {
    paddingTop : 0,
    paddingBottom : 0,
    textTransform: 'none',
  },
  avatar: {
    width: 120,
    height: 120
  },
  formControl: {
    minWidth: 150,
  },
  tablecell : {
    padding : 0,
    paddingTop : 6,
    paddingBottom : 6
  },
  columnSesizer : {
    width : 2
  },
  drawer: {
    width: '36vw',
    minWidth : drawerWidth,
    flexShrink: 0,
    height : '100%',
    overflow : 'hidden'
  },
  drawerPaper: {
    width: '36vw',
    minWidth : drawerWidth,
    overflow : 'hidden',
    boxShadow: '3px 3px 82px 14px rgba(32, 33, 36, .28)'
  },
  drawerContainer: {
    overflow: 'auto',
  },
  task_item_preview : {
    // minHeight : 40,
    minWidth : 200,
    backgroundColor : '#fff',
    color : '#000',
    fontSize : 14,
    padding : 5,
    fontWeight : 'bold',
    // borderColor : '#333',
    // borderWidth : 1,
    // borderStyle : 'solid',
    borderRadius : 5,
    opacity : 1,
    boxShadow: '3px 3px 4px 4px rgba(32, 33, 36, .28)',
    zIndex : 1000
  }
}));



const ListView = props => {
  const { history } = props;

  const classes = useStyles();
 
  const [loading, setLoading] = useState(false);
  const [delete_loading, setDeleteLoading] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);
  const [prf_list, setPrfList] = useState([]);
  const [cur_prf_id, setPrfID] = useState('');
  const [isDeleteDialog, showDeleteDialog] = useState(false);
  
  let registered_user = _sessionStorage.getItem('user');

  useEffect(() => {
    get_auth_status();
    get_prfs();
  }, []);

  const get_auth_status = async () => {
    setPageLoad(true);
  }

  const get_prfs = () => {
    setLoading(true);
    _http.doPost('main/get_prfs', 
      { 
        user_id : registered_user.id, 
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          console.log('main/get_prfs', data.data);
          alert(data.data);
        }
        else
        {
          
          setPrfList(data.data);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        get_prfs();
        // alert('Error occured');
      }
    );
  }

  const doDelete = () => {
    setDeleteLoading(true);
    _http.doPost('main/delete_prf', 
      { 
        user_id : registered_user.id,
        prf_id : cur_prf_id
      }, 
      (data) => {
        setDeleteLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_prfs();
          alert('success');
        }
      },
      (err) => {
        setDeleteLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const goToView = (prf_data) => {
    document.location.href = '/view_prf?prf=' + prf_data.id;
  }

  const goToEdit = (prf_data) => {
    document.location.href = '/edit_prf?prf=' + prf_data.id;
  }


  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
        style = {{justifyContent : 'center'}}
      >
        <Grid
          container
          item
          lg={12}
          md = {12}
          sm = {12}
          style = {{display : 'flex', justifyContent : 'center', paddingTop : 30 }}
        >
          {
            loading == true ?
              <PageLoading />
              :
              <Table
                border = {1}
                borderColor="#eee"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className = {classes.tablecell}
                      style = {{paddingLeft : 6}}
                    >ID</TableCell>
                    <TableCell
                      className = {classes.tablecell}
                      style = {{paddingLeft : 6}}
                    >Patient Name</TableCell>
                    <TableCell
                      className = {classes.tablecell}
                      style = {{paddingLeft : 6}}
                    >Date</TableCell>
                    <TableCell
                      className = {classes.tablecell}
                      style = {{paddingLeft : 6}}
                    >Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {prf_list.map((prf_data, index) => (
                    <TableRow
                      className={classes.tableRow}
                      key = {index}
                      style = {{backgroundColor : prf_data.sent_medical_aid != true ? 'white' : 'lightgreen'}}
                    >
                      <TableCell
                        className = {classes.tablecell}
                        style = {{paddingLeft : 6}}
                      >
                        <span   style = {{marginLeft: 5, color : '#23f', textDecorationLine : 'underline'}} >{prf_data.id}</span>
                      </TableCell>
                      <TableCell
                        className = {classes.tablecell}
                        style = {{paddingLeft : 6}}
                      >
                        <div style={{marginLeft : '5px'}}>
                          {(prf_data.patient_details.first_name == null ? '' : prf_data.patient_details.first_name) + ' '
                          + (prf_data.patient_details.last_name == null ? '' : prf_data.patient_details.last_name) }</div>
                      </TableCell>
                      <TableCell
                        className = {classes.tablecell}
                        style = {{paddingLeft : 6}}
                      >
                        {prf_data.createdAt == null ? '' : moment(prf_data.createdAt).format('YYYY-MM-DD')}
                      </TableCell>
                      <TableCell
                        className = {classes.tablecell}
                        style = {{paddingLeft : 6}}
                      >
                        <IconButton
                          color="#151b26"
                          onClick = {() => goToView(prf_data)}
                          style = {{padding : 4}}
                          variant="outlined"
                        >
                          <Visibility style = {{width : 22, height : 22}}/>
                        </IconButton>
                        <IconButton
                          color="#151b26"
                          onClick = {() => goToEdit(prf_data)}
                          style = {{padding : 4}}
                          variant="outlined"
                        >
                          <EditIcon style = {{width : 22, height : 22}}/>
                        </IconButton>
                        {
                          registered_user != null && registered_user.role == 'admin' ?
                            <IconButton
                              color="#151b26"
                              onClick = {() => {
                                setPrfID(prf_data.id);
                                showDeleteDialog(true)
                              }}
                              style = {{padding : 4}}
                              variant="outlined"
                            >
                              <DeleteIcon style = {{width : 22, height : 22}}/>
                            </IconButton>
                            : null
                        }
                          
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          }
        </Grid>
      </Grid>
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this prf ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {delete_loading}
            onClick = {doDelete}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

ListView.propTypes = {
  history: PropTypes.object
};

export default withRouter(ListView);
