import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider } from '@material-ui/core';
import {Add, Close, CloudUpload, PhotoCamera } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import FleetItem from './Component/FleetItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const FleetManageHome = () => {
  const classes = useStyles();
  const vehicle_photo_file_input = useRef(null);
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isCamDialog, showCamDialog] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isAddDialog, showAddDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);
  const [isReportDialog, showReportDialog] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [cur_vehicle, setCurVehicle] = useState({});
  const [vehicle_photo, setVehicleImg] = useState('');
  const [vehicle_name, setName] = useState('');
  const [vehicle_type, setType] = useState('');

  const [ok_km, setOK_km] = useState('');
  const [bad_km, setBad_km] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    get_all_vehicles();
  }, []);

  const get_all_vehicles = () => {
    setPageLoad(false);
    _http.doPost('main/get_vehicles', 
      {}, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setVehicles(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const doAddVehicle = () => {
    setLoading(true);
    _http.doPost('main/add_vehicle', 
      {
        name : vehicle_name,
        type: vehicle_type,
        photo : vehicle_photo,
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showAddDialog(false);
          get_all_vehicles();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const doEditVehicle = () => {
    if(cur_vehicle == null || cur_vehicle.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('main/edit_vehicle', 
      {
        vehicle_id : cur_vehicle.id,
        name : vehicle_name,
        type: vehicle_type,
        photo : vehicle_photo,
      }, 
      (data) => {
        
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showEditDialog(false);
          get_all_vehicles();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const doDeleteVehicle = () => {
    if(cur_vehicle == null || cur_vehicle.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('main/delete_vehicle', 
      {
        vehicle_id : cur_vehicle.id,
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_all_vehicles();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  
  const getReport =  () => {
    // console.log(startDate, endDate, )
    if((endDate - startDate) < 0 )
    {
      setOK_km('0');
      setBad_km('0');
      return;
    }
    let from_str = moment(startDate).format('YYYY-MM-DD')
    let to_str = moment(endDate).format('YYYY-MM-DD')
    
    let from_date = new Date(from_str);
    let to_date = new Date(to_str);
    if(cur_vehicle == null || cur_vehicle.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('main/get_report_vehicle', 
      {
        vehicle_id : cur_vehicle.id,
        from : from_date.getTime(), 
        to : to_date.getTime() + 86400000 // 1 day plus
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setOK_km(data.data.ok_km);
          setBad_km(data.data.bad_km);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const _handleImageChange = (e) => {
    e.preventDefault();
    try
    {
      let reader = new FileReader();
      let file = e.target.files[0];
    
      reader.onloadend = () => {
        // setAvatarForm(file);
        setVehicleImg(reader.result);
      }
        
      reader.readAsDataURL(file)
    }
    catch(err)
    {
      console.log('handle image error');
    }
  }

  // console.log(vehicles)
  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              <Button
                color = "primary"
                onClick = {() => {
                  setName(''); setType(''); setVehicleImg(''); showAddDialog(true);
                }}
                startIcon = {<Add />}
                variant = "contained"
              >Add Vehicle</Button> 
            </Grid>
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              {
                vehicles.map((vehicle, index) => (
                  <FleetItem
                    onDelete = {(vehicle_item) => {
                      setCurVehicle(vehicle_item);
                      setName(vehicle_item.name); setType(vehicle_item.type); setVehicleImg(vehicle_item.photo);
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(vehicle_item) => {
                      setCurVehicle(vehicle_item);
                      setName(vehicle_item.name); setType(vehicle_item.type); setVehicleImg(vehicle_item.photo);
                      showEditDialog(true)
                    }} 
                    onReport = {(vehicle_item) => {
                      setOK_km('');
                      setBad_km('');
                      setStartDate(new Date());
                      setEndDate(new Date());
                      setCurVehicle(vehicle_item);
                      showReportDialog(true)
                    }} 
                    vehicle_data = {vehicle}
                  />
                ))
              }
            </Grid>
          </Grid>
      } 

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showAddDialog(false)}
        onEntered = {() => {
        }}
        open={isAddDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Add details of vehicle.</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Vehicle Name"
            margin="dense"
            onChange = {event => setName(event.target.value)}
            value = {vehicle_name}
          />
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel htmlFor="outlined-age-native-simple">Vehicle Type</InputLabel>
            <Select
              label="Vehicle Type"
              native
              onChange={(e) => setType(e.target.value)}
              value={vehicle_type}
            >
              <option
                aria-label="None"
                value=""
              />
              { 
                ['Ambulance', 'Response Vehicle', 'Events Vehicle'].map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Select>
          </FormControl>
          <Grid
            item
            lg={12}
            md = {12}
            style = {{padding : 3}}
            xs = {12}
          >
            <div style = {{display : 'flex', alignItems : 'center'}}>
              <span style = {{fontSize : 14, fontWeight : 'bold'}}>Vehicle Photo</span>
              <input
                accept="image/*"
                hidden
                onChange={(e)=>_handleImageChange(e, false)} 
                ref = {vehicle_photo_file_input}
                type="file"
              />
              <IconButton onClick = {() => showCamDialog(true)}><PhotoCamera /></IconButton>
              <IconButton
                onClick = {() => { 
                  if(vehicle_photo_file_input != null) {
                    vehicle_photo_file_input.current.click();
                  }}}
              >
                <CloudUpload />
              </IconButton>
              <IconButton onClick = {() => setVehicleImg('')}><Close /></IconButton>
            </div>
            <div style = {{display : 'flex', alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
              {
                vehicle_photo == null || vehicle_photo == '' ?
                  null : <img
                    src = {vehicle_photo}
                    style = {{ height : '250px', width : '100%', objectFit : 'contain'}}
                         />
              }
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showAddDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Add Vehicle"
            label_loading = "Add Vehicle"
            loading = {loading}
            onClick = {doAddVehicle}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered = {() => {
        }}
        open={isEditDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Edit details of vehicle.</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Vehicle Name"
            margin="dense"
            onChange = {event => setName(event.target.value)}
            value = {vehicle_name}
          />
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel htmlFor="outlined-age-native-simple">Vehicle Type</InputLabel>
            <Select
              label="Vehicle Type"
              native
              onChange={(e) => setType(e.target.value)}
              value={vehicle_type}
            >
              <option
                aria-label="None"
                value=""
              />
              { 
                ['Ambulance', 'Response Vehicle', 'Events Vehicle'].map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Select>
          </FormControl>
          <Grid
            item
            lg={12}
            md = {12}
            style = {{padding : 3}}
            xs = {12}
          >
            <div style = {{display : 'flex', alignItems : 'center'}}>
              <span style = {{fontSize : 14, fontWeight : 'bold'}}>Vehicle Photo</span>
              <input
                accept="image/*"
                hidden
                onChange={(e)=>_handleImageChange(e, false)} 
                ref = {vehicle_photo_file_input}
                type="file"
              />
              <IconButton onClick = {() => showCamDialog(true)}><PhotoCamera /></IconButton>
              <IconButton
                onClick = {() => { 
                  if(vehicle_photo_file_input != null) {
                    vehicle_photo_file_input.current.click();
                  }}}
              >
                <CloudUpload />
              </IconButton>
              <IconButton onClick = {() => setVehicleImg('')}><Close /></IconButton>
            </div>
            <div style = {{display : 'flex', alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
              {
                vehicle_photo == null || vehicle_photo == '' ?
                  null : <img
                    src = {vehicle_photo}
                    style = {{ height : '250px', width : '100%', objectFit : 'contain'}}
                         />
              }
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showEditDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Update"
            label_loading = "Update"
            loading = {loading}
            onClick = {doEditVehicle}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this vehicle ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {doDeleteVehicle}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showReportDialog(false)}
        open={isReportDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Report</span>
        </DialogTitle>
        <Divider />
        <DialogContent
          className = {classes.dialog}
          style = {{minHeight : '400px'}}
        >
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              containter
              item
              lg={6}
              md = {6}
              xs = {12}
            >
              <Grid
                containter
                item
                lg={3}
                md = {3}
                xs = {12}
              >
                <div style = {{width : 60}}>from : </div>
              </Grid>
              <Grid
                containter
                item
                lg={9}
                md = {9}
                xs = {12}
              >
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  endDate={endDate}
                  onChange={date => setStartDate(date)}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                />
              </Grid>
            </Grid>
            <Grid
              containter
              item
              lg={6}
              md = {6}
              xs = {12}
            >
              <Grid
                containter
                item
                lg={3}
                md = {3}
                xs = {12}
              >
                <div style = {{width : 60}}>to : </div>
              </Grid>
              <Grid
                containter
                item
                lg={9}
                md = {9}
                xs = {12}
              >
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  endDate={endDate}
                  minDate={startDate}
                  onChange={date => setEndDate(date)}
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                /> 
              </Grid>
            </Grid>
          </Grid>
          <Divider style = {{marginTop : 10, marginBottom : 5}}/>
          <Grid
            containter
            item
            lg={12}
            md = {12}
            style = {{marginTop : 10, marginBottom : 5}}
            xs = {12}
          >
            <span style = {{fontSize : 14, fontWeight : 'bold'}} >TOTAL KM TRAVELLED FOR CALLS : 
              <span style = {{fontSize : 14, fontWeight : 'bold', color : 'blue'}} > {ok_km} </span>km
            </span> 
          </Grid>
          <Grid
            containter
            item
            lg={12}
            md = {12}
            style = {{marginTop : 10, marginBottom : 5}}
            xs = {12}
          >
            <span style = {{fontSize : 14, fontWeight : 'bold'}} >TOTAL KM TRAVELLED OUTSIDE OF CALLS : 
              <span style = {{fontSize : 14, fontWeight : 'bold', color : 'blue'}} > {bad_km} </span>km
            </span> 
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showReportDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Get"
            label_loading = "Get"
            loading = {loading}
            onClick = {getReport}
          />
        </DialogActions>
      </Dialog>
        
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showCamDialog(false)}
        onClose={() => showCamDialog(false)}
        open={isCamDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          <Camera
            handleTakePhoto = {(data) => {setVehicleImg(data); showCamDialog(false);}}
            handleTakePhotoAnimationDone  = {(data) => {setVehicleImg(data); showCamDialog(false);}}
          />    
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FleetManageHome;
