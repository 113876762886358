import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Checkbox,
  Select,
  DialogActions,
  Divider,
  Breadcrumbs,
  Link,
  FormControlLabel
} from '@material-ui/core';
import {
  Add,
  Close,
  CloudUpload,
  PhotoCamera,
  ArrowBack,
  MailOutline
} from '@material-ui/icons';
import {
  config,
  _http,
  _sessionStorage,
  _auth_check,
  _crypto,
  _localStorage,
  getInitials
} from 'helpers';
import { PageLoading, LoadingButton, Camera } from 'components';
import PatientItem from './Component/PatientItem';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%'
  },
  dialog: {
    alignItems: 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const PatientManage = () => {
  const classes = useStyles();
  const vehicle_photo_file_input = useRef(null);

  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);
  const [isTestDialog, showTestDialog] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isAddDialog, showAddDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);

  const [cur_patient, setCurPatient] = useState({});
  const [site_name, setSiteName] = useState('');

  const [patients, setPatients] = useState([]);
  const [patient_name, setPatientName] = useState('');
  const [patient_surname, setPatientSurName] = useState('');
  const [address, setPatientAddress] = useState('');
  const [phone, setPatientPhone] = useState('');
  const [id_no, setPatientID] = useState('');
  const [patient_unfit, setPatientUnfit] = useState(false);

  const [consulatDate, setConsultDate] = useState(new Date());

  const parsed = qs.parse(document.location.search);
  let site_id = parsed.site;

  useEffect(() => {
    get_site_data();
    get_patients();
  }, []);

  const get_site_data = () => {
    _http.doPost(
      'cwr/get_cwr_site_data',
      {
        site_id: site_id
      },
      data => {
        if (data.status == false) {
          alert(data.data);
        } else {
          setSiteName(data.data.site_name);
        }
      },
      err => {
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const get_patients = () => {
    setPageLoad(false);
    _http.doPost(
      'cwr/get_cwr_patients',
      {
        site_id: site_id
      },
      data => {
        setPageLoad(true);
        if (data.status == false) {
          alert(data.data);
        } else {
          setPatients(data.data);
        }
      },
      err => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const add_patient = () => {
    setLoading(true);
    _http.doPost(
      'cwr/add_cwr_patient',
      {
        site_id: site_id,
        patient_name: patient_name,
        patient_surname: patient_surname,
        address: address,
        phone: phone,
        id_no: id_no,
        patient_unfit: patient_unfit
      },
      data => {
        setLoading(false);

        if (data.status == false) {
          alert(data.data);
        } else {
          showAddDialog(false);
          get_patients();
        }
      },
      err => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const update_patient = () => {
    if (cur_patient == null || cur_patient.id == null) {
      return;
    }
    setLoading(true);
    _http.doPost(
      'cwr/update_cwr_patient',
      {
        site_id: site_id,
        patient_id: cur_patient.id,
        patient_name: patient_name,
        patient_surname: patient_surname,
        address: address,
        phone: phone,
        id_no: id_no,
        patient_unfit: patient_unfit
      },
      data => {
        setLoading(false);
        if (data.status == false) {
          alert(data.data);
        } else {
          showEditDialog(false);
          get_patients();
        }
      },
      err => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const delete_patient = () => {
    if (cur_patient == null || cur_patient.id == null) {
      return;
    }
    setLoading(true);
    _http.doPost(
      'cwr/delete_cwr_patient',
      {
        site_id: site_id,
        patient_id: cur_patient.id
      },
      data => {
        setLoading(false);
        if (data.status == false) {
          alert(data.data);
        } else {
          showDeleteDialog(false);
          get_patients();
        }
      },
      err => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const doAddTest = () => {
    if (cur_patient == null || cur_patient.id == null) {
      return;
    }
    setLoading(true);
    _http.doPost(
      'cwr/add_test',
      {
        site_id: site_id,
        patient_id: cur_patient.id,
        consult_date: consulatDate.getTime(),
        test_data: test_data
      },
      data => {
        setLoading(false);
        if (data.status == false) {
          alert(data.data);
        } else {
          alert('Success');
          showTestDialog(false);
        }
      },
      err => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  };

  const [test_data, setTestData] = useState({});
  const handleChange = event => {
    event.persist();
    setTestData(test_data => ({
      ...test_data,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  return (
    <div className={classes.root}>
      {pageLoaded == false ? (
        <PageLoading />
      ) : (
        <Grid container lg={12} md={12} xs={12}>
          <Grid
            container
            item
            lg={12}
            md={12}
            style={{ marginBottom: 8 }}
            xs={12}>
            <Grid
              container
              item
              lg={8}
              md={7}
              style={{ alignItems: 'center' }}
              xs={12}>
              <IconButton
                onClick={() => {
                  document.location.href = '/cwr_form';
                }}
                variant="contained">
                <ArrowBack />
              </IconButton>
              <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 10 }}>
                <Link color="inherit">{site_name}</Link>
                {/* <Link color="inherit" >
                    patients
                  </Link> */}
              </Breadcrumbs>
            </Grid>
            <Grid container item lg={4} md={5} xs={12}>
              <Button
                color="primary"
                onClick={() => {
                  setPatientName('');
                  setPatientSurName('');
                  setPatientAddress('');
                  setPatientID('');
                  setPatientPhone('');
                  setPatientUnfit('');
                  showAddDialog(true);
                }}
                startIcon={<Add />}
                style={{ height: 40 }}
                variant="contained">
                Add Patient
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  document.location.href = '/cwr_form/report?site=' + site_id;
                }}
                startIcon={<MailOutline />}
                style={{ height: 40, marginLeft: 10 }}
                variant="contained">
                Preview REPORT
              </Button>
            </Grid>
          </Grid>

          <Grid container lg={12} md={12} xs={12}>
            <Card style={{ width: '100%' }}>
              <CardContent style={{ padding: 8 }}>
                <Grid container item lg={12} md={12} sm={12}>
                  <Grid
                    item
                    lg={2}
                    md={2}
                    style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
                    xs={12}>
                    Patient Name & Surname
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={2}
                    style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
                    xs={12}>
                    Address
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={5}
                    style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
                    xs={12}>
                    Phone Number
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={3}
                    style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
                    xs={12}>
                    ID No
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={3}
                    style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
                    xs={12}>
                    Action
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {patients.map((patient, index) => (
              <PatientItem
                onAddTest={patient_item => {
                  setCurPatient(patient_item);
                  setTestData({});
                  showTestDialog(true);
                }}
                onDelete={patient_item => {
                  setCurPatient(patient_item);
                  setPatientName(patient_item.patient_name);
                  setPatientSurName(patient_item.patient_surname);
                  setPatientAddress(patient_item.address);
                  setPatientID(patient_item.id_no);
                  setPatientPhone(patient_item.phone);
                  showDeleteDialog(true);
                }}
                onEdit={patient_item => {
                  setCurPatient(patient_item);
                  setPatientName(patient_item.patient_name);
                  setPatientSurName(patient_item.patient_surname);
                  setPatientAddress(patient_item.address);
                  setPatientID(patient_item.id_no);
                  setPatientPhone(patient_item.phone);
                  setPatientUnfit(patient_item.patient_unfit);
                  showEditDialog(true);
                }}
                onGoHistory={patient_item => {
                  document.location.href =
                    '/cwr_form/patient/tests?site=' +
                    site_id +
                    '&patient=' +
                    patient_item.id;
                }}
                patient_data={patient}
              />
            ))}
          </Grid>
        </Grid>
      )}

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showAddDialog(false)}
        onEntered={() => {}}
        open={isAddDialog}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>Add details of patient.</span>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Name"
            margin="dense"
            onChange={event => setPatientName(event.target.value)}
            value={patient_name}
          />
          <TextField
            fullWidth
            label="Surname"
            margin="dense"
            onChange={event => setPatientSurName(event.target.value)}
            value={patient_surname}
          />
          <TextField
            fullWidth
            label="Address"
            margin="dense"
            onChange={event => setPatientAddress(event.target.value)}
            value={address}
          />
          <TextField
            fullWidth
            label="Cellphone No"
            margin="dense"
            onChange={event => setPatientPhone(event.target.value)}
            value={phone}
          />
          <TextField
            fullWidth
            label="ID No"
            margin="dense"
            onChange={event => setPatientID(event.target.value)}
            value={id_no}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" onClick={() => showAddDialog(false)}>
            Close
          </Button>
          <LoadingButton
            label="Add Patient"
            label_loading="Add Patient"
            loading={loading}
            onClick={add_patient}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered={() => {}}
        open={isEditDialog}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>Edit details of patient.</span>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="Name"
            margin="dense"
            onChange={event => setPatientName(event.target.value)}
            value={patient_name}
          />
          <TextField
            fullWidth
            label="Surname"
            margin="dense"
            onChange={event => setPatientSurName(event.target.value)}
            value={patient_surname}
          />
          <TextField
            fullWidth
            label="Address"
            margin="dense"
            onChange={event => setPatientAddress(event.target.value)}
            value={address}
          />
          <TextField
            fullWidth
            label="Cellphone No"
            margin="dense"
            onChange={event => setPatientPhone(event.target.value)}
            value={phone}
          />
          <TextField
            fullWidth
            label="ID No"
            margin="dense"
            onChange={event => setPatientID(event.target.value)}
            value={id_no}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" onClick={() => showEditDialog(false)}>
            Close
          </Button>
          <LoadingButton
            label="Update"
            label_loading="Update"
            loading={loading}
            onClick={update_patient}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>
            Are you sure to delete this patient ?
          </span>
        </DialogTitle>
        <DialogContent className={classes.dialog} />
        <DialogActions>
          <Button color="primary" onClick={() => showDeleteDialog(false)}>
            Close
          </Button>
          <LoadingButton
            label="Delete"
            label_loading="Delete"
            loading={loading}
            onClick={delete_patient}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showTestDialog(false)}
        open={isTestDialog}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 'bold' }}>
            Test Form : {cur_patient.patient_name} {cur_patient.patient_surname}
          </span>
        </DialogTitle>
        <Divider />
        <DialogContent
          className={classes.dialog}
          style={{ minHeight: '400px' }}>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={4}
              md={4}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              Consultation date :{' '}
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <DatePicker
                closeOnScroll
                onChange={date => setConsultDate(date)}
                selected={consulatDate}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Age"
                margin="dense"
                name="patient_age"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.patient_age || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Chronic Conditions"
                margin="dense"
                name="chronic_conditions"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.chronic_conditions || ''}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Chronic Medication"
                margin="dense"
                name="chronic_medication"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.chronic_medication || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Smoker"
                margin="dense"
                name="smoker"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.smoker || ''}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Weight"
                margin="dense"
                name="weight"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.weight || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Height"
                margin="dense"
                name="height"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.height || ''}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Hypertension"
                margin="dense"
                name="blood_pressure"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.blood_pressure || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Pulse Rate"
                margin="dense"
                name="pulse_rate"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.pulse_rate || ''}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Blood Glucose"
                margin="dense"
                name="blood_glucose"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.blood_glucose || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="HIV Test"
                margin="dense"
                name="hiv_test"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.hiv_test || ''}
              />
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Drug Test"
                margin="dense"
                name="drug_test"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.drug_test || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Advice given"
                margin="dense"
                multiline
                name="advice_given"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.advice_given || ''}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}
              xs={12}>
              <TextField
                fullWidth
                label="Improvement"
                margin="dense"
                multiline
                name="improvement"
                onChange={e => handleChange(e)}
                type="text"
                value={test_data.improvement || ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" onClick={() => showTestDialog(false)}>
            Close
          </Button>
          <LoadingButton
            label="Add"
            label_loading="Add"
            loading={loading}
            onClick={doAddTest}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientManage;
