import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GroupAdd, Add, Close, ArrowBack, CheckBoxOutlineBlank, CheckBox, ExpandMore, CloudUpload, PhotoCamera} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import SignatureCanvas from 'react-signature-canvas'
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  table_font : {
    fontSize : '14px !important',
    
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      fontSize : '10px !important'
    }
  },
  table1 : {
    fontSize: '60%',
    lineHeight: '10px',
  },
  license_img_wrapper_pc : {
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      display : 'none !important',
    }
  },
  license_img_wrapper_phone : {
    [theme.breakpoints != null && theme.breakpoints.up('md')]: {
      display : 'none',
    }
  }
}));

const AcceptForm = props => {
  const { history, prfData, fontSize } = props;
  const classes = useStyles();
  const [prf_Data, setPrfData] = useState({
    tangos : {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details : {},
    patient_history : {},
    chronic_conditions : {},
    haemorrhage : {},
    airway : {},
    breathing : {},
    circulation : {},
    immobilisation : {},
    mornitoring : {},
    iv_access1 : {},
    iv_access2 : {},
    medication_slots : {},
    vital_1 : {},
    vital_2 : {},
    vital_3 : {},
    vital_4 : {},
    vital_5 : {},
    vital_6 : {},
    patient_consent : {},
    signature : {
      patient : 'patient_signature',
      hospital : 'hospital_signature'
    },
    license_img : {
      driver : 'driver_photo',
      hospital : 'hospital_photo'
    },
    
  });

  useEffect(()=>{
    setPrfData(prfData);
  }, [prfData])

  let vehicle_info = prf_Data.call_information == null || prf_Data.call_information.vehicle == null ? '' : prf_Data.call_information.vehicle;
  let tmp_vehicle_infos = vehicle_info.split('_@@==@@_');

  return (
    <Grid
      className = {classes.table_font}
      container
      item
      lg={12}
      md = {12}
      sm = {12}
      style = {{width : '100%', padding : '10px', paddingTop : 20}}
    >
      <div
        id="print1"
        style = {{width : '100%'}}
      >
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                align = "left"
                width="33%"
              >
                <img
                  src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                  width="80px"
                />
              </td>
              <td
                style= {{textAlign: 'right', }}
                width="62%"
              >
                <strong>Legacy Emergency Specialists</strong>
                <br/>
                <strong>Route N4 Business Park, Corridor Crescent</strong>
                <br/>
                <strong>9 Bureau de Paul</strong>
                <br/>
                <strong>Ben Fleur</strong>
                <br/>
                <strong>Witbank</strong>
                <br/>
                <strong>www.legacyems.co.za</strong>
                <br/>
                <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
                <br/>
                <strong>Practice Number: 009 003 064 1332</strong>
                <br/>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td style={{textAlign : 'center'}}><strong>PATIENT PAYMENT AGREEMENT AND INDEMNITY FORM</strong></td></tr></tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderBottomWidth : 0 , borderTopWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td width="50%">
                <strong>I. the undersigned:</strong>
              </td>
            </tr>
            <tr><td
              height = "12"
              width="100%"
            /></tr>
            <tr>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000',  }}
                width="33%"
              >
                <strong />
              </td>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
                width="33%"
              >
                <strong />
              </td>
              <td
                style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
                width="33%"
              >
                <strong />
              </td>
            </tr>
            <tr>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>Name & Surname of Guarantor/Patient</strong>
              </td>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>ID Number</strong>
              </td>
              <td
                style = {{fontStyle : 'italic' }}
                width="33%"
              >
                <strong>PRF No</strong>
              </td>
            </tr>
            <tr><td
              height = "5"
              width="100%"
            /></tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >1)</td>
              <td valign = {'top'}>Hereby give Legacy Emergency Medical Services (Pty) Ltd with Registration No. 2016/121004/07, trading as Legacy Emergency Medical Services, my consent and permission to transport me to a hospital of my choice, and in the event that the afore-mentioned hospital is not available, any hospital Legacy Emergency Medical Services deems fit.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >2)</td>
              <td valign = {'top'}>I further give Legacy Emergency Medical Services my consent and permission to stabilize me and/or give me life support and/or any other function or support if needed in the circumstances at hand and/or as requested by myself.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >3)</td>
              <td valign = {'top'}>By making use of Legacy Emergency Medical Services, I accept that there are costs and fees involved for provided services. I hereby take full responsibility and liability for the payment of their fees and costs as set out in Annexure “A” below.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >4)</td>
              <td valign = {'top'}>I admit that on rendering of the services, all accounts become immediately due and payable to Legacy Emergency Medical Services.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >5)</td>
              <td valign = {'top'}>I agree to Legacy Emergency Medical Services submitting the invoice (even before I receive an account) on my behalf to my medical aid where applicable. I confirm that I will always be responsible and liable for payment of Legacy Emergency Medical Services account.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >6)</td> 
              <td valign = {'top'}>In the event that legal action commences regarding the non-payment of Legacy Emergency Medical Services fees, I accept that I will be liable for the legal costs on an attorney and client scale, which shall include, but is not limited, to tracing fees and 10% collection commission.</td>
            </tr>
            <tr>
              <td
                style = {{paddingRight: 10}}
                valign = {'top'}
                width = "26"
              >7)</td>
              <td valign = {'top'}>I hereby indemnify Legacy Emergency Medical Services, any of it’s director(s), shareholder(s), employee(s) or agent(s) from any loss and/or damage(s) and/or injury and/or death which I may suffer as a result of any act or omission, whether such loss and/or damage and/or injury and/or death is as a result of the negligence of any person relating to any of the services rendered by Legacy Emergency Medical Services.</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody><tr> <td
            height = "15"
            style={{textAlign : 'left'}}
          ><strong>Annexure A</strong></td></tr></tbody>
        </table>
        <table
          border="1"
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
          width="99%"
        >
          <tbody>
            <tr>
              <td valign = {'top'}><b>Description</b></td>
              <td valign = {'top'}><b>Amount</b></td>
            </tr>
            <tr>
              <td valign = {'top'} >Call Out Fee, no Transport, RHT</td>
              <td valign = {'top'}>R 800.00 </td>
            </tr>
            <tr>
              <td valign = {'top'} >Declaration of Death</td>
              <td valign = {'top'}>R 1,500.00 </td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support up to 60 min</td>
              <td valign = {'top'}>R 3,012.16</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support every 15min thereafter</td>
              <td valign = {'top'}>R 753.88</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 37.66</td>
            </tr>
            <tr>
              <td valign = {'top'} >Basic Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 13.50</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support up to 60 min</td>
              <td valign = {'top'}>R 4,070.45</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support every 15 min thereafter</td>
              <td valign = {'top'}>R 1,017.65</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 50.68</td>
            </tr>
            <tr>
              <td valign = {'top'} >Intermediate Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 14.63</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support up to 60 min</td>
              <td valign = {'top'}>R 5,406.68</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support every 15 min thereafter</td>
              <td valign = {'top'}>R 1,764.97</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support above 100km patient in-situ per km</td>
              <td valign = {'top'}>R 78.25</td>
            </tr>
            <tr>
              <td valign = {'top'} >Advanced Life Support above 100km return no patient per km</td>
              <td valign = {'top'}>R 16.31</td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                height = "12"
                style={{textAlign : 'left'}}
              >
                <span>Signed in acceptance at </span>
                <span style = {{minWidth : '50px', display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span> on the  </span>
                <span style = {{minWidth : 20, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span>day of </span><span style = {{minWidth : 50, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}> </span>
                <span> year</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , }}
          width="99%"
        >
          <tbody>
            <tr>
              <td
                height = "12"
                style = {{borderBottomWidth : 1, borderBottomColor : '#000', borderBottomStyle : 'solid', width : '50%'}}
                width = "50%"
              />
              <td />
            </tr>
            <tr>
              <td
                style = {{fontStyle : 'italic'}}
                width = "50%"
              >
                Signature of Guarantor/Patient
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    
    </Grid>
  );
};


export default AcceptForm;
