import firebase from 'firebase/compat/app';
import 'firebase/auth';
// import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDNt10zF0nafM9WYYygOuojGwMVEmECpM0',
  authDomain: 'legacyems-f9cb6.firebaseapp.com',
  databaseURL: 'https://legacyems-f9cb6.firebaseio.com',
  projectId: 'legacyems-f9cb6',
  storageBucket: 'legacyems-f9cb6.appspot.com',
  messagingSenderId: '654593625804',
  appId: '1:654593625804:web:21a780fc371cf08741fc69',
  measurementId: 'G-7Z7CTGWT2P'
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
 
const db = getFirestore(app); // firebase.firestore();
const auth = getAuth(app); // firebase.auth();

export default {db, auth}