import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Grid,
  Button,
  DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, 
  InputLabel, FormControl,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GroupAdd, Add, Close, ArrowBack, CheckBoxOutlineBlank, CheckBox, ExpandMore, CloudUpload, PhotoCamera} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton, PageLoading} from 'components'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import SignatureCanvas from 'react-signature-canvas'
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  table_font : {
    fontSize : 14,
    [theme.breakpoints.down('sm')]: {
      fontSize : '10px'
    }
  },
  table : {
    overflowWrap: 'anywhere',
    tableLayout: 'fixed',
    wordWrap: 'break-word',
  }
}));

const GemsForm = props => {
  const { history, prfData } = props;
  const classes = useStyles();
  const [prf_Data, setPrfData] = useState({
    tangos : {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details : {},
    patient_history : {},
    chronic_conditions : {},
    haemorrhage : {},
    airway : {},
    breathing : {},
    circulation : {},
    immobilisation : {},
    mornitoring : {},
    iv_access1 : {},
    iv_access2 : {},
    medication_slots : {},
    vital_1 : {},
    vital_2 : {},
    vital_3 : {},
    vital_4 : {},
    vital_5 : {},
    vital_6 : {},
    patient_consent : {},
    signature : {
      patient : 'patient_signature',
      hospital : 'hospital_signature'
    },
    license_img : {
      driver : 'driver_photo',
      hospital : 'hospital_photo'
    },
    
  });

  useEffect(()=>{
    setPrfData(prfData);
  }, [prfData])


  return (
    <Grid
      className = {classes.table_font}
      container
      item
      lg={12}
      md = {12}
      sm = {12}
      style = {{width : '100%', padding : '10px', paddingTop : 20}}
    >
          
      <div
        id="print3"
        style = {{width : '100%'}}
      >
        <table
          border="1"
          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
          width="99%"
        >
          <tbody>
            <tr>
              <td width="50%">
                <strong>GEMS Case Submission Sheet</strong>
                <br />
                <strong>Service Provider : Legacy Emergency Specialists (Pty) Ltd</strong>
                <br/>
                <strong>Submission Date : {prf_Data.createdAt == null ? '' : moment(prf_Data.createdAt).format('YYYY-MM-DD')}</strong>
                <br/>
                <strong>Date : {prf_Data.createdAt == null ? '' : moment(prf_Data.createdAt).format('YYYY-MM-DD')}</strong>
              </td>
              <td width="50%">
                <center>
                  <img
                    src="http://www.legacyems.co.za/placeholders/gems.jpg"
                    style = {{maxHeight : '72px', width : '150px', objectFit : 'contain'}}
                  />
                </center>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          className="table1"
          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
          width="99%"
        >
          <tbody>
            <tr>
              <td >
                <strong>PRF / Invoice No</strong>
              </td>
              <td >
                <strong>Date of Service</strong>
              </td>
              <td >
                <strong>Patient</strong>
              </td>
              <td >
                <strong>Age</strong>
              </td>
              <td >
                <strong>Gender</strong>
              </td>
              <td >
                <strong>Patient ID No</strong>
              </td>
              <td >
                <strong>Main Member ID No</strong>
              </td>
              <td >
                <strong>Medical Aid No</strong>
              </td>
              <td >
                <strong>Diagnosis</strong>
              </td>
              <td >
                <strong>Mechanism of Injury (Trauma)</strong>
              </td>
              <td >
                <strong>Pick-up Address</strong>
              </td>
              <td >
                <strong>Drop-off Address</strong>
              </td>
              <td >
                <strong>BLS</strong>
              </td>
              <td >
                <strong>EA Reference</strong>
              </td>
              <td>
                <strong>GEMS Reference</strong>
              </td>
            </tr>
            <tr>
              <td>{prf_Data.id}</td>
              <td>{prf_Data.createdAt == null ? '' : moment(prf_Data.createdAt).format('YYYY-MM-DD')}</td>

              <td>{prf_Data.patient_details.first_name} {prf_Data.patient_details.last_name}</td>

              <td>{prf_Data.patient_details.date_of_birth}</td>

              <td>{prf_Data.patient_details.gender}</td>

              <td>{prf_Data.patient_details.id_no}</td>

              <td>{prf_Data.medical_aid.main_member}</td>

              <td>{prf_Data.medical_aid.medical_aid_no}</td>

              <td>{prf_Data.chronic_conditions.diagnosis}</td>

              <td>{prf_Data.chronic_conditions.moi}</td>

              <td>{prf_Data.call_information.transport_from}</td>

              <td>{prf_Data.call_information.transport_to}</td>

              <td>{prf_Data.call_information.level_of_care}</td>

              <td />
              <td />
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style = {{overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
          width="99%"
        >
          <tbody>
            <tr>
              <td width="50%">
                <strong>Fax No : 086 541 5384</strong>
                <br/>
                <strong>Email Address : admin@legacyems.co.za / gene@legacyems.co.za</strong>
                <br/>
                <strong>SMS No : 072 432 5880</strong>
                <br/>
              </td>
              <td width="50%">
                <center>
                  <strong>Total Number Submitted : 1</strong>
                  <br/>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
               
    </Grid>
  );
};

GemsForm.propTypes = {
  history: PropTypes.object
};

export default withRouter(GemsForm);
