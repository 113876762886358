import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Button, Select, DialogActions
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalActivity from '@material-ui/icons/LocalActivity';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SignatureCanvas from 'react-signature-canvas'
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton} from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  dialog: {
    alignItems : 'flex-start',
    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  sigCanvas : {
    width : '100%',
    height: '250px',
  }
}));

const SiteItem = (props) => {
  const {site_data, onDelete, onEdit, onEquip, onAudit, ...rest} = props;
  const classes = useStyles();
  
  useEffect(() => {
  }, [site_data]);

  return (
    <Grid
      container
      item
      lg={12}
      md = {12}
      xs = {12}
    >
      <Card style = {{width : '100%'}}>
        <CardContent style = {{padding : 8}}>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {site_data.site_name}
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {site_data.contact_no}
            </Grid>
            <Grid
              item
              lg={3}
              md = {3}
              style = {{padding : 5}}
              xs = {12}
            >
              {site_data.contact_name}
            </Grid>
            <Grid
              item
              lg={5}
              md = {5}
              style = {{padding : 10}}
              xs = {12}
            >
              <IconButton
                color="#151b26"
                onClick = {() => onEquip(site_data)}
                style = {{padding : 4}}
                variant="outlined"
              >
                <VisibilityIcon style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 14}}>Equipment</span>
              </IconButton>
              <IconButton
                color="#151b26"
                onClick = {() => onAudit(site_data)}
                style = {{padding : 4}}
                variant="outlined"
              >
                <LocalActivity style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 14}}>Audit</span>
              </IconButton>
              <IconButton
                color="#151b26"
                onClick = {() => onEdit(site_data)}
                style = {{padding : 4}}
                variant="outlined"
              >
                <EditIcon style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 14}}>Edit</span>
              </IconButton>
              <IconButton
                color="#151b26"
                onClick = {() => onDelete(site_data)}
                style = {{padding : 4}}
                variant="outlined"
              >
                <DeleteIcon style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 14}}>Delete</span>
              </IconButton>
            </Grid>
          </Grid>  
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SiteItem;
