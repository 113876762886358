import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GroupAdd,
  Add,
  Close,
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBox,
  ExpandMore,
  CloudUpload,
  PhotoCamera
} from '@material-ui/icons';
import {
  config,
  _http,
  _sessionStorage,
  _auth_check,
  _crypto,
  _localStorage,
  getInitials
} from 'helpers';
import { LoadingButton, PageLoading } from 'components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import SignatureCanvas from 'react-signature-canvas';
import * as qs from 'query-string';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff'
    // height: '100%',
    // marginBottom : 70,
    // paddingLeft: 100,
    // paddingRight: 100,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  },
  grid: {
    height: '100%'
  },
  table_font: {
    fontSize: '14px !important',

    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      fontSize: '10px !important'
    }
  },
  table1: {
    fontSize: '60%',
    lineHeight: '10px'
  },
  license_img_wrapper_pc: {
    [theme.breakpoints != null && theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  license_img_wrapper_phone: {
    [theme.breakpoints != null && theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const NetcareForm = props => {
  const { history, prfData, fontSize } = props;
  const classes = useStyles();
  const [prf_Data, setPrfData] = useState({
    tangos: {},
    call_information: {},
    patient_details: {},
    medical_aid: {},
    crew: [],
    raf_details: {},
    patient_history: {},
    chronic_conditions: {},
    haemorrhage: {},
    airway: {},
    breathing: {},
    circulation: {},
    immobilisation: {},
    mornitoring: {},
    iv_access1: {},
    iv_access2: {},
    medication_slots: {},
    vital_1: {},
    vital_2: {},
    vital_3: {},
    vital_4: {},
    vital_5: {},
    vital_6: {},
    patient_consent: {},
    signature: {
      patient: 'patient_signature',
      hospital: 'hospital_signature'
    },
    license_img: {
      driver: 'driver_photo',
      hospital: 'hospital_photo'
    }
  });

  useEffect(() => {
    setPrfData(prfData);
  }, [prfData]);

  let vehicle_info =
    prf_Data.call_information == null ||
    prf_Data.call_information.vehicle == null
      ? ''
      : prf_Data.call_information.vehicle;
  let tmp_vehicle_infos = vehicle_info.split('_@@==@@_');

  return (
    <Grid
      className={classes.table_font}
      container
      item
      lg={12}
      md={12}
      sm={12}
      style={{ width: '100%', padding: '10px', paddingTop: 20 }}>
      <div id="print1" style={{ width: '100%' }}>
        <table
          border="1"
          style={{
            overflowWrap: 'anywhere',
            tableLayout: 'fixed',
            wordWrap: 'break-word'
          }}
          width="99%">
          <tbody>
            <tr>
              <td width="33%">
                <strong>061 9960 911</strong>
                <strong>N4 Business Park, 9 Corridor Crescent </strong>
                <strong>
                  <a href="http://www.legacyems.co.za">www.legacyems.co.za </a>
                </strong>
                <strong>admin@legacyems.co.za</strong>
              </td>
              <td width="33%">
                <center>
                  <img
                    src="http://www.spectiv.co.za/legacy/signatures/legacynewlogo.jpeg"
                    width="80px"
                  />
                </center>
              </td>
              <td style={{ textAlign: 'right' }} width="33%">
                <strong>PRF No:</strong>
                <font color="red" size="3px">
                  {' '}
                  {prf_Data.id}{' '}
                </font>
                <br />
                <strong>Auth No:</strong>
                <br />
                <strong>Practice : 0641332</strong>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style={{
            overflowWrap: 'anywhere',
            tableLayout: 'fixed',
            wordWrap: 'break-word'
          }}
          width="99%">
          <tbody>
            <tr>
              <td width="50%">
                <strong>Report Date :&nbsp;</strong>
              </td>
              <td>
                {prf_Data.createdAt == null
                  ? ''
                  : moment(prf_Data.createdAt).format('YYYY-MM-DD')}
              </td>
            </tr>
            <tr>
              <td width="50%">
                <strong>Reference No :&nbsp;</strong>
              </td>
              <td>{prf_Data.call_information.reference_no}</td>
            </tr>
            <tr>
              <td width="50%">
                <strong>Call Type :&nbsp;</strong>
              </td>
              <td>{prf_Data.call_information.call_type}</td>
            </tr>
            <tr>
              <td width="50%">
                <strong>Vehicle :&nbsp;</strong>
              </td>
              <td>
                {tmp_vehicle_infos.length > 1 ? tmp_vehicle_infos[1] : ''}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Priority :</strong>
              </td>
              <td>{prf_Data.call_information.priority}</td>
            </tr>
            <tr>
              <td>
                <strong>Level of Care :</strong>
              </td>
              <td>{prf_Data.call_information.level_of_care}</td>
            </tr>
            <tr>
              <td>
                <strong>Transport From :</strong>
              </td>
              <td>{prf_Data.call_information.transport_from}</td>
            </tr>
            <tr>
              <td>
                <strong>Transport To :&nbsp;</strong>
              </td>
              <td>{prf_Data.call_information.transport_to}</td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style={{
            overflowWrap: 'anywhere',
            tableLayout: 'fixed',
            wordWrap: 'break-word'
          }}
          width="99%">
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Name &amp; Surname :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.first_name}{' '}
                {prf_Data.patient_details.last_name}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Email :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.email_address}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Gender :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.gender}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>ID No :</strong>
              </td>
              <td style={{ width: '50%' }}>{prf_Data.patient_details.id_no}</td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Physical Address :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.home_address}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Postal Address :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.postal_address}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Date of Birth :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.date_of_birth}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Mobile Number :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.mobile_number}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Telephone Number :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.mobile_number}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Next-of-kin Name &amp; Surname :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.next_of_kin_name}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Next-of-kin contact No&nbsp;:</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.next_of_kin_contact_no}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Employed by :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.employed_by}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>I.O.D No :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.iod_no}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Employee COY No :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.employer_contact_no}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Employer Contact No :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.employer_contact_no}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Name &amp; Surname of person responsible for account :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.first_name_responsible}{' '}
                {prf_Data.patient_details.last_name_responsible}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>ID number of Person Responsible for Payment :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.id_responsible}{' '}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Name and Surname of Practitioner Completing the PRF :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.patient_details.id_practitioner}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style={{
            height: '78px',
            overflowWrap: 'anywhere',
            tableLayout: 'fixed',
            wordWrap: 'break-word'
          }}
          width="99%">
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Medical Aid / Fund / Client :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.medical_aid.medical_aid_name}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Member No :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.medical_aid.medical_aid_no}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Dependent Code :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.medical_aid.dependant_code}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Main Member :</strong>
              </td>
              <td style={{ width: '50%' }}>
                {prf_Data.medical_aid.main_member}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="1"
          style={{
            overflowWrap: 'anywhere',
            tableLayout: 'fixed',
            wordWrap: 'break-word'
          }}
          width="99%">
          <tbody>
            <tr>
              <td width="20%">
                <strong>Tango 1 :</strong>
              </td>
              <td width="20%">
                <strong>Time :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_1_time}</td>
              <td width="20%">
                <strong>Odo :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_1_km}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Tango 2 :</strong>
              </td>
              <td width="20%">
                <strong>Time :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_2_time}</td>
              <td width="20%">
                <strong>Odo :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_2_km}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Tango 3 :</strong>
              </td>
              <td width="20%">
                <strong>Time :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_3_time}</td>
              <td width="20%">
                <strong>Odo :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_3_km}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Tango 4 :</strong>
              </td>
              <td width="20%">
                <strong>Time :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_4_time}</td>
              <td width="20%">
                <strong>Odo :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_4_km}</td>
            </tr>
            <tr>
              <td width="20%">
                <strong>Tango 5 :</strong>
              </td>
              <td width="20%">
                <strong>Time :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_5_time}</td>
              <td width="20%">
                <strong>Odo :</strong>
              </td>
              <td width="20%">{prf_Data.tangos.tango_5_km}</td>
            </tr>
          </tbody>
        </table>
        <div style={{ pageBreakAfter: 'always' }}>
          <table
            border="1"
            style={{
              pageBreakAfter: 'always',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              {[1, 2, 3].map((crew_member, index) => (
                <tr key={index}>
                  <td style={{ width: '25%' }}>
                    <strong>Crew Member {index + 1} :</strong>
                  </td>
                  <td style={{ width: '25%' }}>
                    {index < prf_Data.crew.length
                      ? prf_Data.crew[index].name
                      : ''}
                  </td>
                  <td style={{ width: '25%' }}>
                    {index < prf_Data.crew.length
                      ? prf_Data.crew[index].number
                      : ''}
                  </td>
                  <td style={{ width: '25%' }}>
                    <img
                      height="20px"
                      src={
                        index < prf_Data.crew.length
                          ? prf_Data.crew[index].sign_img
                          : ''
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <div
          style={{
            width: '100%',
            border: 'none',
            borderStyle: 'none',
            marginTop: '10px'
          }}>
          <center>
            <strong>PRF No:</strong>
            <font color="red" size="3px">
              {prf_Data.id}
            </font>
          </center>
          <br />
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <strong>RAF DETAILS</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '59px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ width: '25%' }}>
                  <strong>Vehicle 1&nbsp;Make</strong>
                </td>
                <td style={{ width: '25%' }}>
                  {prf_Data.raf_details.vehicle1_make}
                </td>
                <td style={{ width: '25%' }}>
                  <strong>Vehicle 2&nbsp;Make</strong>
                </td>
                <td style={{ width: '25%' }}>
                  {prf_Data.raf_details.vehicle2_make}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Vehicle 1 Registration</strong>
                </td>
                <td>{prf_Data.raf_details.vehicle1_registration}</td>
                <td>
                  <strong>Vehicle 2&nbsp;Registration</strong>
                </td>
                <td>{prf_Data.raf_details.vehicle2_registration}</td>
              </tr>
              <tr>
                <td>
                  <strong>Vehicle 1 Driver</strong>
                </td>
                <td>{prf_Data.raf_details.vehicle1_driver_name}</td>
                <td>
                  <strong>Vehicle 2&nbsp;Driver</strong>
                </td>
                <td>{prf_Data.raf_details.vehicle2_driver_name}</td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr style={{ height: '22px', width: '100%' }}>
                <td style={{ height: '22px', width: '50%' }}>
                  <strong>SAPS Official on Scene / Station</strong>
                </td>
                <td style={{ height: '22px', width: '50%' }}>
                  {prf_Data.raf_details.vehicle2_saps}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <strong>PATIENT HISTORY</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '116px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>Allergies :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.patient_history.allergies}
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>Chronic Conditions :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.chronic_conditions.asthma == true
                    ? 'Asthma, '
                    : null}
                  {prf_Data.chronic_conditions.diabetes == true
                    ? 'Diabetes, '
                    : null}
                  {prf_Data.chronic_conditions.cardiac == true
                    ? 'Cardiac, '
                    : null}
                  {prf_Data.chronic_conditions.gastrointestinal == true
                    ? 'Gastrointestinal, '
                    : null}
                  {prf_Data.chronic_conditions.nervous == true
                    ? 'Nervous System, '
                    : null}
                  {prf_Data.chronic_conditions.blood_pressure == true
                    ? 'Hypertension, '
                    : null}
                  {prf_Data.chronic_conditions.renal == true ? 'Renal, ' : null}
                  {prf_Data.chronic_conditions.hepatic == true
                    ? 'Hepatic, '
                    : null}
                  {prf_Data.chronic_conditions.other}
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>Chronic Medication :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.patient_history.chronic_medication}
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>Chief Complaint :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.patient_history.chief_complaint}
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>N.O.I / M.O.I :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.chronic_conditions.moi}
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <strong>Diagnosis :</strong>
                </td>
                <td style={{ width: '50%' }}>
                  {prf_Data.chronic_conditions.diagnosis}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ pageBreakAfter: 'always' }}>
            <table
              border="1"
              style={{
                height: '21px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <strong>INTERVENTIONS</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              border="1"
              style={{
                height: '154px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr>
                  <td width="16.66%">
                    <strong />
                  </td>
                  <td width="16.66%">
                    <strong>Type</strong>
                  </td>
                  <td width="16.66%">
                    <strong>Dosage</strong>
                  </td>
                  <td width="16.66%">
                    <strong>Volume</strong>
                  </td>
                  <td width="16.66%">
                    <strong>Treatment</strong>
                  </td>
                  <td width="16.66%">
                    <strong>Treatment Type</strong>
                  </td>
                </tr>
                <tr>
                  <td width="16.66%">
                    <strong>Severe Bleeding</strong>
                  </td>
                  <td width="16.66%">{prf_Data.haemorrhage.type}</td>
                  <td width="16.66%">
                    {prf_Data.haemorrhage.head == true ? 'Head, ' : null}
                    {prf_Data.haemorrhage.chest == true ? 'Chest, ' : null}
                    {prf_Data.haemorrhage.abdomen == true ? 'Abdomen, ' : null}
                    {prf_Data.haemorrhage.pelvis == true ? 'Pelvis, ' : null}
                    {prf_Data.haemorrhage.extremities == true
                      ? 'Extremities, '
                      : null}
                  </td>
                  <td width="16.66%">{prf_Data.haemorrhage.volume}</td>
                  <td width="16.66%">{prf_Data.haemorrhage.pressure}</td>
                  <td width="16.66%">{prf_Data.haemorrhage.dressing}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Airway</strong>
                  </td>
                  <td>{prf_Data.airway.type}</td>
                  <td />
                  <td />
                  <td>{prf_Data.airway.treatment}</td>
                  <td>
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_000 == true
                      ? '000, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_00 == true
                      ? '00, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_0 == true
                      ? '0, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_1 == true
                      ? '1, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_2 == true
                      ? '2, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_3 == true
                      ? '3, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_4 == true
                      ? '4, '
                      : null}
                    {prf_Data.airway.treatment == 'OP Airway' &&
                    prf_Data.airway.op_airway_5 == true
                      ? '5, '
                      : null}

                    {prf_Data.airway.treatment == 'LMA' &&
                    prf_Data.airway.lma_1 == true
                      ? '1, '
                      : null}
                    {prf_Data.airway.treatment == 'LMA' &&
                    prf_Data.airway.lma_2 == true
                      ? '2, '
                      : null}
                    {prf_Data.airway.treatment == 'LMA' &&
                    prf_Data.airway.lma_3 == true
                      ? '3, '
                      : null}
                    {prf_Data.airway.treatment == 'LMA' &&
                    prf_Data.airway.lma_4 == true
                      ? '4, '
                      : null}
                    {prf_Data.airway.treatment == 'LMA' &&
                    prf_Data.airway.lma_5 == true
                      ? '5, '
                      : null}

                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_10 == true
                      ? '1.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_15 == true
                      ? '1.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_20 == true
                      ? '2.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_25 == true
                      ? '2.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_30 == true
                      ? '3.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_35 == true
                      ? '3.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_40 == true
                      ? '4.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_45 == true
                      ? '4.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_50 == true
                      ? '5.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_55 == true
                      ? '5.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_60 == true
                      ? '6.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_65 == true
                      ? '6.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_70 == true
                      ? '7.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_75 == true
                      ? '7.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_80 == true
                      ? '8.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_85 == true
                      ? '8.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_90 == true
                      ? '9.0, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_95 == true
                      ? '9.5, '
                      : null}
                    {prf_Data.airway.treatment == 'ETT' &&
                    prf_Data.airway.ett_100 == true
                      ? '10.0, '
                      : null}

                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_10 == true
                      ? 'ETT 1.0, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_15 == true
                      ? 'ETT 1.5, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_20 == true
                      ? 'ETT 2.0, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_25 == true
                      ? 'ETT 2.5, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_30 == true
                      ? 'ETT 3.0, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_35 == true
                      ? 'ETT 3.5, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_40 == true
                      ? 'ETT 4.0, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_45 == true
                      ? 'ETT 4.5, '
                      : null}
                    {prf_Data.airway.treatment == 'Surgical' &&
                    prf_Data.airway.surgical_50 == true
                      ? 'ETT 5.0, '
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Breathing / Ventilation</strong>
                  </td>
                  <td>{prf_Data.breathing.type}</td>
                  <td>
                    {prf_Data.breathing.type == 'Breathing Difficulty' &&
                    prf_Data.breathing.difficulty_copd == true
                      ? 'COPD, '
                      : null}
                    {prf_Data.breathing.type == 'Breathing Difficulty' &&
                    prf_Data.breathing.difficulty_asthman == true
                      ? 'Asthma, '
                      : null}
                    {prf_Data.breathing.type == 'Breathing Difficulty' &&
                    prf_Data.breathing.difficulty_pulmonary_edema == true
                      ? 'Pulmonary Edema, '
                      : null}
                    {prf_Data.breathing.type == 'Breathing Difficulty' &&
                    prf_Data.breathing.difficulty_allegy == true
                      ? 'Allergy, '
                      : null}
                    {prf_Data.breathing.difficulty_other}
                  </td>
                  <td />
                  <td>{prf_Data.breathing.treatment}</td>
                  <td>
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_24_nasal == true
                      ? '24% Nasal, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_40_venturi == true
                      ? '40% Venturi, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_60_rebreather == true
                      ? '60% Rebreather, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_100_bvm == true
                      ? '100% BVM, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_simple_face_mask == true
                      ? 'Simple Face Mask, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_non_rebreather == true
                      ? 'Non Rebreather, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_tpiece == true
                      ? 'T-Piece, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_nebuliser == true
                      ? 'Nebuliser, '
                      : null}
                    {prf_Data.breathing.treatment == 'Oxygen' &&
                    prf_Data.breathing.oxygen_cpap == true
                      ? 'CPAP, '
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Circulation</strong>
                  </td>
                  <td>{prf_Data.circulation.pulse}</td>
                  <td>{prf_Data.circulation.treatment}</td>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <strong>Immobilization &amp; Extrication</strong>
                  </td>
                  <td>
                    {prf_Data.immobilisation.spinal == true
                      ? 'Spinal Immobilization, '
                      : null}
                    {prf_Data.immobilisation.stretcher == true
                      ? 'Stretcher, '
                      : null}
                    {prf_Data.immobilisation.straps == true ? 'Straps, ' : null}
                    {prf_Data.immobilisation.blanket == true
                      ? 'Blanket, '
                      : null}
                    {prf_Data.immobilisation.ked == true ? 'K.E.D, ' : null}
                    {prf_Data.immobilisation.rescue == true ? 'Rescue, ' : null}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <strong>Monitor</strong>
                  </td>
                  <td>
                    {prf_Data.mornitoring.ecg == true ? 'ECG, ' : null}
                    {prf_Data.mornitoring.nibp == true ? 'NIBP, ' : null}
                    {prf_Data.mornitoring.pulseox == true ? 'PULSEOX, ' : null}
                    {prf_Data.mornitoring.hgt == true ? 'HGT, ' : null}
                    {prf_Data.mornitoring.hb == true ? 'HB, ' : null}
                    {prf_Data.mornitoring.doppler == true ? 'Doppler, ' : null}
                    {prf_Data.mornitoring.syr_drive == true
                      ? 'Syr.Drive, '
                      : null}
                    {prf_Data.mornitoring.ventilator == true
                      ? 'Ventilator, '
                      : null}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <strong>IV Access</strong>
                  </td>
                  <td colSpan={5}>
                    <span style={{ fontWeight: 'bold' }}>Needle : </span>
                    {prf_Data.iv_access1.needle_24g == true ? '24g, ' : null}
                    {prf_Data.iv_access1.needle_22g == true ? '22g, ' : null}
                    {prf_Data.iv_access1.needle_20g == true ? '20g, ' : null}
                    {prf_Data.iv_access1.needle_18g == true ? '18g, ' : null}
                    {prf_Data.iv_access1.needle_16g == true ? '16g, ' : null}
                    {prf_Data.iv_access1.needle_14g == true ? '14g, ' : null}

                    <span style={{ fontWeight: 'bold' }}> Site : </span>
                    {prf_Data.iv_access1.site_lacf == true ? 'L ACF, ' : null}
                    {prf_Data.iv_access1.site_racf == true ? 'R ACF, ' : null}
                    {prf_Data.iv_access1.site_pedis == true ? 'PEDIS, ' : null}
                    {prf_Data.iv_access1.site_l_ext_jug == true
                      ? 'L EXT JUG, '
                      : null}
                    {prf_Data.iv_access1.site_r_ext_jug == true
                      ? 'R EXT JUG, '
                      : null}
                    {prf_Data.iv_access1.site_l_femor == true
                      ? 'L FEMOR, '
                      : null}
                    {prf_Data.iv_access1.site_r_femor == true
                      ? 'R FEMOR, '
                      : null}
                    {prf_Data.iv_access1.site_l_hand == true
                      ? 'L HAND, '
                      : null}
                    {prf_Data.iv_access1.site_r_hand == true
                      ? 'R HAND, '
                      : null}
                    {prf_Data.iv_access1.site_cvp == true ? 'CVP, ' : null}
                    {prf_Data.iv_access1.site_external_jug == true
                      ? 'EXTERNAL JUG, '
                      : null}
                    {prf_Data.iv_access1.site_fem_vein == true
                      ? 'FEM VEIN, '
                      : null}
                    {prf_Data.iv_access1.site_l_forearm == true
                      ? 'L FOREARM, '
                      : null}
                    {prf_Data.iv_access1.site_r_forearm == true
                      ? 'R FOREARM, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}> Admin Set : </span>
                    {prf_Data.iv_access1.adminset_10drp == true
                      ? '10 DRP, '
                      : null}
                    {prf_Data.iv_access1.adminset_15drp == true
                      ? '15 DRP, '
                      : null}
                    {prf_Data.iv_access1.adminset_20drp == true
                      ? '20 DRP, '
                      : null}
                    {prf_Data.iv_access1.adminset_60drp == true
                      ? '60 DRP, '
                      : null}
                    {prf_Data.iv_access1.adminset_burotrol == true
                      ? 'Burotrol, '
                      : null}
                    {prf_Data.iv_access1.adminset_blood_infusion_set == true
                      ? 'Blood Infusion Set, '
                      : null}
                    {prf_Data.iv_access1.high_cap_line == true
                      ? 'High Cap Line, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}> Fluid : </span>
                    {prf_Data.iv_access1.fluid_nacl == true ? 'NACL, ' : null}
                    {prf_Data.iv_access1.fluid_ringers == true
                      ? 'NACL, '
                      : null}
                    {prf_Data.iv_access1.fluid_balsol == true
                      ? 'Balsol, '
                      : null}
                    {prf_Data.iv_access1.fluid_5dext == true
                      ? '5% Dext, '
                      : null}
                    {prf_Data.iv_access1.fluid_susten == true
                      ? 'Susten, '
                      : null}
                    {prf_Data.iv_access1.fluid_blood == true ? 'Blood, ' : null}
                    {prf_Data.iv_access1.fluid_colloid == true
                      ? 'Colloid, '
                      : null}
                    {prf_Data.iv_access1.fluid_other}

                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      Init Amount ml :{' '}
                    </span>
                    {prf_Data.iv_access1.init_amount_1000 == true
                      ? '1000 ml, '
                      : null}
                    {prf_Data.iv_access1.init_amount_500 == true
                      ? '500 ml, '
                      : null}
                    {prf_Data.iv_access1.init_amount_200 == true
                      ? '200 ml, '
                      : null}
                    {prf_Data.iv_access1.init_amount_100 == true
                      ? '100 ml, '
                      : null}
                    {prf_Data.iv_access1.init_amount_50 == true
                      ? '50 ml, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      Admin amount ml :{' '}
                    </span>
                    {prf_Data.iv_access1.admin_amount != null
                      ? prf_Data.iv_access1.admin_amount
                      : null}
                    <span style={{ fontWeight: 'bold' }}> Time : </span>
                    {prf_Data.iv_access1.admin_amount_time != null
                      ? prf_Data.iv_access1.admin_amount_time
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>IV Access 2</strong>
                  </td>
                  <td colSpan={5}>
                    <span style={{ fontWeight: 'bold' }}>Needle : </span>
                    {prf_Data.iv_access2.needle_24g == true ? '24g, ' : null}
                    {prf_Data.iv_access2.needle_22g == true ? '22g, ' : null}
                    {prf_Data.iv_access2.needle_20g == true ? '20g, ' : null}
                    {prf_Data.iv_access2.needle_18g == true ? '18g, ' : null}
                    {prf_Data.iv_access2.needle_16g == true ? '16g, ' : null}
                    {prf_Data.iv_access2.needle_14g == true ? '14g, ' : null}

                    <span style={{ fontWeight: 'bold' }}>Site : </span>
                    {prf_Data.iv_access2.site_lacf == true ? 'L ACF, ' : null}
                    {prf_Data.iv_access2.site_racf == true ? 'R ACF, ' : null}
                    {prf_Data.iv_access2.site_pedis == true ? 'PEDIS, ' : null}
                    {prf_Data.iv_access2.site_l_ext_jug == true
                      ? 'L EXT JUG, '
                      : null}
                    {prf_Data.iv_access2.site_r_ext_jug == true
                      ? 'R EXT JUG, '
                      : null}
                    {prf_Data.iv_access2.site_l_femor == true
                      ? 'L FEMOR, '
                      : null}
                    {prf_Data.iv_access2.site_r_femor == true
                      ? 'R FEMOR, '
                      : null}
                    {prf_Data.iv_access2.site_l_hand == true
                      ? 'L HAND, '
                      : null}
                    {prf_Data.iv_access2.site_r_hand == true
                      ? 'R HAND, '
                      : null}
                    {prf_Data.iv_access2.site_cvp == true ? 'CVP, ' : null}
                    {prf_Data.iv_access2.site_external_jug == true
                      ? 'EXTERNAL JUG, '
                      : null}
                    {prf_Data.iv_access2.site_fem_vein == true
                      ? 'FEM VEIN, '
                      : null}
                    {prf_Data.iv_access2.site_l_forearm == true
                      ? 'L FOREARM, '
                      : null}
                    {prf_Data.iv_access2.site_r_forearm == true
                      ? 'R FOREARM, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}>Admin Set : </span>
                    {prf_Data.iv_access2.adminset_10drp == true
                      ? '10 DRP, '
                      : null}
                    {prf_Data.iv_access2.adminset_15drp == true
                      ? '15 DRP, '
                      : null}
                    {prf_Data.iv_access2.adminset_20drp == true
                      ? '20 DRP, '
                      : null}
                    {prf_Data.iv_access2.adminset_60drp == true
                      ? '60 DRP, '
                      : null}
                    {prf_Data.iv_access2.adminset_burotrol == true
                      ? 'Burotrol, '
                      : null}
                    {prf_Data.iv_access2.adminset_blood_infusion_set == true
                      ? 'Blood Infusion Set, '
                      : null}
                    {prf_Data.iv_access2.high_cap_line == true
                      ? 'High Cap Line, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}>Fluid : </span>
                    {prf_Data.iv_access2.fluid_nacl == true ? 'NACL, ' : null}
                    {prf_Data.iv_access2.fluid_ringers == true
                      ? 'NACL, '
                      : null}
                    {prf_Data.iv_access2.fluid_balsol == true
                      ? 'Balsol, '
                      : null}
                    {prf_Data.iv_access2.fluid_5dext == true
                      ? '5% Dext, '
                      : null}
                    {prf_Data.iv_access2.fluid_susten == true
                      ? 'Susten, '
                      : null}
                    {prf_Data.iv_access2.fluid_blood == true ? 'Blood, ' : null}
                    {prf_Data.iv_access2.fluid_colloid == true
                      ? 'Colloid, '
                      : null}
                    {prf_Data.iv_access2.fluid_other}

                    <span style={{ fontWeight: 'bold' }}>
                      Init Amount ml :{' '}
                    </span>
                    {prf_Data.iv_access2.init_amount_1000 == true
                      ? '1000 ml, '
                      : null}
                    {prf_Data.iv_access2.init_amount_500 == true
                      ? '500 ml, '
                      : null}
                    {prf_Data.iv_access2.init_amount_200 == true
                      ? '200 ml, '
                      : null}
                    {prf_Data.iv_access2.init_amount_100 == true
                      ? '100 ml, '
                      : null}
                    {prf_Data.iv_access2.init_amount_50 == true
                      ? '50 ml, '
                      : null}

                    <span style={{ fontWeight: 'bold' }}>
                      Admin amount ml :{' '}
                    </span>
                    {prf_Data.iv_access2.admin_amount != null
                      ? prf_Data.iv_access2.admin_amount
                      : null}
                    <span style={{ fontWeight: 'bold' }}> Time : </span>
                    {prf_Data.iv_access2.admin_amount_time != null
                      ? prf_Data.iv_access2.admin_amount_time
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            border: 'none',
            borderStyle: 'none',
            marginTop: '10px'
          }}>
          <center>
            <strong>PRF No:</strong>
            <font color="red" size="3px">
              {prf_Data.id}
            </font>
          </center>
          <br />

          <table
            border="1"
            style={{
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <strong>VITAL SIGNS</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '287px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong />
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>1</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>2</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>3</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>4</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>5</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  <strong>6</strong>
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Time</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.time}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.time}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.time}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.time}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.time}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.time}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Pulse&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.pulse}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.pulse}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.pulse}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.pulse}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.pulse}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.pulse}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Blood Pressure&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.blood_pressure}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.blood_pressure}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.blood_pressure}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.blood_pressure}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.blood_pressure}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.blood_pressure}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>SpO2&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.spo2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.spo2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.spo2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.spo2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.spo2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.spo2}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>O2</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.o2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.o2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.o2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.o2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.o2}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.o2}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Respiratory Rate&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.respiratory_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.respiratory_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.respiratory_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.respiratory_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.respiratory_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.respiratory_rate}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Temperature&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.temperature}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.temperature}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.temperature}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.temperature}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.temperature}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.temperature}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>HGT&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.hgt}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.hgt}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.hgt}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.hgt}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.hgt}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.hgt}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Hb&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.hb}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.hb}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.hb}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.hb}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.hb}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.hb}</td>
              </tr>
              <tr />
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>GCS</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_1.eye}; Verbal: {prf_Data.vital_1.verbal}
                  ; Motor: {prf_Data.vital_1.motor}; GCS: {prf_Data.vital_1.gcs}
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_2.eye}; Verbal: {prf_Data.vital_2.verbal}
                  ; Motor: {prf_Data.vital_2.motor}; GCS: {prf_Data.vital_2.gcs}
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_3.eye}; Verbal: {prf_Data.vital_3.verbal}
                  ; Motor: {prf_Data.vital_3.motor}; GCS: {prf_Data.vital_3.gcs}
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_4.eye}; Verbal: {prf_Data.vital_4.verbal}
                  ; Motor: {prf_Data.vital_4.motor}; GCS: {prf_Data.vital_4.gcs}
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_5.eye}; Verbal: {prf_Data.vital_5.verbal}
                  ; Motor: {prf_Data.vital_5.motor}; GCS: {prf_Data.vital_5.gcs}
                </td>
                <td style={{ width: '14.2%' }}>
                  Eye: {prf_Data.vital_6.eye}; Verbal: {prf_Data.vital_6.verbal}
                  ; Motor: {prf_Data.vital_6.motor}; GCS: {prf_Data.vital_6.gcs}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Pain Score&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.pain_score}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.pain_score}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.pain_score}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.pain_score}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.pain_score}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.pain_score}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>ECG Analysis&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_1.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_1.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_1.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_1.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_1.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_1.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_1.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_1.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_1.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_1.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_1.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_1.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_1.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_1.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_1.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_1.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_1.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_1.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_1.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_1.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_1.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_1.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_1.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_1.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_1.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_1.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_1.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_1.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_1.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_1.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_1.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_1.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_2.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_2.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_2.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_2.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_2.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_2.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_2.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_2.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_2.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_2.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_2.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_2.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_2.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_2.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_2.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_2.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_2.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_2.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_2.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_2.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_2.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_2.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_2.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_2.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_2.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_2.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_2.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_2.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_2.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_2.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_2.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_2.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_3.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_3.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_3.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_3.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_3.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_3.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_3.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_3.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_3.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_3.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_3.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_3.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_3.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_3.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_3.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_3.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_3.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_3.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_3.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_3.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_3.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_3.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_3.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_3.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_3.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_3.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_3.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_3.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_3.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_3.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_3.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_3.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_4.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_4.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_4.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_4.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_4.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_4.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_4.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_4.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_4.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_4.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_4.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_4.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_4.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_4.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_4.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_4.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_4.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_4.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_4.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_4.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_4.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_4.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_4.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_4.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_4.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_4.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_4.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_4.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_4.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_4.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_4.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_4.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_5.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_5.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_5.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_5.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_5.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_5.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_5.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_5.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_5.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_5.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_5.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_5.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_5.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_5.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_5.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_5.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_5.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_5.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_5.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_5.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_5.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_5.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_5.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_5.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_5.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_5.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_5.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_5.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_5.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_5.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_5.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_5.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.ecg_sinus ? 'Sinus Rhythm, ' : ''}
                  {prf_Data.vital_6.ecg_tachycardia ? 'Tachycardia, ' : ''}
                  {prf_Data.vital_6.ecg_bradycardia ? 'Bradycardia, ' : ''}
                  {prf_Data.vital_6.ecg_ventricular_fibrillation
                    ? 'Ventricular Fibrillation, '
                    : ''}
                  {prf_Data.vital_6.ecg_atrial_fibrillation
                    ? 'Atrial Fibrillation, '
                    : ''}
                  {prf_Data.vital_6.ecg_artiral_flutter
                    ? 'Atrial Flutter, '
                    : ''}
                  {prf_Data.vital_6.ecg_ventricular_flutter
                    ? 'Ventricular Flutter, '
                    : ''}
                  {prf_Data.vital_6.ecg_bundle_branch_block
                    ? 'Bundle Branch Block, '
                    : ''}
                  {prf_Data.vital_6.ecg_heart_block ? 'Heart Block, ' : ''}
                  {prf_Data.vital_6.ecg_idioventricular_rhythm
                    ? 'Idioventricular Rhythm, '
                    : ''}
                  {prf_Data.vital_6.ecg_junc_escape_rhythm
                    ? 'Junc Escape Rhythm, '
                    : ''}
                  {prf_Data.vital_6.ecg_junc_tachycardia
                    ? 'Junc Tachycardia, '
                    : ''}
                  {prf_Data.vital_6.ecg_multifocal_atrial_tachycardia
                    ? 'Multifocal Atrial Tachycardia, '
                    : ''}
                  {prf_Data.vital_6.ecg_pm_failure_to_capture
                    ? 'PM failure to capture, '
                    : ''}
                  {prf_Data.vital_6.ecg_pm_single_chamber_atrial
                    ? 'PM single chamber Atrial, '
                    : ''}
                  {prf_Data.vital_6.ecg_prem_atrial_complex
                    ? 'Prem Atrial Complex, '
                    : ''}
                  {prf_Data.vital_6.ecg_prem_junc_complex
                    ? 'Prem Junc Complex, '
                    : ''}
                  {prf_Data.vital_6.ecg_prem_ventricular_complex
                    ? 'Prem Ventricular Complex'
                    : ''}
                  {prf_Data.vital_6.ecg_prem_ventricular_complex_bigeminy
                    ? 'Prem Ventricular Complex Bigeminy, '
                    : ''}
                  {prf_Data.vital_6.ecg_sinoatrial_block
                    ? 'Sinoatrial Block, '
                    : ''}
                  {prf_Data.vital_6.ecg_sinus_arrest ? 'Sinus Arrest, ' : ''}
                  {prf_Data.vital_6.ecg_sinus_arrhythmia
                    ? 'Sinus Arrhythmia, '
                    : ''}
                  {prf_Data.vital_6.ecg_superaventricular_tachycardia
                    ? 'Supraventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_6.ecg_ventricular_tachycardia
                    ? 'Ventricular Tachycardia, '
                    : ''}
                  {prf_Data.vital_6.ecg_v_tach_monomorphic
                    ? 'V-Tach Monomorphic, '
                    : ''}
                  {prf_Data.vital_6.ecg_v_tach_polymorphic
                    ? 'V-Tach Polymorphic, '
                    : ''}
                  {prf_Data.vital_6.ecg_torsade_de_pointes
                    ? 'Torsade de Pointes, '
                    : ''}
                  {prf_Data.vital_6.ecg_wandering_atrial_pacemaker
                    ? 'Wandering Atrial Pacemaker, '
                    : ''}
                  {prf_Data.vital_6.ecg_wolff_park_white_syndrome
                    ? 'Wolff-Park-White Syndrome, '
                    : ''}
                  {prf_Data.vital_6.ecg_asystole ? 'Asystole, ' : ''}
                  {prf_Data.vital_6.ecg_pacemaker_rhythm
                    ? 'Pacemaker Rhythm, '
                    : ''}
                  {prf_Data.vital_6.ecg_st_elevation ? 'ST Elevation, ' : ''}
                  {prf_Data.vital_6.ecg_st_depression ? 'ST Depression, ' : ''}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Pacing mAH</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.pacing_mah}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.pacing_mah}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.pacing_mah}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.pacing_mah}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.pacing_mah}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.pacing_mah}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Pacing Rate</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_1.pacing_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_2.pacing_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_3.pacing_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_4.pacing_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_5.pacing_rate}
                </td>
                <td style={{ width: '14.2%' }}>
                  {prf_Data.vital_6.pacing_rate}
                </td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Defib Joules</strong>
                </td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_1.defib}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_2.defib}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_3.defib}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_4.defib}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_5.defib}</td>
                <td style={{ width: '14.2%' }}>{prf_Data.vital_6.defib}</td>
              </tr>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <strong>Pupils&nbsp;</strong>
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_1.pupils_left}; Right:{' '}
                  {prf_Data.vital_1.pupils_right}{' '}
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_2.pupils_left}; Right:{' '}
                  {prf_Data.vital_2.pupils_right}{' '}
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_3.pupils_left}; Right:{' '}
                  {prf_Data.vital_3.pupils_right}{' '}
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_4.pupils_left}; Right:{' '}
                  {prf_Data.vital_4.pupils_right}{' '}
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_5.pupils_left}; Right:{' '}
                  {prf_Data.vital_5.pupils_right}{' '}
                </td>
                <td style={{ width: '14.2%' }}>
                  Left: {prf_Data.vital_6.pupils_left}; Right:{' '}
                  {prf_Data.vital_6.pupils_right}{' '}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ pageBreakAfter: 'always' }}>
            <table
              border="1"
              style={{
                height: '21px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr style={{ height: '21px' }}>
                  <td style={{ textAlign: 'center', height: '21px' }}>
                    <strong>MEDICATION</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              border="1"
              style={{
                height: '154px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr>
                  <td width="20%">Counts</td>
                  <td width="20%">
                    <strong>Type</strong>
                  </td>
                  <td width="20%">
                    <strong>Dosage</strong>
                  </td>
                  <td width="20%">
                    <strong>Time</strong>
                  </td>
                  <td width="20%">
                    <strong>Route</strong>
                  </td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>1</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_1}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_1}</td>
                  <td width="20%">{prf_Data.medication_slots.time_1}</td>
                  <td width="20%">{prf_Data.medication_slots.route_1}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>2</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_2}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_2}</td>
                  <td width="20%">{prf_Data.medication_slots.time_2}</td>
                  <td width="20%">{prf_Data.medication_slots.route_2}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>3</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_3}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_3}</td>
                  <td width="20%">{prf_Data.medication_slots.time_3}</td>
                  <td width="20%">{prf_Data.medication_slots.route_3}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>4</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_4}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_4}</td>
                  <td width="20%">{prf_Data.medication_slots.time_4}</td>
                  <td width="20%">{prf_Data.medication_slots.route_4}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>5</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_5}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_5}</td>
                  <td width="20%">{prf_Data.medication_slots.time_5}</td>
                  <td width="20%">{prf_Data.medication_slots.route_5}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>6</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_6}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_6}</td>
                  <td width="20%">{prf_Data.medication_slots.time_6}</td>
                  <td width="20%">{prf_Data.medication_slots.route_6}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>7</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_7}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_7}</td>
                  <td width="20%">{prf_Data.medication_slots.time_7}</td>
                  <td width="20%">{prf_Data.medication_slots.route_7}</td>
                </tr>
                <tr>
                  <td width="20%">
                    <strong>8</strong>
                  </td>
                  <td width="20%">{prf_Data.medication_slots.type_8}</td>
                  <td width="20%">{prf_Data.medication_slots.dosage_8}</td>
                  <td width="20%">{prf_Data.medication_slots.time_8}</td>
                  <td width="20%">{prf_Data.medication_slots.route_8}</td>
                </tr>
              </tbody>
            </table>
            <table
              border="1"
              style={{
                height: '21px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr style={{ height: '21px' }}>
                  <td style={{ textAlign: 'center', height: '21px' }}>
                    <strong>EXAMINATIONS / OBSERVATIONS</strong>
                  </td>
                </tr>
                <tr>
                  <td width="99%">
                    {prf_Data.patient_history.exam_objservation}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ width: '100%', marginTop: '10px' }}>
          <center>
            <strong>PRF No:</strong>
            <font color="red" size="3px">
              {prf_Data.id}
            </font>
          </center>
          <br />
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr style={{ height: '21px' }}>
                <td style={{ textAlign: 'center', height: '21px' }}>
                  <strong>LEGAL INDEMNITY</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td width="50%">
                  I, the aforementioned patient,{' '}
                  {prf_Data.patient_details.first_name}{' '}
                  {prf_Data.patient_details.last_name},
                  {prf_Data.patient_consent.accept_treatment == true
                    ? 'accept'
                    : 'decline'}{' '}
                  treatment and/or transport as stated in this patient report
                  form. I confirm that this decision is informed consent. I
                  accept full responsibility for the payment of this account and
                  understand that I will be liable in the event of NON-PAYMENT
                  or SHORT PAYMENT made by my medical aid. I hereby indemnify
                  Legacy Emergency Specialists Pty Ltd and all staff affiliates
                  against any and all claims of loss, theft, injury or death
                  whilst under the care of Legacy Emergency Specialists Pty Ltd.
                </td>
                <td width="50%">
                  I, {prf_Data.call_information.receiving_dr_ward}, accept full
                  responsibility and care of the aforementioned patient at a
                  level of higher care from Legacy Emergency Specialists Pty
                  Ltd. I confirm that a&nbsp; thorough handover has been done
                  and that all patient&nbsp; belongings have been handed to me.
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td>
                  &nbsp;
                  <strong>Signature :</strong>
                </td>
                <td>
                  <img src={prf_Data.signature.patient} width="100mm" />
                </td>
                <td>
                  <strong>Signature :&nbsp;</strong>
                </td>
                <td>
                  <img src={prf_Data.signature.hospital} width="100mm" />
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            style={{
              height: '21px',
              overflowWrap: 'anywhere',
              tableLayout: 'fixed',
              wordWrap: 'break-word'
            }}
            width="99%">
            <tbody>
              <tr>
                <td style={{ width: '25%' }}>
                  <strong>&nbsp;Date :</strong>
                </td>
                <td style={{ width: '25%' }}>
                  {prf_Data.createdAt == null
                    ? ''
                    : moment(prf_Data.createdAt).format('YYYY-MM-DD')}
                </td>
                <td style={{ width: '25%' }}>
                  <strong>Date&nbsp;:</strong>
                </td>
                <td style={{ width: '25%' }}>
                  {prf_Data.createdAt == null
                    ? ''
                    : moment(prf_Data.createdAt).format('YYYY-MM-DD')}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            className={classes.license_img_wrapper_pc}
            style={{
              pageBreakAfter: 'always',
              display: 'flex',
              marginTop: '10px'
            }}>
            <table
              style={{
                height: '21px',
                overflowWrap: 'anywhere',
                tableLayout: 'fixed',
                wordWrap: 'break-word'
              }}
              width="99%">
              <tbody>
                <tr>
                  <td style={{ width: '50%' }}>
                    <div style={{ margin: '5px' }}>
                      
                      <img
                        src={prf_Data.license_img.hospital}
                        style={{ width: '400px', objectFit: 'contain' }}
                      />
                    </div>
                  </td>
                  <td style={{ width: '50%' }}>
                    <div style={{ margin: '5px' }}>
                      
                      <img
                        src={prf_Data.license_img.driver}
                        style={{ width: '400px', objectFit: 'contain' }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className={classes.license_img_wrapper_phone}
            style={{
              pageBreakAfter: 'always',
              overflow: 'hidden',
              overflowX: 'hidden',
              marginTop: '10px'
            }}>
            <div style={{ margin: '5px' }}>
              <h4>Hospital Sticker</h4>
              <img
                src={prf_Data.license_img.hospital}
                style={{
                  width: '250px',
                  height: '250px',
                  objectFit: 'contain'
                }}
              />
            </div>
            <div style={{ margin: '5px' }}>
              <h4>Driver's License</h4>
              <img
                src={prf_Data.license_img.driver}
                style={{
                  width: '250px',
                  height: '250px',
                  objectFit: 'contain'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default NetcareForm;
