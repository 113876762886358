import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer  from 'react-dom/server'
import moment from 'moment';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';


const getAcceptFormHtmlString = (prf_Data) => {
  let date = prf_Data.createdAt == null ? '' : new Date(prf_Data.createdAt).getDate();
  let month = prf_Data.createdAt == null ? '' : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][new Date(prf_Data.createdAt).getMonth()]
  let year = prf_Data.createdAt == null ? '' : new Date(prf_Data.createdAt).getFullYear();

  let html = ReactDOMServer.renderToString(
    <div
      id="print1"
      style = {{width : '100%'}}
    >
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              align = "left"
              width="33%"
            >
              <img
                src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                width="80px"
              />
            </td>
            <td
              style= {{textAlign: 'right', }}
              width="62%"
            >
              <strong>Legacy Emergency Specialists</strong>
              <br/>
              <strong>Route N4 Business Park, Corridor Crescent</strong>
              <br/>
              <strong>9 Bureau de Paul</strong>
              <br/>
              <strong>Ben Fleur</strong>
              <br/>
              <strong>Witbank</strong>
              <br/>
              <strong>www.legacyems.co.za</strong>
              <br/>
              <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
              <br/>
              <strong>Practice Number: 009 003 064 1332</strong>
              <br/>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
        width="99%"
      >
        <tbody><tr> <td style={{textAlign : 'center'}}><strong>PATIENT PAYMENT AGREEMENT AND INDEMNITY FORM</strong></td></tr></tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderBottomWidth : 0 , borderTopWidth : 0 }}
        width="99%"
      >
        <tbody>
          <tr>
            <td width="50%">
              <strong>I. the undersigned:</strong>
            </td>
          </tr>
          <tr><td
            height = "5"
            width="100%"
          /></tr>
          <tr>
            <td
              style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000',  }}
              width="33%"
            >
              <strong>
                { 
                  prf_Data.patient_details == null || prf_Data.patient_details.first_name == null ? '' : prf_Data.patient_details.first_name 
                }
                {' '}
                { 
                  prf_Data.patient_details == null || prf_Data.patient_details.last_name == null ? '' : prf_Data.patient_details.last_name
                }
              </strong>
            </td>
            <td
              style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
              width="33%"
            >
              <strong>
                { 
                  prf_Data.patient_details == null || prf_Data.patient_details.id_no == null ? '' : prf_Data.patient_details.id_no
                }
              </strong>
            </td>
            <td
              style = {{borderBottomWidth : 1, borderBottomStyle : 'solid', borderBottomColor : '#000' }}
              width="33%"
            >
              <strong>
                { 
                  prf_Data.id == null ? '' : prf_Data.id
                }
              </strong>
            </td>
          </tr>
          <tr>
            <td
              style = {{fontStyle : 'italic' }}
              width="33%"
            >
              <strong>Name & Surname of Guarantor/Patient</strong>
            </td>
            <td
              style = {{fontStyle : 'italic' }}
              width="33%"
            >
              <strong>ID Number</strong>
            </td>
            <td
              style = {{fontStyle : 'italic' }}
              width="33%"
            >
              <strong>PRF No</strong>
            </td>
          </tr>
          <tr><td
            height = "5"
            width="100%"
          /></tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >1)</td>
            <td valign = {'top'}>Hereby give Legacy Emergency Specialists (Pty) Ltd with Registration No. 2016/121004/07, trading as Legacy Emergency Specialists, my consent and permission to transport me to a hospital of my choice, and in the event that the afore-mentioned hospital is not available, any hospital Legacy Emergency Specialists deems fit.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >2)</td>
            <td valign = {'top'}>I further give Legacy Emergency Specialists my consent and permission to stabilize me and/or give me life support and/or any other function or support if needed in the circumstances at hand and/or  as requested by myself.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >3)</td>
            <td valign = {'top'}>By making use of Legacy Emergency Specialist services, i accept that there are costs and fees involved for provided services. I hereby take full responsibility and liability for the payment of their fees and costs as set out in Annexure "A" below.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >4)</td>
            <td valign = {'top'}>I admit that on rendering of the services, all accounts become immediately due and payable to Legacy  Emergency Specialists.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >5)</td>
            <td valign = {'top'}>I agree to Legacy Emergency Specialists submitting the invoice (even before i receive an account) on my behalf to my medical aid where applicable, i confirm that i wil always be responsible and liable for payment of Legacy Emergency Specialists account.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >6)</td> 
            <td valign = {'top'}>In the event that legal action commences regarding the non-payment of Legacy Emergency Specialists fees, I accept that i will be liable for the legal costs on an attorney and client scale, which shall include, but is not limited, to tracing fees and 10% collection commission.</td>
          </tr>
          <tr>
            <td
              style = {{paddingRight: 10}}
              valign = {'top'}
              width = "26"
            >7)</td>
            <td valign = {'top'}>I hereby indemnify Legacy Emergency Specialists, any of it's director(s), shareholder(s), employee(s) or agent(s)  from any loss and/or damage(s) and/or injury and/or death which i many suffer as a result of any act or omission, whether such loss and/or damages and/or injury and/or death is a result of the negligence of any person relating to any of the services rendered by Legacy Emergency Specialists.</td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
        width="99%"
      >
        <tbody><tr> <td
          height = "13"
          style={{textAlign : 'left'}}
        ><strong>Annexure A</strong></td></tr></tbody>
      </table>
      <table
        border="1"
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
        width="99%"
      >
        <tbody>
          <tr>
            <td valign = {'top'}><b>Description</b></td>
            <td
              valign = {'top'}
              width="30%"
            ><b>Amount</b></td>
          </tr>
          <tr>
            <td valign = {'top'} >Call Out Fee, no Transport, RHT</td>
            <td
              valign = {'top'}
              width="30%"
            >R 800.00</td>
          </tr>
          <tr>
            <td valign = {'top'} >Declaration of Death</td>
            <td
              valign = {'top'}
              width="30%"
            >R 1,500.00</td>
          </tr>
          <tr>
            <td valign = {'top'} >Basic Life Support up to 60 min</td>
            <td
              valign = {'top'}
              width="30%"
            >R 2,241.00</td>
          </tr>
          <tr>
            <td valign = {'top'} >Basic Life Support every 15min thereafter</td>
            <td
              valign = {'top'}
              width="30%"
            >R 561.07</td>
          </tr>
          <tr>
            <td valign = {'top'} >Basic Life Support above 100km patient in-situ per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 27.93</td>
          </tr>
          <tr>
            <td valign = {'top'} >Basic Life Support above 100km return no patient per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 12.55</td>
          </tr>
          <tr>
            <td valign = {'top'} >Intermediate Life Support up to 60 min</td>
            <td
              valign = {'top'}
              width="30%"
            >R 2,962.42</td>
          </tr>
          <tr>
            <td valign = {'top'} >Intermediate Life Support every 15 min thereafter</td>
            <td
              valign = {'top'}
              width="30%"
            >R 757.22</td>
          </tr>
          <tr>
            <td valign = {'top'} >Intermediate Life Support above 100km patient in-situ per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 37.82</td>
          </tr>
          <tr>
            <td valign = {'top'} >Intermediate Life Support above 100km return no patient per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 12.55</td>
          </tr>
          <tr>
            <td valign = {'top'} >Advanced Life Support up to 60 min</td>
            <td
              valign = {'top'}
              width="30%"
            >R 4,701.46</td>
          </tr>
          <tr>
            <td valign = {'top'} >Advanced Life Support every 15 min thereafter</td>
            <td
              valign = {'top'}
              width="30%"
            >R 1,534.76</td>
          </tr>
          <tr>
            <td valign = {'top'} >Advanced Life Support above 100km patient in-situ per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 68.04</td>
          </tr>
          <tr>
            <td valign = {'top'} >Advanced Life Support above 100km return no patient per km</td>
            <td
              valign = {'top'}
              width="30%"
            >R 12.55</td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              height = "12"
              style={{textAlign : 'left'}}
            >
              <span>Signed in acceptance at </span>
              <span style = {{minWidth : '50px', display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}>
                {prf_Data.call_information == null || prf_Data.call_information.transport_from == null ? '' : prf_Data.call_information.transport_from}
              </span>
              <span> on the  </span>
              <span style = {{minWidth : 20, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}>
                {date}
              </span>
              <span>day of </span>
              <span style = {{minWidth : 50, display : 'inline-block', borderBottomWidth: 1, borderBottomColor : '#000', borderBottomStyle : 'solid'}}>
                {month}
              </span>
              <span> {year} </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              height = "12"
              style = {{borderBottomWidth : 1, borderBottomColor : '#000', borderBottomStyle : 'solid', width : '50%'}}
              width = "50%"
            >
              <img
                height = "24px"
                src = {prf_Data.signature == null ? '' : prf_Data.signature.patient}
                width = "60px"
              />
            </td>
            <td />
          </tr>
          <tr>
            <td
              style = {{fontStyle : 'italic'}}
              width = "50%"
            >
              Signature of Guarantor/Patient
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  )
  return html
}

const getRefuseFormHtmlString = (prf_Data) => {

  let time = prf_Data.createdAt == null ? '' : new Date(prf_Data.createdAt).getHours() + ':' + new Date(prf_Data.createdAt).getMinutes();

  let html = ReactDOMServer.renderToString(
    <div
      id="print1"
      style = {{width : '100%'}}
    >
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000' }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              align = "left"
              width="33%"
            >
              <img
                src="http://www.spectiv.co.za/legacy/signatures/Logo with No.JPG"
                width="80px"
              />
            </td>
            <td
              style= {{textAlign: 'right', }}
              width="62%"
            >
              <strong>Legacy Emergency Specialists</strong>
              <br/>
              <strong>Route N4 Business Park, Corridor Crescent</strong>
              <br/>
              <strong>9 Bureau de Paul</strong>
              <br/>
              <strong>Ben Fleur</strong>
              <br/>
              <strong>Witbank</strong>
              <br/>
              <strong>www.legacyems.co.za</strong>
              <br/>
              <strong style = {{color : '#f00'}}>Emergencies: 061 9960 911</strong>
              <br/>
              <strong>Practice Number: 009 003 064 1332</strong>
              <br/>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 , }}
        width="99%"
      >
        <tbody><tr> <td style={{textAlign : 'center'}}><strong>REFUSAL OF TREATMENT, TRANSPORT AND/OR EVALUATION</strong></td></tr></tbody>
      </table>
      <table
        border="1"
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
        width="99%"
      >
        <tbody>
          <tr>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Patient Name</b></td>
            <td
              valign = {'top'}
              width = "40%"
            >
              { 
                prf_Data.patient_details == null || prf_Data.patient_details.first_name == null ? '' : prf_Data.patient_details.first_name 
              }
            </td>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Patient Surname</b></td>
            <td
              valign = {'top'}
              width = "20%"
            >
              { 
                prf_Data.patient_details == null || prf_Data.patient_details.last_name == null ? '' : prf_Data.patient_details.last_name
              }
            </td>
          </tr>
          <tr>
            <td
              valign = {'top'}
              width = "20%"
            ><b>ID Number</b></td>
            <td
              valign = {'top'}
              width = "40%"
            >
              { 
                prf_Data.patient_details == null || prf_Data.patient_details.id_no == null ? '' : prf_Data.patient_details.id_no
              }
            </td>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Mobile Number</b></td>
            <td
              valign = {'top'}
              width = "20%"
            >
              { 
                prf_Data.patient_details == null || prf_Data.patient_details.mobile_number == null ? '' : prf_Data.patient_details.mobile_number
              }
            </td>
          </tr>
          <tr>
            <td
              valign = {'top'}
              width = "20%"
            ><b>E-mail Address</b></td>
            <td
              valign = {'top'}
              width = "40%"
            >
              { 
                prf_Data.patient_details == null || prf_Data.patient_details.email_address == null ? '' : prf_Data.patient_details.email_address
              }
            </td>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Date</b></td>
            <td
              valign = {'top'}
              width = "20%"
            >
              {prf_Data.createdAt == null ? '' : moment(prf_Data.createdAt).format('YYYY-MM-DD')}
            </td>
          </tr>
          <tr>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Incident</b></td>
            <td
              valign = {'top'}
              width = "40%"
            >
              { 
                prf_Data.patient_history == null || prf_Data.patient_history.exam_objservation == null ? '' : prf_Data.patient_history.exam_objservation
              }
            </td>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Time</b></td>
            <td
              valign = {'top'}
              width = "20%"
            > {time} </td>
          </tr>
          <tr>
            <td
              valign = {'top'}
              width = "20%"
            ><b>Incident Address</b></td>
            <td
              colSpan = {3}
              valign = {'top'}
            >
              {prf_Data.call_information == null || prf_Data.call_information.transport_from == null ? '' : prf_Data.call_information.transport_from}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0 , borderBottomWidth : 0 }}
        width="99%"
      >
        <tbody>
          <tr >
            <td
              style = {{paddingTop : 8, fontWeight : 'bold'}}
              valign = {'top'}
            >Indemnity Form and Legal Waiver</td>
          </tr>
          <tr >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >Please read completely before signing below</td>
          </tr>
          <tr >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >Because it is sometimes impossible to recognize actual or potential medical problems outside the hospital, Legacy Emergency Specialists strongly encourage you to be evaluated, treated if neccessary, and transported to a hospital by ambulance for a more complete examination by the Emergency Unit physician.</td>
          </tr>
          <tr >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >You as a patient have the right to choose not to be evaluated, treated or transported if you wish; however, there is the possiblilty that you could suffer serious complications or even death from conditions that are not apparent at this time.</td>
          </tr>
          <tr >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >By signing below, you are acknowledging that Legacy Emergency Specialists personnel have advised you, and that you understand, the potential harm to your health that may result from your refusal of the recommended care; you release Legacy Emergency Specialists (Pty) Ltd and supporting personnel from liability resulting from refusal of treatement, transport and/or Evaluation.</td>
          </tr>
          <tr  >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >If you change your mind and desire evaluation, treatment, and/or transport to a hospital, you may contact us again telephonically at any time.</td>
          </tr>
          <tr >
            <td
              style = {{paddingTop : 3}}
              valign = {'top'}
            >I acknowledge that Legacy Emergency Specialists personnel explained my condition to me and advised me of some of the potential risk(s) and/or complication(s) which could or would arise from refusal of medical care. I have also been advised that other common risk(s) and/or complication(s) are possible up to and including the loss of life or limb. Being aware that there are known and unknown potential risk(s) and/or complication(s), it is still my desire to refuse the advised medical care.</td>
          </tr>
        </tbody>
      </table>
      <table
        border="1"
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', }}
        width="99%"
      >
        <tbody>
          <tr>
            <td valign = {'top'}><b>Patient Signature</b></td>
            <td valign = {'top'}>
              <img
                height = "20px"
                src = {prf_Data.signature == null ? '' : prf_Data.signature.patient}
                width = "60px"
              />
            </td>
            <td valign = {'top'}><b>Witness Signature</b></td>
            <td valign = {'top'}>
              <img
                height = "20px"
                src = {prf_Data.signature == null ? '' : prf_Data.signature.witness}
                width = "60px"
              />
            </td>
          </tr>
          <tr>
            <td valign = {'top'}><b>Legacy Signature</b></td>
            <td valign = {'top'}>
              <img
                height = "20px"
                src = {prf_Data.crew != null && prf_Data.crew.length > 0 ? prf_Data.crew[0].sign_img : ''}
                width = "60px"
              />
            </td>
            <td valign = {'top'}><b>Witness Name</b></td>
            <td valign = {'top'}>
              {prf_Data.patient_consent == null || prf_Data.patient_consent.witness_name == null ? '' : prf_Data.patient_consent.witness_name}
            </td>
          </tr>
          <tr>
            <td valign = {'top'}><b>Legacy Crew</b></td>
            <td valign = {'top'}>
              {prf_Data.crew != null && prf_Data.crew.length > 0 ? prf_Data.crew[0].name : ''}
            </td>
            <td valign = {'top'}><b>Withness ID</b></td>
            <td valign = {'top'}>
              {prf_Data.patient_consent == null || prf_Data.patient_consent.witness_id == null ? '' : prf_Data.patient_consent.witness_id}
            </td>
          </tr>
          <tr>
            <td valign = {'top'}><b>HPCSA Reg</b></td>
            <td valign = {'top'}>
              {prf_Data.crew != null && prf_Data.crew.length > 0 ? prf_Data.crew[0].number : ''}
            </td>
            <td valign = {'top'}><b>Witness Relation</b></td>
            <td valign = {'top'}>
              {prf_Data.patient_consent == null || prf_Data.patient_consent.witness_relation == null ? '' : prf_Data.patient_consent.witness_relation}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
        width="99%"
      >
        <tbody><tr> <td
          height = "12"
          style={{textAlign : 'left'}}
        ><strong>Person Capacity</strong></td></tr></tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000', borderTopWidth : 0, borderBottomWidth : 0 }}
        width="99%"
      >
        <tbody>
          <tr >
            <td valign = {'top'}  >Orientated to person, time and place?</td>
            <td
              align = "center"
              valign = {'top'}
              width = "50"
            >
              {prf_Data.patient_consent == null || prf_Data.patient_consent.oriented_person == null ? '' : prf_Data.patient_consent.oriented_person}
            </td>
          </tr>
          <tr >
            <td valign = {'top'} >Coherent Speech?</td>
            <td
              align = "center"
              valign = {'top'}
              width = "50"
            >
              {prf_Data.patient_consent == null || prf_Data.patient_consent.coherent_speech == null ? '' : prf_Data.patient_consent.coherent_speech}
            </td>
          </tr>
          <tr >
            <td valign = {'top'} >Auditory and/or visual hallucinations</td>
            <td
              align = "center"
              valign = {'top'}
              width = "50"
            >
              {prf_Data.patient_consent == null || prf_Data.patient_consent.auditory == null ? '' : prf_Data.patient_consent.auditory}
            </td>
          </tr>
          <tr >
            <td valign = {'top'} >Suicidal or Homicida ? SAPS notified</td>
            <td
              align = "center"
              valign = {'top'}
              width = "50"
            >
              {prf_Data.patient_consent == null || prf_Data.patient_consent.suicidal == null ? '' : prf_Data.patient_consent.suicidal}
            </td>
          </tr>
          <tr>
            <td valign = {'top'} >Under influence of alcohol/narcotics</td>
            <td
              align = "center"
              valign = {'top'}
              width = "50"
            >
              {prf_Data.patient_consent == null || prf_Data.patient_consent.alcohol == null ? '' : prf_Data.patient_consent.alcohol}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word', borderWidth : 1, borderStyle : 'solid', borderColor : '#000',borderBottomWidth : 0 ,borderTopWidth : 0 , }}
        width="99%"
      >
        <tbody><tr> <td
          height = "12"
          style={{textAlign : 'left'}}
        ><strong>Vital Signs</strong></td></tr></tbody>
      </table>
      <table
        border = "1"
        style = {{ fontSize : '60%', lineHeight : '10px', overflowWrap: 'anywhere', tableLayout: 'fixed', wordWrap: 'break-word',}}
        width="99%"
      >
        <tbody>
          <tr >
            <td valign = {'top'}>BP</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.blood_pressure == null ? '' : prf_Data.vital_1.blood_pressure}
            </td>
            <td valign = {'top'}>Pulse</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.pulse == null ? '' : prf_Data.vital_1.pulse}
            </td>
          </tr>
          <tr >
            <td valign = {'top'}>Sats</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.spo2 == null ? '' : prf_Data.vital_1.spo2}
            </td>
            <td valign = {'top'}>R Rate</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.respiratory_rate == null ? '' : prf_Data.vital_1.respiratory_rate}
            </td>
          </tr>
          <tr >
            <td valign = {'top'}>GCS</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.gcs == null ? '' : prf_Data.vital_1.gcs}
            </td>
            <td valign = {'top'}>Pain</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.pain_score == null ? '' : prf_Data.vital_1.pain_score}
            </td>
          </tr>
          <tr >
            <td valign = {'top'}>Pupils</td>
            <td valign = {'top'}>
              Left: 
              {prf_Data.vital_1 == null && prf_Data.vital_1.pupils_left == null ? '' : prf_Data.vital_1.pupils_left}
              ; Right: 
              {prf_Data.vital_1 == null && prf_Data.vital_1.pupils_right == null ? '' : prf_Data.vital_1.pupils_right}
            </td>
            <td valign = {'top'}>HGT</td>
            <td valign = {'top'}>
              {prf_Data.vital_1 == null && prf_Data.vital_1.hgt == null ? '' : prf_Data.vital_1.hgt}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
  return html
}

const send_AcceptForm= (prf_Data) => {
  // to : 'prf@legacyems.co.za', 
  let acceptform_html = getAcceptFormHtmlString(prf_Data);
  console.log('acceptform_html')
  console.log(acceptform_html)
  _http.doPost('main/send_email', 
    {
      to : 'prf@legacyems.co.za', 
      orientation : 'portrait',
      subject : 'Please find attached from Legacy Emergency Specialists',
      pdf_name : 'Patient-Agreement-' + prf_Data.id + '-' + prf_Data.patient_details.first_name + '-' +
          prf_Data.patient_details.last_name + '-' + prf_Data.patient_details.id_no + '-' + prf_Data.medical_aid.medical_aid_name + '.pdf',
      html_str : acceptform_html
    }, 
    (data) => {
      if(data.status == false)
      {
        alert(JSON.stringify(data.data));
        console.log('reply', data.data);
      }
      else
      {
        alert(data.data);
        console.log('reply', data.data);
      }
    },
    (err) => {
      console.log(err);
      alert('Error occured');
    }
  );
}

const send_RefusalForm = (prf_Data) => {
  // to : 'prf@legacyems.co.za', 
  let refuseform_html = getRefuseFormHtmlString(prf_Data);
  console.log('refuseform_html')
  console.log(refuseform_html)

  _http.doPost('main/send_email', 
    {
      to : 'prf@legacyems.co.za', 
      orientation : 'portrait',
      subject : 'Please find attached from Legacy Emergency Specialists',
      pdf_name : 'Refusal-Treatment-' + prf_Data.id + '-' + prf_Data.patient_details.first_name + '-' +
          prf_Data.patient_details.last_name + '-' + prf_Data.patient_details.id_no + '-' + prf_Data.medical_aid.medical_aid_name + '.pdf',
      html_str : refuseform_html
    }, 
    (data) => {
      if(data.status == false)
      {
        alert(JSON.stringify(data.data));
        console.log('reply', data.data);
      }
      else
      {
        alert(data.data);
        console.log('reply', data.data);
      }
    },
    (err) => {
      console.log(err);
    }
  );
}


export {send_AcceptForm, send_RefusalForm, getAcceptFormHtmlString, getRefuseFormHtmlString};
