import React, {useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Breadcrumbs, Grid, IconButton, Link } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {ArrowBack} from '@material-ui/icons';
import FirstAidKitManage from '../FirstAidKitManage';
import FEManage from '../FEManage';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 'calc(100% - 54px)'
  },
  padding: {
    // padding: theme.spacing(3),
  },
  demo1: {
    padding : 20,
    width : '100%',
    height : '100%',  
    display : 'flex',
    flexDirection : 'column',
    backgroundColor: theme.palette.background.paper,
  },
}));


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff'
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab
  disableRipple
  {...props}
               />);

const EquipmentManage = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const tab_names = ['Profile', 'Notification'];
  const parsed = qs.parse(document.location.search);

  useEffect(() => {
    let tab_id = parsed.tab;
    if(tab_id == null){
      setValue(0);
    }
    else if (tab_id == 0)
    {
      setValue(0);
    }
    else if (tab_id == 1)
    {
      setValue(1);
    }
    else {
      setValue(0);
    }
  }, [])

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    document.location.href = '/epr_form/equip?tab=' + newValue + '&site=' + parsed.site
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        style = {{height : '100%'}}
      >
        <Grid
          container
          item
          lg={4}
          md = {4}
          style = {{alignItems : 'center', padding : 12}}
          xs = {12}
        >
          <IconButton
            onClick = {() => {document.location.href = '/epr_form'}}
            variant = "contained"
          >
            <ArrowBack />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            style = {{marginLeft : 10}}
          >
            <Link color="inherit"  > 
              {/* {site_name} */}
                Go To Site List
            </Link>
            {/* <Link color="inherit" >
                patients
              </Link> */}
          </Breadcrumbs>
        </Grid>
        <div className={classes.demo1}>
          <AntTabs
            aria-label="ant example"
            onChange={handleChange}
            value={value}
          >
            <AntTab label="First Aid Kits" />
            <AntTab label="Fire Extinguisher" />
          </AntTabs>
          {
            value == 0 ?  <FirstAidKitManage /> : null
          }
          {
            value == 1? <FEManage /> : null
          }
        </div>
      </Grid>
    </div>
  );
};

export default EquipmentManage;
