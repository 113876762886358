import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Button, Select, DialogActions
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import SignatureCanvas from 'react-signature-canvas'
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {LoadingButton} from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  dialog: {
    alignItems : 'flex-start',
    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  sigCanvas : {
    width : '100%',
    height: '250px',
  }
}));

const MemberItem = (props) => {
  const {member_data, onDeleteMember, ...rest} = props;
  const classes = useStyles();
  
  const sign_img_ref = useRef(null);
  const signature_canvas_item = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);
  
  const [number, setNumber] = useState('');
  const [role, setRole] = useState('');
  const [signImg, setSignImg] = useState('');
  const [memberData, setMemberData] = useState({});
  
  useEffect(() => {
    setMemberData(member_data);
    setNumber(member_data.number == null ? '' : member_data.number);
    setRole(member_data.role == null ? '' : member_data.role);
    // setSignImg(member_data.sign_img == null ? '' : member_data.sign_img);
    
  }, [member_data]);

  const _handleImageChange = (e) => {
    e.preventDefault();
    try
    {
      let reader = new FileReader();
      let file = e.target.files[0];
    
      reader.onloadend = () => {
        // setAvatarForm(file);
        setSignImg(reader.result);
      }
        
      reader.readAsDataURL(file)
    }
    catch(err)
    {
      console.log('handle image error');
    }
  }

  const updateMemberData = () => {
    let sign_img = signature_canvas_item.current.toDataURL();
    setLoading(true);
    _http.doPost('auth/update_user', 
      {
        user_id : memberData.id,
        number : number,
        role: role,
        sign_img: sign_img
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showEditDialog(false);
          setMemberData(data.data);
          setNumber(data.data.number == null ? '' : data.data.number);
          setRole(data.data.role == null ? '' : data.data.role);
          setSignImg(data.data.sign_img == null ? '' : data.data.sign_img);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const deleteMember = () => {
    setLoading(true);
    _http.doPost('auth/delete_user', 
      {
        user_id : memberData.id
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          onDeleteMember();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }


  const onClearSignature = () => {
    if(signature_canvas_item != null && signature_canvas_item.current != null)
    {
      signature_canvas_item.current.clear();
    }
  }
  

  return (
    <Grid
      container
      item
      lg={12}
      md = {12}
      xs = {12}
    >
      <Card style = {{width : '100%'}}>
        <CardContent style = {{padding : 8}}>
          <Grid
            container
            item
            lg={12}
            md = {12}
            sm = {12}
          >
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {memberData.name}
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {memberData.email}
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {memberData.role}
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              {memberData.number}
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 5}}
              xs = {12}
            >
              <img
                height="80px"
                height = "50px"
                src={memberData.sign_img}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md = {2}
              style = {{padding : 10}}
              xs = {12}
            >
              <IconButton
                color="#151b26"
                onClick = {() => 
                {
                  showEditDialog(true);
                }
                }
                style = {{padding : 4}}
                variant="outlined"
              >
                <EditIcon style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 18}}>Edit</span>
              </IconButton>
              <IconButton
                color="#151b26"
                onClick = {() => 
                {
                  showDeleteDialog(true);
                }
                }
                style = {{padding : 4}}
                variant="outlined"
              >
                <DeleteIcon style = {{width : 22, height : 22}}/>
                <span style = {{fontSize : 18}}>Delete</span>
              </IconButton>
            </Grid>
          </Grid>  
        </CardContent>
      </Card>
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showEditDialog(false)}
        onEntered = {() => {
          if(signature_canvas_item != null && signature_canvas_item.current != null)
          {
            signature_canvas_item.current.fromDataURL(memberData.sign_img);
          }
        }}
        open={isEditDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Edit crew member's detail.</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog}>
          <TextField
            autoFocus
            fullWidth
            label="member number"
            margin="dense"
            onChange = {event => setNumber(event.target.value)}
            value = {number}
          />
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
            <Select
              label="Role"
              native
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option
                aria-label="None"
                value=""
              />
              {
                ['employee', 'admin'].map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Select>
          </FormControl>
          <Grid
            item
            lg={12}
            md = {12}
            style = {{padding : 10}}
            xs = {12}
          >
            <div style = {{display : 'flex', alignItems : 'center'}}>
              <span style = {{fontSize : 14, fontWeight : 'bold', marginRight : 8}}>Signature</span>
              {/* <input  hidden = {true} ref = {sign_img_ref}  type="file" 
                      accept="image/*"
                      onChange={(e)=>_handleImageChange(e)} /> */}
              {/* <IconButton onClick = {() => { 
                        if(sign_img_ref != null) {
                          sign_img_ref.current.click();
                        }}}
                    >
                        <CloudUploadIcon />
                    </IconButton> */}
              <IconButton
                onClick = {() => onClearSignature()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style = {{display : 'flex', alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
              {/* {
                      sign_img == null || sign_img == '' ?
                      null : <img src = {sign_img} style = {{ height : '150px', width : '100%', objectFit : 'contain'}} />
                    } */}
              <SignatureCanvas
                canvasProps={{  className: classes.sigCanvas}}
                penColor="black"
                ref = {signature_canvas_item}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showEditDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Update"
            label_loading = "Update"
            loading = {loading}
            onClick = {updateMemberData}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this member ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {deleteMember}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default MemberItem;
