import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {  Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider } from '@material-ui/core';
import {Add,} from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading} from 'components';
import MemberItem from './Component/MemberItem';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width : '100%'
  }
}));

const AuthManageHome = () => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [pageLoaded, setPageLoad] = useState(false);

  useEffect(() => {
    get_all_users();
  }, []);

  const get_all_users = () => {
    setPageLoad(false);
    _http.doPost('auth/users/get', 
      {}, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setUsers(data.data);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const goSignUp = () => {
    document.location.href = '/register';
  }

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              <Button
                color = "primary"
                onClick = {goSignUp}
                startIcon = {<Add />}
                variant = "contained"
              >Add Member</Button> 
            </Grid>
            <Grid
              item
              lg={12}
              md = {12}
              style = {{margin : 10}}
              xs = {12}
            >
              {
                users.map((user, index) => (
                  <MemberItem
                    member_data = {user}
                    onDeleteMember = {get_all_users}
                  />
                ))
              }
            </Grid>
          </Grid>
      } 
    </div>
  );
};

export default AuthManageHome;
