import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Card, CardContent, IconButton, Dialog, DialogContent, DialogTitle, TextField, 
  FormControl, InputLabel, Select, DialogActions, Divider, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import {Add, Close, CloudUpload, Crop, PhotoCamera } from '@material-ui/icons';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import AuditItem from './Component/AuditItem';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import * as qs from 'query-string';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    width : '100%'
  },
  dialog: {
    alignItems : 'flex-start',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const FakAuditManage = () => {
  const classes = useStyles();
  
  const [loading , setLoading] = useState(false); 
  const [pageLoaded, setPageLoad] = useState(false);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isType1Dialog, showType1Dialog] = useState(false);
  const [isType2Dialog, showType2Dialog] = useState(false);
  const [isDeleteDialog, showDeleteDialog] = useState(false);
  const [isCropDialog, showCropDialog] = useState(false);
  const [isCameraDialog, showCameraDialog] = useState(false);

  const [cur_audit, setCurAudit] = useState({});
  const [audits, setAudits] = useState([]);
  const [type1_kits, setType1kits] = useState([]);
  const [type2_kits, setType2kits] = useState([]);

  const [type1_dialog_id, setType1DialogID] = useState('Add');
  const [type2_dialog_id, setType2DialogID] = useState('Add');

  const [cur_type, setCurType] = useState('type1');

  const [photo_id, setPhotoID] = useState('old_seal');
  const [cropper, setCropper] = useState('');

  const parsed = qs.parse(document.location.search);
  let registered_user = _sessionStorage.getItem('user');

  useEffect(() => {
    get_kits();
    get_audits();
  }, []);

  const get_kits = () => {
    // setPageLoad(false);
    _http.doPost('epr/get_kits', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        // setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else 
        {
          let tmp_type1_kits = []
          let tmp_type2_kits = []
          for(var i = 0; data.data.length != null && i < data.data.length; i ++)
          {
            if(data.data[i].type == 'Regulation 7')
            {
              tmp_type2_kits.push(data.data[i])
            }
            else {
              tmp_type1_kits.push(data.data[i])
            }
          }
          setType1kits(tmp_type1_kits);
          setType2kits(tmp_type2_kits);
        }
      },
      (err) => {
        // setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const get_audits = () => {
    setPageLoad(false);
    _http.doPost('epr/get_audits', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else 
        {
          let tmp_audits = []
          for(var i = 0; data.data.length != null && i < data.data.length; i ++)
          {
            if(data.data[i].type == 'fakit')
            {
              tmp_audits.push(data.data[i]);
            }
          }
          setAudits(tmp_audits);
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const addType1 = () => {
    if(fakit_type1_data.fakit_id_no == null || fakit_type1_data.fakit_id_no == '') {
      alert('please select first aid kit which you are going to inspect')
      return;
    }

    let target_fakit = {}
    for(var i = 0; i < type1_kits.length; i ++)
    {
      if(type1_kits[i].id_no == fakit_type1_data.fakit_id_no)
      {
        target_fakit = type1_kits[i];
        break;
      }
    }
    setLoading(true);
    _http.doPost('epr/add_audit', 
      {
        site_id : parsed.site,
        type : 'fakit',
        fakit_id : target_fakit.id,
        fakit_id_no : target_fakit.id_no,
        fakit_location : target_fakit.location,
        fakit_type : target_fakit.type,
        fakit_seal_no_1 : target_fakit.seal_no_1,
        fakit_seal_no_2 : target_fakit.seal_no_2,
        audit_data : fakit_type1_data,
        author : {
          id : registered_user.id,
          name : registered_user.name,
          sign_img : registered_user.sign_img
        }
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showType1Dialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const editType1 = () => {
    if(cur_audit == null || cur_audit.id == null)
    {
      return;
    }
    if(fakit_type1_data.fakit_id_no == '') {
      alert('please select first aid kit which you are going to inspect')
      return;
    }

    let target_fakit = {}
    for(var i = 0; i < type1_kits.length; i ++)
    {
      if(type1_kits[i].id_no == fakit_type1_data.fakit_id_no)
      {
        target_fakit = type1_kits[i];
        break;
      }
    }
    setLoading(true);
    _http.doPost('epr/edit_audit', 
      {
        site_id : parsed.site,
        audit_id : cur_audit.id,
        type : 'fakit',
        fakit_id : target_fakit.id,
        fakit_id_no : target_fakit.id_no,
        fakit_location : target_fakit.location,
        fakit_type : target_fakit.type,
        fakit_seal_no_1 : target_fakit.seal_no_1,
        fakit_seal_no_2 : target_fakit.seal_no_2,
        audit_data : fakit_type1_data
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showType1Dialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const addType2 = () => {
    if(fakit_type2_data.fakit_id_no == null || fakit_type2_data.fakit_id_no == '') {
      alert('please select first aid kit which you are going to inspect')
      return;
    }

    let target_fakit = {}
    for(var i = 0; i < type2_kits.length; i ++)
    {
      if(type2_kits[i].id_no == fakit_type2_data.fakit_id_no)
      {
        target_fakit = type2_kits[i];
        break;
      }
    }
    setLoading(true);
    _http.doPost('epr/add_audit', 
      {
        site_id : parsed.site,
        type : 'fakit',
        fakit_id : target_fakit.id,
        fakit_id_no : target_fakit.id_no,
        fakit_location : target_fakit.location,
        fakit_type : target_fakit.type,
        fakit_seal_no_1 : target_fakit.seal_no_1,
        fakit_seal_no_2 : target_fakit.seal_no_2,
        audit_data : fakit_type2_data,
        author : {
          id : registered_user.id,
          name : registered_user.name,
          sign_img : registered_user.sign_img
        }
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showType2Dialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const editType2 = () => {
    if(cur_audit == null || cur_audit.id == null)
    {
      return;
    }
    if(fakit_type2_data.fakit_id_no == '') {
      alert('please select first aid kit which you are going to inspect')
      return;
    }

    let target_fakit = {}
    for(var i = 0; i < type2_kits.length; i ++)
    {
      if(type2_kits[i].id_no == fakit_type2_data.fakit_id_no)
      {
        target_fakit = type2_kits[i];
        break;
      }
    }
    setLoading(true);
    _http.doPost('epr/edit_audit', 
      {
        site_id : parsed.site,
        audit_id : cur_audit.id,
        type : 'fakit',
        fakit_id : target_fakit.id,
        fakit_id_no : target_fakit.id_no,
        fakit_location : target_fakit.location,
        fakit_type : target_fakit.type,
        fakit_seal_no_1 : target_fakit.seal_no_1,
        fakit_seal_no_2 : target_fakit.seal_no_2,
        audit_data : fakit_type2_data
      }, 
      (data) => {
        setLoading(false);
        
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showType2Dialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const delete_audit = () => {
    if(cur_audit == null || cur_audit.id == null)
    {
      return;
    }
    setLoading(true);
    _http.doPost('epr/delete_audit', 
      {
        site_id : parsed.site,
        audit_id : cur_audit.id
      }, 
      (data) => {
        setLoading(false);
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          showDeleteDialog(false);
          get_audits();
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert('Error occured');
      }
    );
  }
  

  const [fakit_type1_data, setFakitType1Data] = useState({});
  const handleType1Change = (event) => {
    event.persist();
    setFakitType1Data(fakit_type1_data => ({
      ...fakit_type1_data,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const [fakit_type2_data, setFakitType2Data] = useState({});
  const handleType2Change = (event) => {
    event.persist();
    setFakitType2Data(fakit_type2_data => ({
      ...fakit_type2_data,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const handleType1_Burnshield_dressings_ExpDate_Change = (date) => {
    setFakitType1Data(fakit_type1_data => ({
      ...fakit_type1_data,
      burnshield_dressings_exp_date : date
    }));
  };

  const handleType1_Sterile_Gauze_ExpDate_Change = (date) => {
    setFakitType1Data(fakit_type1_data => ({
      ...fakit_type1_data,
      sterile_gauze_exp_date : date
    }));
  };

  const handleType1_Saline_Pour_ExpDate_Change = (date) => {
    setFakitType1Data(fakit_type1_data => ({
      ...fakit_type1_data,
      saline_pour_exp_date : date
    }));
  };

  const handleType1_Cetrimide_ExpDate_Change = (date) => {
    setFakitType1Data(fakit_type1_data => ({
      ...fakit_type1_data,
      cetrimide_exp_date : date
    }));
  };

  const handleType1_SetPhoto = (photo, type) => {
    if(type == 'old_seal')
    {
      setFakitType1Data(fakit_type1_data => ({
        ...fakit_type1_data,
        old_seal_photo : photo
      }));
    }
    else if(type == 'new_seal')
    {
      setFakitType1Data(fakit_type1_data => ({
        ...fakit_type1_data,
        new_seal_photo : photo
      }));
    }
    else if(type == 'kit')
    {
      setFakitType1Data(fakit_type1_data => ({
        ...fakit_type1_data,
        kit_photo : photo
      }));
    }
  };

  const handleType2_SetPhoto = (photo, type) => {
    if(type == 'old_seal')
    {
      setFakitType2Data(fakit_type2_data => ({
        ...fakit_type2_data,
        old_seal_photo : photo
      }));
    }
    else if(type == 'new_seal')
    {
      setFakitType2Data(fakit_type2_data => ({
        ...fakit_type2_data,
        new_seal_photo : photo
      }));
    }
    else if(type == 'kit')
    {
      setFakitType2Data(fakit_type2_data => ({
        ...fakit_type2_data,
        kit_photo : photo
      }));
    }
  };

  const handleType2_Sterile_Gauze_ExpDate_Change = (date) => {
    setFakitType2Data(fakit_type2_data => ({
      ...fakit_type2_data,
      sterile_gauze_exp_date : date
    }));
  };

  const getCropData = () => {
    if (cropper != null) {
      if(cur_type == 'type1')
      {
        handleType1_SetPhoto(cropper.getCroppedCanvas().toDataURL(), photo_id)
      }
      else
      {
        handleType2_SetPhoto(cropper.getCroppedCanvas().toDataURL(), photo_id)
      }
    }
  };

  const getCropImgSrc = () => {
    if(cur_type == 'type1')
    {
      if(photo_id == 'old_seal')
      {
        return fakit_type1_data.old_seal_photo
      }
      else if(photo_id == 'new_seal')
      {
        return fakit_type1_data.new_seal_photo
      }
      else
      {
        return fakit_type1_data.kit_photo
      }
    }
    else
    {
      if(photo_id == 'old_seal')
      {
        return fakit_type2_data.old_seal_photo
      }
      else if(photo_id == 'new_seal')
      {
        return fakit_type2_data.new_seal_photo
      }
      else
      {
        return fakit_type2_data.kit_photo
      }
    }
  }

  return (
    <div className={classes.root}>
      {
        pageLoaded == false ?
          <PageLoading />
          :
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            <Grid
              item
              lg={12}
              md = {12}
              xs = {12}
            >
              <Button 
                color = "primary"
                onClick = {() => {
                  setFakitType2Data({}); setType2DialogID('Add'); showType2Dialog(true);
                }}
                startIcon = {<Add />}
                style = {{margin : 10}}
                variant = "contained" 
              >
                Add Audit(Regulation 7)
              </Button> 
              <Button 
                color = "primary"
                onClick = {() => {
                  setFakitType1Data({}); setType1DialogID('Add'); showType1Dialog(true);
                }}
                startIcon = {<Add />}
                style = {{margin : 10}}
                variant = "contained" 
              >
                Add Audit(Mine Spec) 
              </Button> 
            </Grid>
            <Grid
              container
              lg={12}
              md = {12}
              xs = {12}
            >
              <Card style = {{width : '100%'}}>
                <CardContent style = {{padding : 8}}>
                  <Grid
                    container
                    item
                    lg={12}
                    md = {12}
                    sm = {12}
                  >
                    <Grid
                      item
                      lg={3}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Type</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Identification No</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Location</Grid>
                    <Grid
                      item
                      lg={2}
                      md = {2}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Created Date</Grid>
                    <Grid
                      item
                      lg={3}
                      md = {3}
                      style = {{padding : 5, fontSize : 14, fontWeight : 'bold'}}
                      xs = {12}
                    >Action</Grid>
                  </Grid>
                </CardContent>
              </Card>
              {
                audits.map((audit, index) => (
                  <AuditItem 
                    audit_data = {audit}
                    key = {index} 
                    onDelete = {(audit_item) => {
                      setCurAudit(audit_item);
                      showDeleteDialog(true);
                    }} 
                    onEdit = {(audit_item) => {
                      setCurAudit(audit_item);

                      if(audit_item.fakit_type == 'Regulation 7')
                      {
                        setFakitType2Data(audit_item.audit_data);
                        setType2DialogID('Edit');
                        showType2Dialog(true)
                      }
                      else
                      {
                        setFakitType1Data(audit_item.audit_data);
                        setType1DialogID('Edit');
                        showType1Dialog(true)
                      }
                    }} 
                  />
                ))
              }
            </Grid>
          </Grid>
      } 

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showCameraDialog(false)}
        open={isCameraDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          <Camera  
            handleTakePhoto = {(data) => {
              if(cur_type == 'type1')
              {
                handleType1_SetPhoto(data, photo_id); 
              }
              else {
                handleType2_SetPhoto(data, photo_id); 
              }
              showCameraDialog(false);
            }}
            handleTakePhotoAnimationDone  = {(data) => {
              if(cur_type == 'type1')
              {
                handleType1_SetPhoto(data, photo_id); 
              }
              else {
                handleType2_SetPhoto(data, photo_id); 
              }
              showCameraDialog(false);
            }}
          />    
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onBackdropClick = {() => showCropDialog(false)}
        open={isCropDialog}
      >
        <DialogContent style = {{alignItems : 'flex-start', width : '100%'  }} >
          <div style = {{display : 'flex', alignItems : 'center', width : '100%'}} >
            <Button 
              onClick = {() => {
                showCropDialog(false);
                getCropData();
              }}
              startIcon = {<Crop />}
              variant = "outlined"
            > Crop</Button>
          </div>
          <Cropper
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            guides
            initialAspectRatio={1}
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            responsive
            src={getCropImgSrc()}
            style={{ height: 400, width: '100%' }} // https://github.com/fengyuanchen/cropperjs/issues/671
            viewMode={1}
          />   
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => showDeleteDialog(false)}
        open={isDeleteDialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>Are you sure to delete this Audit ?</span>
        </DialogTitle>
        <DialogContent className = {classes.dialog} />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showDeleteDialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = "Delete"
            label_loading = "Delete"
            loading = {loading}
            onClick = {delete_audit}
          />
        </DialogActions>
      </Dialog>
      
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown 
        onEntered = {() => {}}
        open={isType1Dialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>{type1_dialog_id} check list of first aid kit (Mine Spec)</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            
            <Grid
              item
              lg={12}
              md = {12}
              style = {{marginTop : 20}}
              xs = {12}
            >
              <FormControl
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-age-native-simple">Select a registered First Aid Kit</InputLabel>
                <Select
                  inputProps={{
                    name: 'fakit_id_no',
                    id: 'outlined-age-native-simple',
                  }} 
                  label="Select a registered First Aid Kit"
                  native
                  onChange={(e) => handleType1Change(e)}
                  value={fakit_type1_data.fakit_id_no}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    type1_kits.map((kit) => (
                      <option value={kit.id_no}>{kit.id_no}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Old Seal No</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="Old Seal No 1"
                    name="old_seal_no_1"
                    onChange={(e) => handleType1Change(e)}
                    size = "medium"
                    value={fakit_type1_data.old_seal_no_1 || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="Old Seal No 2"
                    name="old_seal_no_2"
                    onChange={(e) => handleType1Change(e)}
                    size = "medium"
                    value={fakit_type1_data.old_seal_no_2 || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>New Seal No</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="New Seal No 1"
                    name="new_seal_no_1"
                    onChange={(e) => handleType1Change(e)}
                    size = "medium"
                    value={fakit_type1_data.new_seal_no_1 || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="New Seal No 2"
                    name="new_seal_no_2"
                    onChange={(e) => handleType1Change(e)}
                    size = "medium"
                    value={fakit_type1_data.new_seal_no_2 || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Old Seal Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      setCurType('type1')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      setCurType('type1')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType1_SetPhoto('', 'old_seal')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type1_data.old_seal_photo == null || fakit_type1_data.old_seal_photo == '' ?
                        null : <img
                          src = {fakit_type1_data.old_seal_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>New Seal Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('new_seal')
                      setCurType('type1')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('new_seal')
                      setCurType('type1')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType1_SetPhoto('', 'new_seal')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type1_data.new_seal_photo == null || fakit_type1_data.new_seal_photo == '' ?
                        null : <img
                          src = {fakit_type1_data.new_seal_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Kit Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('kit')
                      setCurType('type1')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('kit')
                      setCurType('type1')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType1_SetPhoto('', 'kit')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type1_data.kit_photo == null || fakit_type1_data.kit_photo == '' ?
                        null : <img
                          src = {fakit_type1_data.kit_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>4 x Burnshield Dressings 10cm x 10cm</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="burnshield_dressings"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.burnshield_dressings}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md = {7}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <span style = {{fontSize : 14}}>Expiry date : </span>
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleType1_Burnshield_dressings_ExpDate_Change(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={
                      fakit_type1_data.burnshield_dressings_exp_date == null ?
                        null : new Date(fakit_type1_data.burnshield_dressings_exp_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>04	x	Sterile	Gauze	75mmx75mm	(5’s)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="sterile_gauze"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.sterile_gauze}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md = {7}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <span style = {{fontSize : 14}}>Expiry date : </span>
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleType1_Sterile_Gauze_ExpDate_Change(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={fakit_type1_data.sterile_gauze_exp_date == null ?
                      null : new Date(fakit_type1_data.sterile_gauze_exp_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Saline	Pour	1000ml</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="saline_pour"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.saline_pour}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md = {7}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <span style = {{fontSize : 14}}>Expiry date : </span>
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleType1_Saline_Pour_ExpDate_Change(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={fakit_type1_data.saline_pour_exp_date == null ?
                      null :  new Date(fakit_type1_data.saline_pour_exp_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>02	x	Cetrimide	0.9%	100ml</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cetrimide"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.cetrimide}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md = {7}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <span style = {{fontSize : 14}}>Expiry date : </span>
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleType1_Cetrimide_ExpDate_Change(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={fakit_type1_data.cetrimide_exp_date == null ?
                      null :   new Date(fakit_type1_data.cetrimide_exp_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>04	x	Cotton	Wool	Roll	50g</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cotton_wool_rolls"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.cotton_wool_rolls}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Scissor</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="scissor"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.scissor}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Safety	Pins	(12’s)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="safety_pins"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.safety_pins}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Forceps	Stainless	Steel</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="forceps_stainless_steel"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.forceps_stainless_steel}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>50	x	Plaster	Strips</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="plaster_strips"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.plaster_strips}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>06	x	Triangular	Bandages	(Disposable)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="triangular_bandages"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.triangular_bandages}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>04	x	FAD	Dressing	No.	5	(150mmx200mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="fad_dressing_5"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.fad_dressing_5}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>08	x	FAD	Dressing	No.	3	(75mmx100mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="fad_dressing_3"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.fad_dressing_3}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>12	x	FAD	Dressing	No.	2	(50mmx100mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="fad_dressing_2"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.fad_dressing_2}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>02	x	Shell	Abdominal	Bandage	(300mmx300mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="shell_abdominal_bandage"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.shell_abdominal_bandage}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>02	x	Crepe	Bandage	(75mmx4,5m)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="crepe_bandage"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.crepe_bandage}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Gloves	Examination	– Large	(6	pairs)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="gloves_examination"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.gloves_examination}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>12	x	Splint	Plastic	Interlocking</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="splint_plastic_interlocking"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.splint_plastic_interlocking}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>02	x	CPR	Mouthpiece	&	Instructions</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cpr_mouthpiece_instruction"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.cpr_mouthpiece_instruction}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	150ml	Eyewash	Bottle</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="eyewash_bottle"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.eyewash_bottle}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Cervical	Collar	Disposable	– Large</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cervical_collar_disposable"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.cervical_collar_disposable}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Rescue	Space	Blanket</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="rescue_space_blanket"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.rescue_space_blanket}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Basic	First	Aid	Booklet</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="basic_first_aid_booklet"
                    onChange={(e) => handleType1Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type1_data.basic_first_aid_booklet}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>01	x	Medical	Waste	Bag	Red</span></legend>
              <Grid
                item
                lg={12}
                md = {12}
                style = {{padding : 5, paddingRight : 8,}}
                xs = {12}
              >
                <RadioGroup
                  name="medical_waste_bag_red"
                  onChange={(e) => handleType1Change(e)}
                  style = {{display : 'block'}}
                  value={fakit_type1_data.medical_waste_bag_red}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="Yes"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    value="No"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Replaced"
                    value="Replaced"
                  />
                </RadioGroup>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Additional Notes / Recommendations</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <TextField
                  fullWidth
                  multiline
                  name="additional_note"
                  onChange={(e) => handleType1Change(e)}
                  size = "medium"
                  value={fakit_type1_data.additional_note || ''}
                  variant="outlined"
                />
              </Grid>
            </fieldset>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showType1Dialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = {type1_dialog_id}
            label_loading = {type1_dialog_id}
            loading = {loading}
            onClick = {() =>{
              if(type1_dialog_id == 'Add')
              {
                addType1();
              }
              else{
                editType1();
              }
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown 
        onEntered = {() => {}}
        open={isType2Dialog}
      >
        <DialogTitle id="form-dialog-title" >
          <span style = {{fontWeight : 'bold'}}>{type2_dialog_id} check list of first aid kit (Regulation 7)</span>
        </DialogTitle>
        <Divider />
        <DialogContent className = {classes.dialog}>
          <Grid
            container
            lg={12}
            md = {12}
            xs = {12}
          >
            
            <Grid
              item
              lg={12}
              md = {12}
              style = {{marginTop : 20}}
              xs = {12}
            >
              <FormControl
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-age-native-simple">Select a registered First Aid Kit</InputLabel>
                <Select
                  inputProps={{
                    name: 'fakit_id_no',
                    id: 'outlined-age-native-simple',
                  }} 
                  label="Select a registered First Aid Kit"
                  native
                  onChange={(e) => handleType2Change(e)}
                  value={fakit_type2_data.fakit_id_no}
                >
                  <option
                    aria-label="None"
                    value=""
                  />
                  {
                    type2_kits.map((kit) => (
                      <option value={kit.id_no}>{kit.id_no}</option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Old Seal No</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="Old Seal No 1"
                    name="old_seal_no_1"
                    onChange={(e) => handleType2Change(e)}
                    size = "medium"
                    value={fakit_type2_data.old_seal_no_1 || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="Old Seal No 2"
                    name="old_seal_no_2"
                    onChange={(e) => handleType2Change(e)}
                    size = "medium"
                    value={fakit_type2_data.old_seal_no_2 || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>New Seal No</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="New Seal No 1"
                    name="new_seal_no_1"
                    onChange={(e) => handleType2Change(e)}
                    size = "medium"
                    value={fakit_type2_data.new_seal_no_1 || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <TextField
                    fullWidth
                    label="New Seal No 2"
                    name="new_seal_no_2"
                    onChange={(e) => handleType2Change(e)}
                    size = "medium"
                    value={fakit_type2_data.new_seal_no_2 || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Old Seal Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      setCurType('type2')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('old_seal')
                      setCurType('type2')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType2_SetPhoto('', 'old_seal')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type2_data.old_seal_photo == null || fakit_type2_data.old_seal_photo == '' ?
                        null : <img
                          src = {fakit_type2_data.old_seal_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>New Seal Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('new_seal')
                      setCurType('type2')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('new_seal')
                      setCurType('type2')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType2_SetPhoto('', 'new_seal')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type2_data.new_seal_photo == null || fakit_type2_data.new_seal_photo == '' ?
                        null : <img
                          src = {fakit_type2_data.new_seal_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Kit Photo</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                style = {{padding : 12}}
                xs = {12}
              >
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <IconButton
                    onClick = {() => {
                      setPhotoID('kit')
                      setCurType('type2')
                      showCameraDialog(true)
                    }}
                  ><PhotoCamera /></IconButton>
                  <IconButton
                    onClick = {() => {
                      setPhotoID('kit')
                      setCurType('type2')
                      showCropDialog(true);
                    }}
                  ><Crop /></IconButton>
                  <IconButton onClick = {() => handleType2_SetPhoto('', 'kit')}><Close /></IconButton>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md = {6}
                  xs = {12}
                >
                  <div style = {{ alignItems : 'center', justifyContent : 'center', borderWidth : 1, borderColor : '#000', borderStyle : 'solid', borderRadius : 5}}>
                    {
                      fakit_type2_data.kit_photo == null || fakit_type2_data.kit_photo == '' ?
                        null : <img
                          src = {fakit_type2_data.kit_photo}
                          style = {{ height : '200px', width : '100%',objectFit : 'contain'}}
                               />
                    }
                  </div>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>STERILE GAUZE (5’s) (75mm x 75mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="sterile_gauze"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.sterile_gauze}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={7}
                  md = {7}
                  style = {{padding : 10, paddingRight : 8}}
                  xs = {12}
                >
                  <span style = {{fontSize : 14}}>Expiry date : </span>
                  <DatePicker
                    closeOnScroll
                    dateFormat="dd/MM/yyyy"
                    onChange={date => handleType2_Sterile_Gauze_ExpDate_Change(date)}
                    placeholderText = "dd/mm/yyyy"
                    selected={
                      fakit_type2_data.sterile_gauze_exp_date == null ?
                        null : new Date(fakit_type2_data.sterile_gauze_exp_date)}
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>ANTISEPTIC SOLUTION (100ml)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="antiseptic_solution"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.antiseptic_solution}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>COTTON WOOL ROLLS (50g)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cotton_wool_rolls"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.cotton_wool_rolls}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>SCISSOR</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="scissor"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.scissor}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>SAFETY PINS</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="safety_pins"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.safety_pins}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>FORCEPS STAINLESS STEEL</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="forceps_stainless_steel"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.forceps_stainless_steel}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>PLASTER STRIPS</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="plaster_strips"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.plaster_strips}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>TRIANGULAR BANDAGES</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="triangular_bandages"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.triangular_bandages}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>CONFORM BANDAGES (75mm x 4,5m)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="conform_bandages_75"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.conform_bandages_75}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>CONFORM BANDAGES (100mm x 4,5m)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="conform_bandages_100"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.conform_bandages_100}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>PLASTER ROLL (25mm x 3m)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="plaster_roll"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.plaster_roll}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>FAD Dressing No. 5 (150mm x 200mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="fad_dressing_5"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.fad_dressing_5}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>FAD Dressing No. 3 (75mm x 100mm)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="fad_dressing_3"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.fad_dressing_3}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>EXAMINATION GLOVES (Large & Medium)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="examination_gloves"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.examination_gloves}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>PLASTIC INTERLOCKING SPLINTS</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="plastic_interlocking_splints"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.plastic_interlocking_splints}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>CPR MOUTHPIECES</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="cpr_mouthpieces"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.cpr_mouthpieces}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>ABSORBENT PAD SPILL</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="absorbent_pad_spill"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.absorbent_pad_spill}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>DISINFECTANT SACHETS (30g)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="disinfectant_sachets"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.disinfectant_sachets}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>HIGH RISK GLOVES (Large)</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <Grid
                  item
                  lg={5}
                  md = {5}
                  style = {{padding : 5, paddingRight : 8}}
                  xs = {12}
                >
                  <RadioGroup
                    name="high_risk_gloves"
                    onChange={(e) => handleType2Change(e)}
                    style = {{display : 'block'}}
                    value={fakit_type2_data.high_risk_gloves}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value="No"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Replaced"
                      value="Replaced"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>MEDICAL WASTE BAG RED (Medium)</span></legend>
              <Grid
                item
                lg={12}
                md = {12}
                style = {{padding : 5, paddingRight : 8,}}
                xs = {12}
              >
                <RadioGroup
                  name="medical_waste_bag_red"
                  onChange={(e) => handleType2Change(e)}
                  style = {{display : 'block'}}
                  value={fakit_type2_data.medical_waste_bag_red}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="Yes"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    value="No"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Replaced"
                    value="Replaced"
                  />
                </RadioGroup>
              </Grid>
            </fieldset>
            <fieldset style = {{width  : '100%', marginTop : 20}}>
              <legend><span style = {{fontSize : 14, padding : 4}}>Additional Notes / Recommendations</span></legend>
              <Grid
                container
                lg={12}
                md = {12}
                xs = {12}
              >
                <TextField
                  fullWidth
                  multiline
                  name="additional_note"
                  onChange={(e) => handleType2Change(e)}
                  size = "medium"
                  value={fakit_type2_data.additional_note || ''}
                  variant="outlined"
                />
              </Grid>
            </fieldset>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => showType2Dialog(false)}
          >
              Close
          </Button>
          <LoadingButton
            label = {type2_dialog_id}
            label_loading = {type2_dialog_id}
            loading = {loading}
            onClick = {() =>{
              if(type2_dialog_id == 'Add')
              {
                addType2();
              }
              else{
                editType2();
              }
            }}
          />
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default FakAuditManage;
