import React, {useEffect, useState, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Grid, IconButton, Breadcrumbs, Link, Button, } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {ArrowBack, MailOutline} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import AuditReport from './Component/AuditReport';
import * as qs from 'query-string';
import moment from 'moment';
import {config, _http, _sessionStorage, _auth_check, _crypto, _localStorage, getInitials} from 'helpers';
import {PageLoading, LoadingButton, Camera} from 'components';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 'calc(100% - 54px)'
  },
  padding: {
    // padding: theme.spacing(3),
  },
  demo1: {
    padding : 20,
    width : '100%',
    height : '100%',  
    display : 'flex',
    flexDirection : 'column',
    backgroundColor: theme.palette.background.paper,
  },
}));


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff'
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab
  disableRipple
  {...props}
               />);

const ReportPreview = () => {
  const classes = useStyles();
  const AuditReportRef = useRef(null);
  const adminReportRef = useRef(null);
  
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoad] = useState(false);

  const [siteName, setSiteName] = React.useState('');
  const [fakitRegData, setFakitRegAudits] = React.useState([]);
  const [fakitMineData, setFakitMineAudits] = React.useState([]);
  const [feAuditData, setFeAudits] = React.useState([]);
  const [reportDate, setReportDate] = useState(new Date());

  const parsed = qs.parse(document.location.search);
  let site_id = parsed.site;

  useEffect(() => {
    get_site_data();
    get_audits();
  }, []);

  const get_site_data = () => {
    _http.doPost('epr/get_site_data', 
      {
        site_id : site_id
      }, 
      (data) => {
        if(data.status == false)
        {
          alert(data.data);
        }
        else
        {
          setSiteName(data.data.site_name);
        }
      },
      (err) => {
        console.log(err); 
        alert('Error occured');
      }
    );
  }

  const get_audits = () => {
    setPageLoad(false);
    _http.doPost('epr/get_audits', 
      {
        site_id : parsed.site
      }, 
      (data) => {
        setPageLoad(true);
        if(data.status == false)
        {
          alert(data.data);
        }
        else 
        {
          let tmp_fakit_reg_audits = []
          let tmp_fakit_mine_audits = []
          let tmp_fe_audits = []
          for(var i = 0; data.data.length != null && i < data.data.length; i ++)
          {
            if(data.data[i].type == 'fakit')
            {
              if(data.data[i].fakit_type == 'Regulation 7')
              {
                tmp_fakit_reg_audits.push(data.data[i]);
              }
              else
              {
                tmp_fakit_mine_audits.push(data.data[i]);
              }
            }
            else 
            {
              tmp_fe_audits.push(data.data[i]);
            }
          }
          setFakitRegAudits(tmp_fakit_reg_audits);
          setFakitMineAudits(tmp_fakit_mine_audits);
          setFeAudits(tmp_fe_audits)
        }
      },
      (err) => {
        setPageLoad(true);
        console.log(err);
        alert('Error occured');
      }
    );
  }

  const send_Report = async () => {
    setLoading(true)
    let AuditReport_html = AuditReportRef.current.innerHTML;
    // console.log('AuditReport_html', AuditReport_html)
    AuditReport_html = AuditReport_html.replaceAll('data-first-table="true" style="width: 100%;"', 'data-first-table="true" style="width: 100%; height: 750px;"');
    AuditReport_html = AuditReport_html.replaceAll('data-patient-table="true" style="width: 100%;"', 
      'data-patient-table="true" style="width: 100%; height: 750px;"' );
    AuditReport_html = AuditReport_html.replaceAll('overflow-wrap: break-word; table-layout: fixed;', 'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;');
    // for safari
    AuditReport_html = AuditReport_html.replaceAll('table-layout: fixed; word-wrap: break-word;', 'overflow-wrap: break-word; table-layout: fixed; font-size : 60%; line-height : 10px;');
    
    let sending_res = await _http.doPost_NoCallback('epr/send_email',  {
      to : 'test-mail@legacyems.co.za', //'admin@legacyems.co.za, gavin@legacyems.co.za',
      orientation : 'portrait',
      subject : 'Please find attached from Legacy Emergency Specialists',
      pdf_name : 'Audit-Report-' + moment(reportDate).format('YYYY-MM-DD') + '_' + new Date().getTime() + '.pdf',
      html_str : AuditReport_html
    });
    console.log('sending res', sending_res)
    if (sending_res == null)
    {
      setLoading(false)
      alert('Error occured');
      return
    }
    setLoading(false)
    alert(sending_res.data);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // document.location.href = 'home?tab=' + newValue;
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        lg={12}
        md = {12}
        style = {{height : '100%'}}
        xs = {12}
      >
        <Grid
          container
          item
          lg={12}
          md = {12}
          style = {{marginBottom : 8, marginTop : 10}}
          xs = {12}
        >
          <Grid
            container
            item
            lg={6}
            md = {4}
            style = {{alignItems : 'center'}}
            xs = {12}
          >
            <IconButton
              onClick = {() => {document.location.href = '/epr_form/audit?tab=0&site=' + site_id}}
              variant = "contained"
            >
              <ArrowBack />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              style = {{marginLeft : 10}}
            >
              <Link color="inherit"  >Go To Audit List</Link>
            </Breadcrumbs>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md = {3}
            style = {{alignItems : 'center', justifyContent : 'center', padding : 12}}
            xs = {12}
          >
            <span style = {{fontSize : 12, fontWeight : 'bold', marginRight : 6}}>Report Date : </span>
            <DatePicker
              closeOnScroll
              dateFormat="dd/MM/yyyy"
              onChange={date => setReportDate(date)}
              selected={reportDate}
            />
          </Grid>
          <Grid
            container
            item
            lg={3}
            md = {4}
            style = {{alignItems : 'center', justifyContent : 'center', padding : 12}}
            xs = {12}
          >
            <LoadingButton
              color = "primary"
              label = "Send REPORT"
              label_loading = "Send REPORT"
              loading = {loading}
              onClick = {() => {
                send_Report()
              }}
              startIcon = {<MailOutline />}
              style = {{height : 40, marginLeft : 10}}
              variant = "contained"
            >Send REPORT</LoadingButton>  
          </Grid>
        </Grid>
        {
          pageLoaded == true ? 
            <div className={classes.demo1}>
              <AntTabs
                aria-label="ant example"
                onChange={handleChange}
                value={value}
              >
                <AntTab label="Report" />
              </AntTabs>
              <div
                ref = {AuditReportRef}
                style = {{width : '100%', }}
              > 
                <AuditReport
                  fakitMineData = {fakitMineData}
                  fakitRegData = {fakitRegData}
                  feAuditData = {feAuditData}
                  reportDate = {reportDate}
                  site_name = {siteName}
                /> 
              </div>
            </div>
            :
            <PageLoading />
        }  
      </Grid>
    </div>
  );
};

export default ReportPreview;